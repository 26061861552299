import {api} from '@/stores/api';
import {formatKeys, hasInvalidatedChanges} from '@/shared/utils/responseUtils';
import {
  authorFields,
  companyFields,
  parentTaskFields,
  supportTaskCommentsFields,
  userFields
} from '@/shared/utils/requestParamsUtils';
import {
  ISupportTask,
  ISupportTaskResponse,
  ISupportTaskUpdateRequest
} from '@/shared/models/supportTaskModel';
import {withSocket} from '@/shared/constants/appConstants.ts';

export const apiWithSupportTaskPage = api.injectEndpoints({
  endpoints: builder => ({
    getSupportTaskById: builder.query<ISupportTask, {taskId?: string}>({
      query: ({taskId}: {taskId: string}) => ({
        url: `/v1/support_tasks/${taskId}`,
        method: 'get',
        params: {
          include:
            'user,author,support_task_comments,support_task_comments.user,support_task_comments.files,company,task,support_task_spectators,spectator_support_task_comment,',
          fields:
            'id,title,ticket,department_id,task_status_id,task_type_id,company.bitrix_id,deadline_at,done_at,created_at,is_has_files,unit_id,' +
            [
              userFields,
              supportTaskCommentsFields,
              companyFields,
              authorFields,
              parentTaskFields
            ].join(',')
        }
      }),
      transformResponse: (result: {
        data: ISupportTaskResponse;
      }): ISupportTask | Promise<ISupportTask> => {
        return formatKeys<ISupportTaskResponse, ISupportTask>(result.data);
      },

      // @ts-ignore
      providesTags: (_result, _error, arg) => {
        return [{type: 'SUPPORT_TASK', id: arg}];
      }
    }),

    updateSupportTask: builder.mutation<void, {taskId: string} & ISupportTaskUpdateRequest>({
      query: ({taskId, ...patch}) => ({
        url: `/v1/support_tasks/${taskId}`,
        method: 'PUT',
        data: patch
      }),
      async onQueryStarted({taskId, ...patch}, {dispatch, queryFulfilled}) {
        if (withSocket) {
          return;
        }
        // Pessimistic Update
        try {
          const {data: updatedTask} = await queryFulfilled;

          const _patchResult = dispatch(
            apiWithSupportTaskPage.util.updateQueryData('getSupportTaskById', {taskId}, draft => {
              if (hasInvalidatedChanges(patch)) {
                // инвалидируем кеш
                console.debug('invalidate cache SUPPORT_TASK');
                dispatch(api.util.invalidateTags(['SUPPORT_TASK']));
                return;
              }

              const updatedFields = Object.keys(patch).reduce((acc, curr) => {
                return {
                  ...acc,
                  // @ts-ignore
                  [curr]: updatedTask[curr] || patch[curr]
                };
              }, {});
              Object.assign(draft, formatKeys(updatedFields));
            })
          );
        } catch (e) {
          console.error(e);
        }
      }
    })
  }),
  overrideExisting: true
});

export const {useGetSupportTaskByIdQuery, useUpdateSupportTaskMutation} = apiWithSupportTaskPage;

export const getSupportTaskByIdQueryState =
  apiWithSupportTaskPage.endpoints.getSupportTaskById.useQueryState;
