import React, {FC, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import cn from 'classnames';
import {createFilterOptions, FilterOptionsState} from '@mui/material';

import {EFilterName, IFilterOption, IOption} from '@/shared/models/tasksFilterModel';
import {ComboBox} from '@/components/UIKit';
import {useAppSelector} from '@/stores/hooks';
import {companyIndividualTypeIdSelector} from '@/stores/TaskConditionsStore/TaskConditionsSelector';
import {checkCompanyIndividual} from '@/shared/utils/companyUtils';

const selectAllOption = {name: 'Все', id: 'select-all', isActive: true};

interface IProps extends IFilterOption<IOption> {
  onChange: (value?: IOption) => void;
}

const TasksFilterItems: FC<IProps> = ({
  fieldName,
  selectAll,
  options,
  onChange,
  minWidth,
  ...rest
}) => {
  const [urlParams] = useSearchParams();
  const companyIndividualType = useAppSelector(companyIndividualTypeIdSelector);
  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);

  const idsFromUrl = useMemo(() => {
    return fieldName && urlParams.get(fieldName);
  }, [fieldName, urlParams]);

  useEffect(() => {
    // init one with url
    if (options && idsFromUrl) {
      const foundOption = options.find(val => val?.id == idsFromUrl);

      foundOption && setSelectedOption(foundOption);
    }
  }, [idsFromUrl, options]);

  useEffect(() => {
    // init one if selectAll
    if (selectAll && !idsFromUrl) {
      setSelectedOption(selectAllOption);
    }
  }, [idsFromUrl, selectAll]);

  const getOptionLabel = (option: IOption) => `${option?.name}`;

  const handleChange = (_event: React.SyntheticEvent, option: IOption | null) => {
    setSelectedOption(option);
    if (option) {
      // @ts-ignore
      onChange(option.id !== selectAllOption.id && option);
    } else {
      onChange(undefined);
    }
  };

  /**
   * RENDER
   */
  const renderOption = (props: any, option: IOption) => {
    if (!option) {
      return null;
    }
    // физ лица комппаний покрасить к врасный цвет
    // fieldName === 'company_id' && console.log('option', option)

    const {className, ...rest} = props;
    const companyTypeId = 'companyTypeId' in option ? option.companyTypeId : undefined;
    const isNotActiveUser = fieldName === EFilterName.Users && !option?.isActive;
    const isImp =
      isNotActiveUser || checkCompanyIndividual(companyIndividualType, companyTypeId, option.name);

    return (
      <li {...rest} className={cn(className, isImp ? 'imp' : '')} key={option.id}>
        {getOptionLabel(option)}
      </li>
    );
  };

  const filter = createFilterOptions();

  return (
    <ComboBox<IOption>
      {...rest}
      key={fieldName}
      options={options}
      variantInput="standard"
      // @ts-ignore
      onChange={handleChange}
      sx={{
        marginRight: '2.4rem',
        marginBottom: '1.2rem',
        minWidth: minWidth ? `${minWidth}px` : '180px',
        maxWidth: '260px',
        position: 'relative',
        zIndex: 1
      }}
      disableClearable
      value={selectedOption}
      renderOption={renderOption}
      // @ts-ignore
      filterOptions={(options: IOption[], params: FilterOptionsState<IOption>) => {
        // @ts-ignore
        const filtered = filter(options, params);

        if (selectAll) {
          return [selectAllOption, ...filtered];
        }
        return filtered as IOption[];
      }}
      inputClassName={
        selectedOption &&
        'companyTypeId' in selectedOption &&
        checkCompanyIndividual(
          companyIndividualType,
          selectedOption.companyTypeId,
          selectedOption.name
        )
          ? 'imp'
          : ''
      }
    />
  );
};

export default React.memo(TasksFilterItems);
