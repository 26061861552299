import React, {FC, memo, useEffect, useRef} from 'react';
import {Chip, Grid, Tooltip} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {ICounter} from '@/shared/models/tasksCounters';
import {TCounter, setCounterState} from '@/stores/SearchStateStore';
import {RootState} from '@/stores';

import s from './TasksCounters.module.css';

interface IProps {
  dataCounter?: ICounter;
  tabPath: string;
  currentTab: string;
  selectedChip: TCounter | null;
  setSelectedChip: (chip: TCounter | null) => void;
  isSupportTab?: boolean;
}

const TasksCounters: FC<IProps> = ({
  dataCounter,
  tabPath,
  currentTab,
  selectedChip,
  setSelectedChip,
  isSupportTab = false
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const counter = useSelector((state: RootState) => state.searchState.counter);

  const prevTabPath = useRef<string>(tabPath);

  const chipData = [
    {
      color: 'primary' as const,
      label: dataCounter?.newCount || 0,
      tooltip: 'Новые задачи',
      type: 'new_count' as TCounter
    },
    {
      color: 'error' as const,
      label: dataCounter?.overdueCount || 0,
      tooltip: 'Просроченные задачи',
      type: 'overdue_count' as TCounter
    },
    {
      color: 'success' as const,
      label: dataCounter?.workCount || 0,
      tooltip: 'Задачи в работе',
      type: 'work_count' as TCounter
    },
    {
      color: 'warning' as const,
      label: dataCounter?.unreadCommentsCount || 0,
      tooltip: 'Непрочитанные комментарии',
      type: 'unread_comments_count' as TCounter
    }
  ];

  const handleClick = (type: TCounter) => {
    if (currentTab !== prevTabPath.current) {
      navigate(tabPath);
    }
    dispatch(setCounterState(type));
    prevTabPath.current = currentTab;
    setSelectedChip(type);
  };

  useEffect(() => {
    if (counter === undefined) {
      setSelectedChip(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (
    <Grid container spacing={2} style={{marginTop: '3px'}}>
      {chipData.map((chip, index) => (
        <Grid item key={index}>
          <Tooltip title={chip.tooltip} arrow>
            <Chip
              onClick={() => handleClick(chip.type)}
              style={{
                cursor: 'pointer',
                border:
                  selectedChip === chip.type && currentTab === tabPath
                    ? '2px solid #132436'
                    : '2px solid transparent'
              }}
              color={chip.color}
              size="small"
              label={chip.label}
            />
          </Tooltip>
        </Grid>
      ))}

      {isSupportTab && (
        <Tooltip title="Задачи под наблюдением">
          <div
            className={s.watchersCounter}
            onClick={() => handleClick('spectator_count')}
            onKeyDown={() => 'spectator_count'}
            tabIndex={0}
            role="button"
            style={{
              border:
                selectedChip === 'spectator_count' && currentTab === tabPath
                  ? '2px solid #132436'
                  : '2px solid transparent'
            }}
          >
            <span className={s.watchersIcon}>
              <VisibilityIcon />
            </span>
            <span className={s.watchersCount}>{dataCounter?.spectatorCount || 0}</span>
          </div>
        </Tooltip>
      )}
    </Grid>
  );
};

export default memo(TasksCounters);
