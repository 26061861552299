import React, {FC} from 'react';
import {Backdrop, Box, Button, Grid, Modal, Typography} from '@mui/material';
import {useSelector} from 'react-redux';

import {chatErrorSelector} from '@/stores/TaskChatStore/TaskChatSelector';
import {useAppSelector} from '@/stores/hooks.ts';
import {taskErrorsSelector} from '@/stores/TaskSettingsStore';

interface IProps {
  open: boolean;
  onClose: () => void;
  isChat?: boolean;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 434,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '100vh',
  overflowY: 'auto',
  flexDirection: 'column',
  justifyContent: 'center'
};

const ErrorTaskCondition: FC<IProps> = ({open, onClose, isChat = false}) => {
  const chatError = useSelector(chatErrorSelector);

  const errorList = useAppSelector(taskErrorsSelector);

  const errorMessages = [
    ...errorList,
    ...(chatError && isChat ? chatError.map((msg: string) => ' ' + msg) : [])
  ];

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Перевод в другой отдел"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <form action="" onSubmit={handleSubmit} style={{width: '100%'}}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item style={{marginBottom: '20px'}}>
              <Typography variant="h2" align="center">
                Внимание!
              </Typography>
            </Grid>
            <Grid item style={{width: '100%', textAlign: 'left'}}>
              <ul style={{listStyle: 'none', padding: 0}}>
                {errorMessages.map((msg, index) => (
                  <li
                    key={index}
                    style={{
                      padding: '4px 0',
                      fontSize: '1.1em'
                    }}
                  >
                    — {msg}
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item style={{width: '100%'}}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={false}
                sx={{minWidth: 140}}
                style={{margin: '20px auto 0', display: 'flex', justifyContent: 'center'}}
              >
                Ок
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default ErrorTaskCondition;
