import {RootState} from 'src/stores';

export const isAuthSelector = (state: RootState) => {
  return state.appState.isAuth;
};
export const getIsLoading = (state: RootState) => {
  return state.appState.isLoading;
};
export const getIsLinearLoading = (state: RootState) => {
  return state.appState.isLinearLoading;
};
