import React, {FC, memo, useMemo} from 'react';
import {IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {IFile} from '@/shared/models/fileModel';
import {convertToDataStorageUnit} from '@/shared/utils/mathUtils';

import s from './SimpleFile.module.css';

interface IProps {}

interface IProps {
  file: IFile;
  onDeleteFile: (file: IFile) => void;
}

const SimpleFile: FC<IProps> = ({file, onDeleteFile}) => {
  const sizeFormatted = useMemo(() => {
    if (!file?.size) {
      return '';
    }
    return convertToDataStorageUnit(file.size, true, 1);
  }, [file?.size]);

  if (!file) {
    return null;
  }

  return (
    <div className={s.file}>
      <div className={s.fileInfo}>
        <Typography gutterBottom={false} variant="bold" title={file.name}>
          {file.name}
        </Typography>
        {sizeFormatted && (
          <Typography
            gutterBottom={false}
            variant="body2"
            title={file.name}
            sx={{lineHeight: '22px'}}
          >
            {', '}
            {sizeFormatted}
          </Typography>
        )}
      </div>

      <IconButton onClick={() => onDeleteFile(file)} className={s.fileDelete}>
        <CloseIcon style={{color: '#f50057'}} />
      </IconButton>
    </div>
  );
};

export default memo(SimpleFile);
