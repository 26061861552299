import React, {FC} from 'react';

import {useUpdateUrlParams} from '@/scenes/AllTasksPage/hooks/useUpdateUrlParams.ts';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {TFilterValues} from '@/shared/models/tasksFilterModel.ts';
import SubtasksFilters from '@/scenes/AllTasksPage/components/TasksFilters/SubtasksFilters.tsx';
import {subtasksFilterValuesSelector} from '@/stores/SubtasksFilterStore/SubtasksFilterSelector.ts';
import {resetFilterValues, updateValueByKey} from '@/stores/SubtasksFilterStore';

import {SubtasksTable} from './components/SubtasksTable/index';

interface IProps {}

const Subtasks: FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const filterValues: TFilterValues = useAppSelector(subtasksFilterValuesSelector);

  const handleUpdateFilters = ({key, value}: any) => {
    dispatch(updateValueByKey({key, value}));
  };

  const handleResetFilters = () => {
    dispatch(resetFilterValues());
  };

  useUpdateUrlParams(filterValues, handleUpdateFilters, handleResetFilters);

  return (
    <>
      <SubtasksFilters />
      <SubtasksTable />
    </>
  );
};

export default Subtasks;
