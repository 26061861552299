import React, {FC, SyntheticEvent} from 'react';
import {Backdrop, Box, Grid, Modal, Typography} from '@mui/material';
import Button from '@mui/lab/LoadingButton';

import {useDeleteSpectatorMutation} from '@/stores/api/support-task-page/support-task-watchers';

interface IProps {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  supportTaskId?: number;
  supportTaskSpectatorId?: number;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 434,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const StopWatchTask: FC<IProps> = ({
  open,
  onSubmit,
  onClose,
  supportTaskId,
  supportTaskSpectatorId
}) => {
  const [submitStopWatchTask, {isLoading}] = useDeleteSpectatorMutation();

  const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (supportTaskId && supportTaskSpectatorId) {
      await submitStopWatchTask({supportTaskId, supportTaskSpectatorId});
    }
    onSubmit();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="Перевод в другой отдел"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <form action="" onSubmit={handleSubmit}>
          <Typography variant="h2" align="center" style={{paddingBottom: '15px'}}>
            Остановить наблюдение за задачей
          </Typography>
          <Typography variant="body1" style={{paddingBottom: '15px'}}>
            Вы действительно хотите остановить наблюдение за этой задачей?
          </Typography>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={onClose}
                sx={{minWidth: 140}}
                disabled={false}
              >
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                loading={isLoading}
                sx={{minWidth: 140}}
              >
                Ок
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default StopWatchTask;
