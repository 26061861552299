import React, {FC, memo, useEffect} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {Box, Button, createFilterOptions, FilterOptionsState, Grid, TextField} from '@mui/material';

import {FormComboBox} from '@/components/Form/components/FormComboBox';
import {useGetDepartmentsQuery} from '@/stores/api/filtered-dictionaries';
import {useUpdateTaskMutation} from '@/stores/api/task-page/task-page';
import {ITaskUpdateRequest} from '@/shared/models/tasksDataModel';
import {useAppSelector} from '@/stores/hooks';
import {taskCompanySelector} from '@/stores/TaskSettingsStore';
import {useGetCompanyByIdQuery} from '@/stores/api/companies';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {prepareDepartment} from '@/shared/utils/departmentUtils';
import {IDepartment} from '@/shared/models/dictionaryModel';
import {useGetUsersQuery} from '@/stores/api/user';
import {IUser} from '@/shared/models';
import {OptionUser} from '@/components/UIKit/OptionUser';

interface IProps {
  taskId: string;
  currentDepartmentId?: number;
  onClose: () => void;
}

type FormValues = {
  department: IDepartment;
  employee: IUser;
  content: string;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 434,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const TransferTaskForm: FC<IProps> = ({taskId, currentDepartmentId, onClose}) => {
  const company = useAppSelector(taskCompanySelector);
  const {unitsIds} = usePrepareDict();

  const {departmentsIds, isLoading: isCompanyLoading} = useGetCompanyByIdQuery(
    {
      companyId: company?.id
    },
    {
      skip: !company?.id,
      selectFromResult: ({data, isLoading}) => ({
        departmentsIds: data?.companyResponsibles?.length
          ? data.companyResponsibles.map(({departmentId}) => departmentId).join(',')
          : '',
        isLoading
      })
    }
  );

  // Отделы отфильтрованные по companyResponsibles текущей компании
  const {departments, isDepartmentsLoading} = useGetDepartmentsQuery(
    {
      'filters[id]': 'in|' + departmentsIds
    },
    {
      skip: !departmentsIds,
      selectFromResult: ({data, isLoading}) => ({
        /**
         * При передаче задчи в  другой отдел,
         * надо исключать в селекторе отдел под которым сейчас находится задача
         */
        departments: data
          ? data
              .filter(o => o.id !== currentDepartmentId)
              .map(val => prepareDepartment(val, unitsIds))
          : [],
        isDepartmentsLoading: isLoading
      })
    }
  );

  const [submitUpdateTask, {isLoading: isSubmitLoading, isError, isSuccess}] =
    useUpdateTaskMutation();

  const {
    control,
    watch,
    handleSubmit,
    formState: {errors}
  } = useForm<FormValues>();

  // @ts-ignore
  const onSubmit: SubmitHandler<FormValues | undefined> = data => {
    if (Object.keys(errors).length > 0) {
      return;
    }
    if (data) {
      const requestData = {
        taskId,
        data: {
          // Надо чтобы при передаче в другой отдел, задача переходила из статуса "Принято в работу" на "Новая"
          task_status_id: 1,
          department_id: data.department.id,
          unit_id: data.department.unitDepartment?.unitId,
          user_id: data.employee?.id
        }
      } as {taskId: string} & ITaskUpdateRequest;

      if (data.content) {
        requestData.data['task_comment'] = {
          content: data.content,
          is_change_department: true
        };
      }

      submitUpdateTask(requestData);
    }
  };

  /**
   * USERS
   */
  const watchDepartment = watch('department') as IDepartment | undefined;
  const {data: employees, isLoading: isEmployeeLoading} = useGetUsersQuery(
    {
      'filters[has_departments]': watchDepartment?.id,
      'filters[active]': 1,
      'filters[is_vacation]': 0
    },
    {
      skip: !watchDepartment?.id
    }
  );

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  const isLoading = isCompanyLoading || isDepartmentsLoading;

  const filter = createFilterOptions<IUser>({
    trim: true,
    stringify: option => option.name + option.email
  });

  return (
    <Box sx={style}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isError && <span className="error">Ошибка передачи задачи в отдел</span>}
        <Grid container spacing={12} sx={{marginBottom: 12}}>
          <Grid item xs={12}>
            <FormComboBox
              name="department"
              required
              options={departments || []}
              control={control}
              placeholder="Выберите отдел"
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <FormComboBox
              name="employee"
              options={employees || []}
              control={control}
              placeholder="Выберите сотрудника"
              isLoading={isEmployeeLoading}
              renderOption={(props: any, option: IUser) => {
                return <OptionUser {...props} key={option.id} option={option} />;
              }}
              filterOptions={(options: IUser[], params: FilterOptionsState<IUser>) => {
                return filter(options, params);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="content"
              control={control}
              defaultValue=""
              render={({field: {onChange, value}}) => (
                <TextField
                  id="content"
                  placeholder="Введите комментарий"
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                  }}
                  fullWidth
                  multiline
                  autoComplete="off"
                  rows={6}
                  error={!!errors?.content?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onClose}
            sx={{minWidth: 154, borderWidth: 2}}
          >
            Отмена
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isSubmitLoading}
            loadingIndicator="Передача…"
            sx={{minWidth: 154}}
          >
            Передать
          </LoadingButton>
        </Grid>
      </form>
    </Box>
  );
};

export default memo(TransferTaskForm);
