const fileStorageUrl = import.meta.env.APP_FILE_STORAGE_URL || '';
const fileStorageContainer = import.meta.env.APP_FILE_STORAGE_CONTAINER || '';
const fileStorageBucket = import.meta.env.APP_FILE_STORAGE_BUCKET || '';

export default {
  fileStorageUrl,
  fileStorage: {
    url: fileStorageUrl,
    user: '249598_marusia',
    key: 'N7a&qKt.F0',
    container: fileStorageContainer,
    bucket: fileStorageBucket
  },
  s3: {
    credentials: {
      accessKeyId: '249598_marusia',
      secretAccessKey: 'N7a&qKt.F0'
    },
    endpoint: fileStorageUrl + 'v1/'
  }
};
