import {useEffect} from 'react';

import {EFilterName, IOption} from '@/shared/models/tasksFilterModel';
import {useGetStatusesQuery} from '@/stores/api/filtered-dictionaries';

type TUpdate = (key: EFilterName, options: IOption[]) => void;

export const useStatusFilters = (onUpdate: TUpdate, isSupportPage?: boolean) => {
  const queryParams = isSupportPage ? {} : {'filters[without_done]': 1};

  const {data: statuses} = useGetStatusesQuery(queryParams);

  useEffect(() => {
    if (statuses) {
      onUpdate(EFilterName.Statuses, statuses);
    }
  }, [onUpdate, statuses]);
};
