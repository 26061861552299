import React, {FC, SyntheticEvent, useEffect, useRef, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@mui/material';
import cn from 'classnames';
import ReactQuill from 'react-quill';

import {InputFile} from '@/components/UIKit/InputFile';
import {useOutsideClick} from '@/shared/hooks/useOutsideClick';

import {ReactComponent as SendMessageIcon} from './assets/send.svg';
import 'react-quill/dist/quill.snow.css';
import s from './ChatTextField.module.css';

interface IProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  isDisabledSend: boolean;
  isDisabledSendAll?: boolean;
  isDisabledInput?: boolean;
  isDisabledInputFile?: boolean;
  isSendAll?: boolean;
  hasAllButton?: boolean;
  onChangeFileInput: (files: FileList) => void;
  onSendClick: (message: string) => void;
  onSendAll: (message: string) => void;
  onChangeInput: (message: string) => void;
  onFocusInput: (message: string) => void;
  confirmContent?: () => React.ReactNode;
}

const ChatTextField: FC<IProps> = ({
  isLoading,
  isDisabled,
  isDisabledSend,
  isDisabledSendAll,
  isDisabledInput,
  isDisabledInputFile,
  isSendAll,
  hasAllButton,
  onChangeFileInput,
  onSendClick,
  onSendAll,
  onChangeInput,
  onFocusInput,
  confirmContent
}) => {
  const [message, setMessage] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showToolbar, setShowToolbar] = useState(false);
  const editorRef = useRef<HTMLFormElement | null>(null);

  const handleClickOutside = () => {
    setShowToolbar(false);
  };

  useOutsideClick(editorRef, handleClickOutside);

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSendClick(message);
    setMessage('');
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleSubmitConfirm = () => {
    onSendAll(message);
    setMessage('');
    handleCloseConfirm();
  };

  useEffect(() => {
    const toolbars = Array.from(document.querySelectorAll<HTMLElement>('div.ql-toolbar.ql-snow'));
    if (!toolbars.length) {
      return;
    }
    if (showToolbar && !isDisabled) {
      toolbars[0].style.display = 'block';
    } else {
      toolbars[0].style.display = 'none';
    }
  }, [isDisabled, showToolbar]);

  const modules = {
    toolbar: [
      [{header: [1, 2, 3, false]}],
      ['bold', 'italic', 'underline'],
      [{list: 'ordered'}, {list: 'bullet'}],
      ['link'],
      ['clean']
    ]
  };

  return (
    <>
      <form onSubmit={handleSubmit} ref={editorRef}>
        <Grid container direction="row" alignItems="end" className={s.grid}>
          <InputFile
            multiple
            onChange={onChangeFileInput}
            className={s.file}
            isDisabled={isDisabled || isDisabledInputFile}
          />
          <div className={s.editorWrapper}>
            <ReactQuill
              readOnly={isDisabled || isDisabledInput}
              theme={'snow'}
              value={message}
              placeholder="Введите сообщение"
              onChange={content => {
                setMessage(content);
                onChangeInput(content);
              }}
              className={cn(s.editor)}
              modules={modules}
              onFocus={(_range, _source, editor) => {
                setShowToolbar(!isDisabledInput);
                onFocusInput(editor.getText());
              }}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={cn(s.button, {
              [s.disabled]: isDisabled,
              [s.sendButton]: !hasAllButton
            })}
            disabled={isDisabled || isLoading || isDisabledSend}
          >
            {hasAllButton ? (
              'Ответить'
            ) : !isLoading ? (
              <SendMessageIcon />
            ) : (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress size={32} color="inherit" />
              </Box>
            )}
          </Button>
          {hasAllButton && (
            <Button
              type="button"
              onClick={() => handleOpenConfirm()}
              variant="contained"
              color="primary"
              className={cn(s.button, {[s.disabled]: isDisabled})}
              disabled={isDisabled || isLoading || isDisabledSendAll || !isSendAll}
            >
              Ответить всем
            </Button>
          )}
        </Grid>
      </form>
      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="Подтвердите отправку сообщения"
      >
        <DialogTitle>Отправить всем</DialogTitle>
        <DialogContent>
          {confirmContent ? (
            confirmContent()
          ) : (
            <DialogContentText>
              Письмо получат все сотрудники из раздела Кому и Копия
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Отменить</Button>
          <Button variant="contained" color="primary" onClick={handleSubmitConfirm}>
            Отправить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatTextField;
