import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';

import {IChatChannel} from '@/shared/models/chatModel.ts';
import {apiWithChat} from '@/stores/api/chats.ts';
import {logout} from '@/stores/AuthStore';

export const chatListAdapter = createEntityAdapter({
  selectId: (item: IChatChannel) => item.id
});

const initialState = chatListAdapter.getInitialState({
  hasMorePages: true,
  chat: {}
});

export const chatListStateSlice = createSlice({
  name: 'chatList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout, _state => {
        return initialState;
      })
      .addMatcher<any>(apiWithChat.endpoints.getChatList.matchFulfilled, (state, action) => {
        if (action.meta.arg.originalArgs.page?.offset === 0) {
          chatListAdapter.setAll(state, action.payload.data);
        }
        if (action.meta.arg.originalArgs.page.offset > 0 && action.payload.links.next) {
          chatListAdapter.addMany(state, action.payload.data);
        }
        state.hasMorePages = action.payload.links.next;
      });
  }
});

export const chatListReducer = chatListStateSlice.reducer;
