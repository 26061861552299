import {useCallback, useState} from 'react';

import {IFile} from '@/shared/models/fileModel';

export const useFileState = () => {
  const [files, setFiles] = useState<IFile[]>([]);

  const handleDeleteFile = (idx: number) => {
    if (files) {
      const newFiles = [...files.slice(0, idx), ...files.slice(idx + 1)];

      setFiles(newFiles);
    }
  };

  const handleAttachFiles = useCallback(async (files: IFile[]) => {
    if (files) {
      setFiles(files);
    }
  }, []);

  return {
    files,
    handleDeleteFile,
    handleAttachFiles
  };
};
