import React, {FC} from 'react';
import {Outlet} from 'react-router';
import {Box, ThemeProvider} from '@mui/material';
import {createTheme} from '@mui/material/styles';

import {ReactComponent as Logo} from '@/assets/auth-logo.svg';
import {darkThemeOptions} from '@/layouts/AuthLayout/theme';

import s from './AuthLayout.module.css';

interface IProps {
  children?: React.ReactNode;
}

const darkTheme = createTheme(darkThemeOptions);

const AuthLayout: FC<IProps> = () => {
  return (
    <div className={s.authLayout}>
      <div className={s.authLayoutWrapper}>
        <Box mt={4} mb={2} textAlign="center">
          <Logo className={s.authLayoutImage} />
        </Box>
        <ThemeProvider theme={darkTheme}>
          <Outlet />
        </ThemeProvider>
      </div>
    </div>
  );
};

export default AuthLayout;
