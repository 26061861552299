import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {logout} from '@/stores/AuthStore';
import {RootState} from '@/stores';
import {ICommonResponse} from '@/shared/models/commonModel';
import {ISubtask, ISubtaskResponse} from '@/shared/models/subtaskModel';
import {formatKeys} from '@/shared/utils/responseUtils';
import {apiWithSubtasks} from '@/stores/api/tasks-table/subtasks';

interface IProps {
  subtasks: Record<string, ISubtask>;
}

const initialState: IProps = {
  subtasks: {}
};

export const tasksTableStateSlice = createSlice({
  name: 'tasksTableState',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => {
        return initialState;
      })
      .addMatcher<any>(
        apiWithSubtasks.endpoints.getSubtasksByTaskId.matchFulfilled,
        (state: RootState, action: PayloadAction<ICommonResponse<ISubtask[]>>) => {
          if (action.payload?.data) {
            // @ts-ignore
            const taskId = action.meta.arg.originalArgs.taskId;
            state.subtasks[taskId] = action.payload.data.map(item => {
              return {
                ...formatKeys<ISubtaskResponse, ISubtask>(item),
                name: item.title
              };
            });
            return state;
          }

          return state;
        }
      );
  }
});

export const {resetState} = tasksTableStateSlice.actions;

export const tasksTableStateReducer = tasksTableStateSlice.reducer;
