import {IFile} from '@/shared/models/fileModel';
import Config from '@/shared/constants/config';
import {fileMobileConfig, fileEmailConfig} from '@/shared/constants/file';

export const generateFilePath = (file: IFile) => {
  return ['v1', Config.fileStorage.container, Config.fileStorage.bucket, file.path].join('/');
};

/**
 * Пусть до файла скачать все
 * @param file
 */
export const generateMessageFilesPath = (file: IFile) => {
  return (
    [
      'v1',
      Config.fileStorage.container,
      Config.fileStorage.bucket,
      file.parentEntityType,
      file.parentEntityId,
      file.entityType,
      file.entityId
    ].join('/') + `?download-all-as-zip=task-${file.parentEntityId}-${file.entityId}.zip`
  );
};

export const generateAllFilesPath = (taskId: string) => {
  // return ['v1', Config.fileStorage.container, 'incidents', file.parentEntityType, file.parentEntityId, file.entityType].join('/') + '?download_to_zip';
  return (
    [
      'v1',
      Config.fileStorage.container,
      Config.fileStorage.bucket,
      'task',
      taskId,
      'task_comment'
    ].join('/') + `?download-all-as-zip=task-${taskId}.zip`
  );
};

export const generateAllSubtaskFilesPath = (taskId: string) => {
  return (
    [
      'v1',
      Config.fileStorage.container,
      Config.fileStorage.bucket,
      'subtask',
      taskId,
      'subtask_comment'
    ].join('/') + `?download-all-as-zip=task-${taskId}.zip`
  );
};

export const checkSize = (files: IFile[], isSendEmail?: boolean) => {
  const sumSize = files.reduce((acc, curr) => {
    if (curr.size) {
      return acc + curr.size;
    }
    return acc;
  }, 0);

  const maxFileSize = isSendEmail
    ? fileEmailConfig.maxTaskFileSize
    : fileMobileConfig.maxTaskFileSize;

  if (sumSize > maxFileSize) {
    return `Суммарный объем вложений больше ${maxFileSize / (1024 * 1024)} мегабайт.`;
  }

  return null;
};
