import React, {FC, memo} from 'react';
import {SxProps, TextField, Theme} from '@mui/material';
import {BaseTextFieldProps} from '@mui/material/TextField/TextField';
import {InputProps as StandardInputProps} from '@mui/material/Input/Input';

interface IProps extends BaseTextFieldProps {
  label?: string;
  customRootStyles?: SxProps<Theme>;
  customInputLabelStyles?: SxProps<Theme>;
  customRootInputStyles?: SxProps<Theme>;
  customInputStyles?: SxProps<Theme>;
  customHelperTextStyles?: SxProps<Theme>;
  InputProps?: Partial<StandardInputProps>;
  isMultiline?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// 1- Default styles
const rootStyles = {
  border: 'none',
  margin: 0,
  borderRadius: '6px',
  bgcolor: '#fff'
};

const inputLabelStyles = {
  border: 'none'
};

const rootInputStyles = {
  '& fieldset': {
    border: '1px solid #EEF0F3'
  },
  '&:hover fieldset': {
    border: '1px solid #E2E2E2!important'
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    border: '1px solid #E2E2E2!important'
  }
};

const inputStyles = {};

const helperTextStyles = {
  color: 'red'
};

const Input: FC<IProps> = ({
  label = '',
  customRootStyles = {},
  customInputLabelStyles = {},
  customRootInputStyles = {},
  customInputStyles = {},
  customHelperTextStyles = {},
  InputProps = {},
  isMultiline,
  ...rest
}) => {
  return (
    <TextField
      label={label}
      sx={{...rootStyles, ...customRootStyles}}
      size="small"
      variant="outlined"
      multiline={isMultiline}
      InputLabelProps={{
        sx: {
          ...inputLabelStyles,
          ...customInputLabelStyles
        }
      }}
      InputProps={{
        ...InputProps,
        sx: {
          ...rootInputStyles,
          ...customRootInputStyles
        }
      }}
      inputProps={{
        sx: {
          ...inputStyles,
          ...customInputStyles
        }
      }}
      FormHelperTextProps={{
        sx: {
          ...helperTextStyles,
          ...customHelperTextStyles
        }
      }}
      {...rest}
    />
  );
};

export default memo(Input);
