import React, {FC, memo, useMemo, useState} from 'react';
import {GridSortModel} from '@mui/x-data-grid';

import {Table} from '@/components/Table';
import {useGetSubtasksData} from '@/scenes/AllTasksPage/scenes/Subtasks/components/SubtasksTable/hooks/useGetSubtasksData';
import {PAGE_SIZE} from '@/scenes/AllTasksPage/scenes/constants';

import {useColumns} from './hooks/useColumns';
import {usePrepareSubtasksData} from './hooks/usePrepareSubtasksData';
import s from './SubtasksTable.module.css';

interface IProps {}

const SubtasksTable: FC<IProps> = () => {
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE
  });

  const {data, isLoading} = useGetSubtasksData({paginationModel, setPaginationModel, sortModel});

  const columns = useColumns();

  const rowCount = useMemo(() => {
    if (data?.meta?.totalCount) {
      return data?.meta?.totalCount || 0;
    }
    return 0;
  }, [data?.meta]);

  const {data: tableData} = usePrepareSubtasksData(data?.data);

  return (
    <div className={s.wrapper}>
      {/*<Stack direction="row" spacing={1} sx={{ mb: 1 }}>*/}
      {/*  <Button size="small" onClick={handleToggleColumns}>*/}
      {/*    Колонки*/}
      {/*  </Button>*/}
      {/*</Stack>*/}
      <Table
        columns={columns}
        rows={tableData}
        rowCount={rowCount}
        pagination
        paginationMode="server"
        sortingMode="server"
        pageSizeOptions={[PAGE_SIZE]}
        loading={isLoading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        keepNonExistentRowsSelected
        sortModel={sortModel}
        onSortModelChange={newSortModel => setSortModel(newSortModel)}
      />
    </div>
  );
};

export default memo(SubtasksTable);
