import React, {FC, memo, useEffect, useState} from 'react';
import {addDays} from 'date-fns';

import {Input} from '@/components/UIKit';
import {ITask} from '@/shared/models/tasksDataModel';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {
  isIndividualCompanySelector,
  setCompanyError,
  setDelayAtError,
  setEmployeeError,
  setTypeError,
  setUserError,
  taskEmployeeSelector,
  taskStatusSelector,
  taskTypeSelector,
  taskUserSelector
} from '@/stores/TaskEditStore';
import {ETaskStatuses} from '@/shared/constants/taskStatuses';
import {DateRangePicker} from '@/components/UIKit/DateRangePicker';
import {MetaListItem} from '@/components/MetaListItem';
import {formatDate} from '@/shared/utils/dateUtils';
import {
  checkTaskClientMsg,
  checkTaskDelayAtMsg,
  checkTaskEmployeeMsg,
  checkTaskTypeMsg,
  checkTaskUserMsg
} from '@/shared/utils/taskUtils';

interface IProps {
  task?: ITask;
  isDisabled?: boolean;
  onChangeData: (params: Record<string, Date | null | Record<string, string>>) => void;
}

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const TaskDelayBox: FC<IProps> = ({task, isDisabled, onChangeData}) => {
  const dispatch = useAppDispatch();
  const editedStatus = useAppSelector(taskStatusSelector);
  const [reasonValue, setReasonValue] = useState('');
  const [, setIsCalendarOpen] = useState(true);
  const [selectedDateDelay, setSelectedDateDelay] = useState<string | null>(null);

  const isDelayStatus = editedStatus?.id === ETaskStatuses.Delay;
  const isOpenStraightaway = isDelayStatus && task?.taskStatusId !== ETaskStatuses.Delay;

  const editedEmployee = useAppSelector(taskEmployeeSelector);
  const editedTaskType = useAppSelector(taskTypeSelector);
  const editedUser = useAppSelector(taskUserSelector);
  const isIndividualCompany = useAppSelector(isIndividualCompanySelector);

  useEffect(() => {
    if (task?.delayAt) {
      setSelectedDateDelay(formatDate(task.delayAt));
    }

    if (task?.delayTaskComment?.content) {
      setReasonValue(task?.delayTaskComment?.content);
    }
  }, [dispatch, task]);

  useEffect(() => {
    if (isDelayStatus) {
      dispatch(setDelayAtError(checkTaskDelayAtMsg(isDelayStatus && !selectedDateDelay)));
      dispatch(setCompanyError(checkTaskClientMsg(isIndividualCompany)));
      dispatch(setEmployeeError(checkTaskEmployeeMsg(!editedEmployee)));
      dispatch(setUserError(checkTaskUserMsg(!editedUser)));
      dispatch(setTypeError(checkTaskTypeMsg(editedTaskType?.id, false)));
    } else {
      dispatch(setDelayAtError(null));
    }
  }, [
    dispatch,
    isDelayStatus,
    selectedDateDelay,
    isIndividualCompany,
    editedEmployee,
    editedTaskType,
    editedUser
  ]);

  const handleChangeReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReasonValue(e.target.value);

    onChangeData({
      delay_task_comment: {
        content: e.target.value
      }
    });
  };

  const handleDateDelayChange = (val: Value) => {
    const newDateDelay = Array.isArray(val) ? val[0] : val;

    if (newDateDelay) {
      setSelectedDateDelay(formatDate(newDateDelay));

      dispatch(setDelayAtError(null));

      onChangeData({
        delay_at: newDateDelay
      });
    }
  };

  if (!isDelayStatus) {
    return null;
  }

  return (
    <>
      <div style={{display: 'flex', alignItems: 'center', minWidth: '400px'}}>
        <MetaListItem field="Отложить до"></MetaListItem>
        <DateRangePicker
          placeholder={selectedDateDelay || 'Выберите дату'}
          start={undefined}
          end={selectedDateDelay || undefined}
          isOpenStraightaway={isOpenStraightaway}
          singleDate
          isDelayTask
          setIsCalendarOpen={setIsCalendarOpen}
          minDate={addDays(new Date(), 1)}
          handleDateDelayChange={handleDateDelayChange}
        />
      </div>
      <Input
        placeholder="Комментарий при переносе"
        fullWidth
        autoComplete="off"
        value={reasonValue}
        onChange={handleChangeReason}
        label="Причина переноса"
        customRootStyles={{minWidth: '400px'}}
        InputProps={{rows: 3}}
        disabled={isDisabled}
        isMultiline={true}
      />
    </>
  );
};

export default memo(TaskDelayBox);
