import {useEffect, useMemo, useState} from 'react';
import {createSelector} from '@reduxjs/toolkit';

import {allDepartmentsQueryState, allTypesQueryState} from '@/stores/api/dictionaries';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {useGetSubtaskByIdQuery} from '@/stores/api/subtask-page/subtask-page';
import {ISubtask} from '@/shared/models/subtaskModel';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {prepareDepartment} from '@/shared/utils/departmentUtils';

interface IProps {
  taskId?: string;
}

export const useSubtaskData = ({taskId}: IProps) => {
  const {unitsIds, typesIds, departmentsIds} = usePrepareDict();

  const {isSuccess: isDepartmentsFetched} = allDepartmentsQueryState({});
  const {isSuccess: isTypesFetched} = allTypesQueryState({});

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (isDepartmentsFetched && isTypesFetched) {
      setIsReady(true);
    }
  }, [isDepartmentsFetched, isTypesFetched]);

  const memoTaskData = useMemo(() => {
    return createSelector(
      (res: ISubtask) => res,
      task => {
        if (!task) {
          return undefined;
        }

        return {
          ...task,
          department: prepareDepartment(departmentsIds[task.departmentId], unitsIds),
          taskType: typesIds[task.taskTypeId],
          user: {
            ...(task.user || {}),
            name: formatUserName(task?.user)
          }
        };
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const {data, isLoading, isError, isSuccess} = useGetSubtaskByIdQuery(
    {taskId},
    {
      skip: !taskId || !isReady,
      selectFromResult: ({data, isLoading, isError, isSuccess}) => ({
        isLoading,
        isError,
        isSuccess,
        // @ts-ignore
        data: memoTaskData(data)
      })
    }
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess
  };
};
