const POWER_ABBRS = ['байт', 'Кб', 'Мб', 'Гб'];

/**
 * Format Bytes to KB/MB/etc
 *
 * @param value
 * @param withAbbr
 * @param decimals
 * @param withValue
 * @returns {number|string}
 */

export const convertToDataStorageUnit = (
  value: number,
  withAbbr = false,
  decimals = 2,
  withValue = true
) => {
  let base = 0;
  if (value !== 0) {
    base = Math.floor(Math.log(Math.abs(value)) / Math.log(1000));
  }

  let result = withValue ? parseFloat((value / 1000 ** base).toFixed(decimals)) : '';
  if (withAbbr) {
    result += ` ${POWER_ABBRS[base]}`;
  }
  return result;
};
