import React, {FC, useState} from 'react';
import Button from '@mui/lab/LoadingButton';
import {Typography, useTheme} from '@mui/material';

import {EditTask} from '@/modals/edit/EditTask';
import {ReactComponent as EditIcon} from '@/assets/edit.svg';
import {ITask} from '@/shared/models/tasksDataModel';
import {useAppDispatch} from '@/stores/hooks.ts';
import {clearErrors} from '@/stores/TaskEditStore';

interface IProps {
  task?: ITask;
  taskId?: string;
  taskTicket?: number;
  isLoading?: boolean;
}

const EditButton: FC<IProps> = ({task, taskId, taskTicket, isLoading}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isEditTaskOpen, setIsEditTaskOpen] = useState(false);

  const handleEditTaskOpen = () => {
    dispatch(clearErrors());

    setIsEditTaskOpen(true);
  };

  return (
    <>
      <div className="task-header-button">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={handleEditTaskOpen}
          loading={isLoading}
          disabled={false}
        >
          <EditIcon />
          <Typography
            sx={{
              [theme.breakpoints.down('md')]: {
                display: 'none'
              },
              paddingRight: 5,
              paddingLeft: 5
            }}
          >
            Редактировать
          </Typography>
        </Button>

        {isEditTaskOpen && (
          <EditTask
            open={isEditTaskOpen}
            task={task}
            taskId={taskId}
            taskTicket={taskTicket}
            setIsEditTaskOpen={setIsEditTaskOpen}
          />
        )}
      </div>
    </>
  );
};

export default EditButton;
