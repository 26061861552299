import {api} from '@/stores/api';
import {ITaskModel, ITaskResponse} from '@/shared/models/tasksDataModel';
import {
  ICommonArgs,
  ICommonResponse,
  IMeta,
  IMetaResponse,
  IPagination
} from '@/shared/models/commonModel';
import {formatKeys} from '@/shared/utils/responseUtils';
import {
  companyFields,
  employeeFields,
  fromEmployeeFields,
  userFields
} from '@/shared/utils/requestParamsUtils';

interface IQueryArg extends ICommonArgs {}

export const apiWithTasks = api.injectEndpoints({
  endpoints: builder => ({
    getTasks: builder.query<IPagination<ITaskModel[]>, IQueryArg>({
      query: params => ({
        url: '/v1/tasks/',
        method: 'get',
        params: {
          include: 'from_employee,company,user,employee,delay_task_comment,',
          fields:
            'id,created_at,updated_at,done_at,department_id,task_status_id,task_source_id,task_type_id,ticket,title,subtasks.id,subtasks.is_has_files,deadline_at,direction,is_has_files,is_has_subtasks,delay_at,' +
            [userFields, employeeFields, companyFields, fromEmployeeFields].join(','),
          ...params
        }
      }),
      transformResponse: (result: ICommonResponse<ITaskResponse[]>): IPagination<ITaskModel[]> => {
        return {
          meta: formatKeys<IMetaResponse, IMeta>(result.meta),
          data: result.data.map(item => {
            return formatKeys<ITaskResponse, ITaskModel>(item);
          })
        };
      },
      // @ts-ignore
      providesTags: result => {
        return result && result?.data
          ? [...result.data.map(({id}) => ({type: 'TASKS', id})), {type: 'TASKS', id: 'LIST'}]
          : [{type: 'TASKS', id: 'LIST'}];
      }
    })
  }),
  overrideExisting: true
});

export const {useGetTasksQuery} = apiWithTasks;
