import React, {forwardRef, useEffect, useMemo, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import cn from 'classnames';
import {
  Box,
  Button,
  createFilterOptions,
  FilterOptionsState,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {addDays, format} from 'date-fns';

import {useGetTypesQuery} from '@/stores/api/filtered-dictionaries';
import {FormComboBox} from '@/components/Form/components/FormComboBox';
import {ITaskResponse} from '@/shared/models/tasksDataModel';
import {IUser} from '@/shared/models';
import {AddFilesSection} from '@/components/AddFilesSection';
import {useFileState} from '@/shared/hooks/useFileState';
import {checkSize} from '@/shared/utils/fileUtils';
import useLoading from '@/shared/hooks/useLoading';
import {useDepartmentsLogic} from '@/modals/create/hooks/useDepartmentsLogic';
import {useAppSelector} from '@/stores/hooks';
import {OptionUser} from '@/components/UIKit/OptionUser';
import TextEditor from '@/components/TextEditor/TextEditor';
import {ICompany} from '@/shared/models/companyModel';
import {DateRangePicker} from '@/components/UIKit/DateRangePicker';
import {DelayTaskConfirmation} from '@/modals/confirmation/DelayTaskConfirmation';
import {useGetEmployeesQuery} from '@/stores/api/employees.ts';
import {IChat, IChatMessage} from '@/shared/models/chatModel.ts';
import s from '@/components/Chat/Chat.module.css';
import {ChatMessage} from '@/components/Chat/components/ChatMessage';
import {IFile} from '@/shared/models/fileModel.ts';
import {downloadFile} from '@/shared/utils/downloadFiles.ts';
import {fileStorageTokenSelector} from '@/stores/FileStorageStore';
import {useCreateTaskChatMutation} from '@/stores/api/chat-page/tasks.ts';
import {useGetCompaniesQuery} from '@/stores/api/companies.ts';
import {useGetUsersQuery} from '@/stores/api/user.ts';

import {FormValues, prepareRequestData} from './utils.ts';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface IProps {
  onClose: () => void;
  onSubmit: () => void;
  chatData?: IChat;
  selectedChatMessages: IChatMessage[];
  isOpen: boolean;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 786,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 12,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const CreateTaskChat = forwardRef<HTMLInputElement, IProps>((props, _ref) => {
  const {onClose, onSubmit, chatData, selectedChatMessages, isOpen} = props;
  const [isReady, setIsReady] = useState(false);

  /**
   * CREATE TASK SUBMIT
   */
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isCompaniesDisabled, setIsCompaniesDisabled] = useState(false);
  const [isEmployeesDisabled, setIsEmployeesDisabled] = useState(false);
  const [isUsersDisabled, setIsUsersDisabled] = useState(false);
  const [submitCreateTaskChat, {isLoading: isSubmitLoading, isError}] = useCreateTaskChatMutation();

  /**
   * ОТЛОЖИТЬ ДО
   */
  const [, setIsCalendarOpen] = useState(false);
  const [selectedDateDelay, setSelectedDateDelay] = useState<Date | null>(null);
  const [isDelayConfirmationOpen, setIsDelayConfirmationOpen] = useState(false);
  const [reasonDelay, setReasonDelay] = useState<string>('');

  const handleDateDelayChange = (val: Value) => {
    const newDateDelay = Array.isArray(val) ? val[0] : val;
    if (setSelectedDateDelay && (newDateDelay instanceof Date || newDateDelay === null)) {
      setSelectedDateDelay(newDateDelay);
    }
    setIsDelayConfirmationOpen(true);
  };

  const filter = createFilterOptions<IUser>({
    trim: true,
    stringify: option => option.name + option.email
  });

  const s3Token = useAppSelector(fileStorageTokenSelector);

  const handleGetFile = async (file: IFile) => {
    await downloadFile(file, s3Token);
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: {errors},
    resetField,
    setValue
  } = useForm<FormValues>({});

  const watchCompany = watch('company');
  const watchDepartment = watch('department');
  const watchEmployee = watch('employee');
  const watchUser = watch('user');
  const watchTaskType = watch('taskType');

  /**
   *    Получаем Список типов
   *
   *  - Вставляем отдел в фильтр filters[incoming_task_types] текущий выбранный отдел
   */

  const {types, isLoading: isTypeLoading} = useGetTypesQuery(
    {
      filters: {
        incoming_task_types: String(watchDepartment?.id)
      }
    },
    {
      skip: !watchDepartment?.id,
      selectFromResult: ({data, isLoading}) => ({
        types: data ? data.filter(val => val.isTask) : [],
        isLoading
      })
    }
  );

  const companiesFilters = useMemo(() => {
    const filters: {id?: string; has_customer?: string} = {};

    if (chatData?.isBot) {
      filters.has_customer = String(chatData?.chatCustomers?.[0].customerId);
    } else {
      filters.id = String(chatData?.companyId);
    }

    return filters;
  }, [chatData]);

  /**
   * СПИСОК КЛИЕНТОВ
   */
  const {data: companies, isLoading: isCompaniesLoading} = useGetCompaniesQuery(
    {
      filters: companiesFilters
    },
    {
      skip: !companiesFilters
    }
  );

  useEffect(() => {
    if (companies?.data.length === 1) {
      const singleCompany = companies.data[0];
      setValue('company', singleCompany);
      setIsCompaniesDisabled(true);
    } else {
      setIsCompaniesDisabled(false);
    }
  }, [companies, setValue]);

  /**
   * СПИСОК СОТРУДНИКОВ
   */
  const {data: employees, isLoading: isEmployeesLoading} = useGetEmployeesQuery(
    {
      filters: {
        company_id: watchCompany?.id,
        active: 1,
        // Необходимо добавить фильтр filters[bitrix_api_id]=1
        // в запрос списка сотрудников компании при создании задачи
        bitrix_api_id: 1,
        customer_id:
          'in|' + selectedChatMessages?.map(message => String(message.customerId)).join(',')
      }
    },
    {
      skip: !watchCompany?.id,
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (employees?.length === 1) {
      const singleEmployee = employees[0];
      setValue('employee', singleEmployee);
      setIsEmployeesDisabled(true);
    } else {
      setIsEmployeesDisabled(false);
    }
  }, [employees, setValue]);

  /**
   * СПИСОК ИСПОЛНИТЕЛЕЙ
   */
  const {users, isUsersLoading} = useGetUsersQuery(
    {
      filters: {
        has_departments: watchDepartment?.id,
        active: 1,
        is_vacation: 0
      }
    },
    {
      skip: !watchDepartment?.id || !isOpen,
      selectFromResult: ({data, isLoading}) => ({
        users: data,
        isUsersLoading: isLoading
      })
    }
  );

  useEffect(() => {
    if (users?.length === 1) {
      const singleUser = users[0];
      setValue('user', singleUser);
      setIsUsersDisabled(true);
    } else {
      setIsUsersDisabled(false);
    }
  }, [users, setValue]);

  /**
   * СПИСОК ОТДЕЛОВ
   */
  const {departments, isDepartmentsLoading, isDepartmentsDisabled} = useDepartmentsLogic({
    company: watchCompany,
    isTask: true
  });

  // files
  const {files, handleDeleteFile, handleAttachFiles} = useFileState();
  const [errorFileSize, setErrorFileSize] = useState<string | null>(null);

  // @ts-ignore
  const onClickSubmit: SubmitHandler<FormValues | undefined> = async data => {
    if (Object.keys(errors).length > 0 || errorFileSize) {
      return;
    }

    if (chatData?.id && data) {
      const chatMessageIds = new Set(selectedChatMessages.map(message => message.id));
      const uniqueMessageIdsArray = Array.from(chatMessageIds);

      const requestData = {
        ...data,
        chatId: Number(chatData?.id),
        chatMessageIds: uniqueMessageIdsArray,
        attachments: files,
        delayAt: selectedDateDelay?.toISOString()
      };

      const preparedData = prepareRequestData(requestData);

      if (reasonDelay.trim() !== '') {
        preparedData.delay_task_comment = {
          content: reasonDelay.trim()
        };
      } else {
        delete preparedData.delay_task_comment;
      }

      const result: {data: ITaskResponse} | {error: unknown} = await submitCreateTaskChat(
        // @ts-ignore
        preparedData
      );

      if ('data' in result && result?.data) {
        onSubmit();
      }
    }
  };

  useEffect(() => {
    setErrorFileSize(null);
    const fileSizeError = checkSize(files);
    setErrorFileSize(fileSizeError);
    setIsSubmitDisabled(!!fileSizeError);
  }, [files]);

  useEffect(() => {
    if (departments && isDepartmentsDisabled && !isReady) {
      resetField('department', {
        defaultValue: departments[0]
      });
      setIsReady(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments, isDepartmentsDisabled, resetField]);

  useEffect(() => {
    if ((watchCompany || watchCompany === null) && watchEmployee) {
      resetField('employee');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCompany]);

  useEffect(() => {
    if (watchDepartment || watchDepartment === null) {
      if (watchTaskType) {
        resetField('taskType');
      }

      if (watchUser) {
        resetField('user');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDepartment]);

  useLoading(isSubmitLoading);

  return (
    <Box sx={style}>
      <form onSubmit={handleSubmit(onClickSubmit)}>
        <Grid container spacing={12} sx={{marginBottom: 12}}>
          <Grid item sm={6} xs={12}>
            <label htmlFor="company">
              Клиент <span className="imp">*</span>
            </label>
            <FormComboBox
              id="company"
              name="company"
              required
              isDisabled={isCompaniesDisabled}
              isLoading={isCompaniesLoading}
              options={companies?.data || []}
              control={control}
              placeholder="Выберите Клиента"
              renderOption={({className, ...props}: any, option: ICompany) => {
                return (
                  <li {...props} className={cn(className)} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="department">
              Отдел <span className="imp">*</span>
            </label>
            <FormComboBox
              id="department"
              name="department"
              required
              isDisabled={isDepartmentsDisabled}
              isLoading={isDepartmentsLoading}
              options={departments || []}
              control={control}
              placeholder="Выберите отдел"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="employee">
              Сотрудник клиента <span className="imp">*</span>
            </label>
            <FormComboBox
              id="employee"
              name="employee"
              required
              isDisabled={isEmployeesDisabled}
              isLoading={isEmployeesLoading}
              options={employees || []}
              control={control}
              placeholder="Выберите сотрудника клиента"
              renderOption={(props: any, option: IUser, {selected}: {selected: boolean}) => {
                return (
                  <OptionUser
                    {...props}
                    key={option.id}
                    option={option}
                    selected={selected}
                    isHasMobile={option.isHasMobile}
                    isSpam={option.isSpam}
                  />
                );
              }}
              filterOptions={(options: IUser[], params: FilterOptionsState<IUser>) => {
                return filter(options, params);
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Stack justifyContent="flex-end" sx={{height: '100%'}}>
              <label htmlFor="user">Исполнитель</label>
              <FormComboBox
                id="user"
                name="user"
                isDisabled={isUsersDisabled}
                isLoading={isUsersLoading}
                options={users || []}
                control={control}
                placeholder="Выберите исполнителя"
                renderOption={(props: any, option: IUser, {selected}: {selected: boolean}) => {
                  return (
                    <OptionUser {...props} key={option.id} option={option} selected={selected} />
                  );
                }}
                filterOptions={(options: IUser[], params: FilterOptionsState<IUser>) => {
                  return filter(options, params);
                }}
              />
            </Stack>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Stack
              direction="column"
              alignItems={'start'}
              sx={{height: '100%', justifyContent: 'center', marginTop: 4}}
            >
              <label htmlFor="button">Отложить до</label>
              <Stack direction="row" paddingTop={2} alignItems="center">
                <DateRangePicker
                  placeholder={
                    selectedDateDelay ? format(selectedDateDelay, 'yyyy-MM-dd') : 'Выберите дату'
                  }
                  start={undefined}
                  end={selectedDateDelay ? format(selectedDateDelay, 'yyyy-MM-dd') : undefined}
                  isOpenStraightaway={false}
                  singleDate
                  isDelayTask
                  setIsCalendarOpen={setIsCalendarOpen}
                  minDate={addDays(new Date(), 1)}
                  handleDateDelayChange={handleDateDelayChange}
                />
              </Stack>
            </Stack>
            {isDelayConfirmationOpen && (
              <DelayTaskConfirmation
                open={isDelayConfirmationOpen}
                delayData={selectedDateDelay}
                setIsDelayConfirmationOpen={setIsDelayConfirmationOpen}
                reasonDelay={reasonDelay}
                setReasonDelay={setReasonDelay}
                isCreateTask
                setIsCalendarOpen={setIsCalendarOpen}
              />
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <Stack justifyContent="flex-end" sx={{height: '100%'}}>
              <label htmlFor="taskType">
                Тип обращения <span className="imp">*</span>
              </label>
              <FormComboBox
                id="taskType"
                name="taskType"
                required
                options={types}
                isLoading={isTypeLoading}
                control={control}
                placeholder="Выберите тип обращения"
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="title">
              Тема <span className="imp">*</span>
            </label>
            <Controller
              name="title"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Обязательное поле'
                }
              }}
              defaultValue=""
              render={({field: {onChange, value}}) => (
                <TextField
                  id="title"
                  placeholder="Укажите название задачи"
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                  }}
                  fullWidth
                  autoComplete="off"
                  error={!!errors?.title}
                  helperText={errors?.title?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{maxHeight: '25vh', marginBottom: 12}}>
            <label htmlFor="content">Описание задачи</label>
            <Paper className={s.chat} sx={{height: '100%', overflowY: 'auto'}}>
              <div className={s.inner}>
                <ul className={s.messages}>
                  {selectedChatMessages &&
                    selectedChatMessages.length > 0 &&
                    selectedChatMessages.map(message => (
                      <li style={{listStyleType: 'none'}} key={message.id}>
                        <ChatMessage
                          key={message.id}
                          message={message}
                          onGetFile={handleGetFile}
                          isDisplaySystemComments={false}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="content">Внутренний комментарий к задаче</label>
            <Controller
              name="content"
              control={control}
              defaultValue=""
              render={({field: {onChange, value}}) => (
                <TextEditor
                  id="content"
                  value={value}
                  onChange={(value: string) => {
                    onChange(value);
                  }}
                  error={!!errors?.content}
                  helperText={errors?.content?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <AddFilesSection
              files={files}
              onDeleteFile={handleDeleteFile}
              onChangeFileInput={handleAttachFiles}
            />
            {errorFileSize && (
              <Typography variant="body2" color="error">
                {errorFileSize}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onClose}
            sx={{minWidth: 154, borderWidth: 2}}
          >
            Отмена
          </Button>
          {isError && <span className="error">Ошибка создания задачи</span>}
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isSubmitLoading}
            loadingIndicator="Создание…"
            sx={{minWidth: 154}}
            disabled={isSubmitDisabled || isSubmitLoading || isError}
          >
            Создать
          </LoadingButton>
        </Grid>
      </form>
    </Box>
  );
});

CreateTaskChat.displayName = 'CreateTaskChat';

export default CreateTaskChat;
