import {useEffect, useState} from 'react';
import {createSearchParams, URLSearchParamsInit, useSearchParams} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {TFilterValues} from '@/shared/models/tasksFilterModel';
import {searchSelector} from '@/stores/SearchStateStore/SearchStateSelector.ts';
import {setSearchState} from '@/stores/SearchStateStore';

export const useUpdateUrlParams = (
  filterValues: TFilterValues,
  onUpdate: ({key, value}: any) => void,
  onReset: () => void
) => {
  const dispatch = useAppDispatch();
  const searchState = useAppSelector(searchSelector);

  const [urlParams, setUrlParams] = useSearchParams();
  const [localParams, setLocalParams] = useState<URLSearchParamsInit | undefined>();
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (!urlParams) {
      onReset();
      dispatch(setSearchState(''));
      return;
    }

    // INIT: get url params and save in store
    for (const entry of urlParams.entries()) {
      const [key, value] = entry;
      if (key === 'find') {
        dispatch(setSearchState(value));
      }

      // @ts-ignore
      onUpdate({key, value});
    }

    setIsUpdated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params: any = filterValues ? {...filterValues} : {};
    params.find = searchState;

    if (isUpdated) {
      return;
    }

    for (const key in params) {
      if (
        params[key] === undefined ||
        params[key] === '' ||
        (key === 'page' && params[key] === '0')
      ) {
        delete params[key];
      }
    }
    setLocalParams(params);

    setIsUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState, filterValues]);
  //
  useEffect(() => {
    setUrlParams(createSearchParams(localParams));
  }, [localParams, setUrlParams]);

  return;
};
