export const checkTaskTypeMsg = (typeId?: number, isChangeDepartment?: boolean) => {
  if (!typeId || (typeId === 1 && !isChangeDepartment)) {
    return 'Выберите тип';
  }
  return null;
};

export const checkTaskClientMsg = (hasError: boolean) => {
  if (hasError) {
    return 'Выберите клиента';
  }
  return null;
};

export const checkTaskEmployeeMsg = (hasError: boolean) => {
  if (hasError) {
    return 'Выберите сотрудника';
  }
  return null;
};

export const checkTaskDepartmentMsg = (hasError: boolean) => {
  if (hasError) {
    return 'Выберите отдел';
  }
  return null;
};

export const checkTaskDelayAtMsg = (hasError: boolean) => {
  if (hasError) {
    return 'Выберите дату переноса';
  }
  return null;
};

export const checkTaskUserMsg = (hasError: boolean) => {
  if (hasError) {
    return 'Выберите исполнителя';
  }
  return null;
};
