import {useEffect} from 'react';

import {useGetCompaniesQuery} from '@/stores/api/companies';
import {EFilterName, IOption} from '@/shared/models/tasksFilterModel';

type TUpdate = (key: EFilterName, options: IOption[]) => void;

export const useCompanyFilters = (onUpdate: TUpdate) => {
  const {data: companiesData} = useGetCompaniesQuery({
    'filters[without_inner_support_companies]': 1
  });

  useEffect(() => {
    if (companiesData) {
      onUpdate(EFilterName.CompanyId, companiesData.data);
    }
  }, [companiesData, onUpdate]);

  return;
};
