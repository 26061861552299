import React, {FC, memo} from 'react';
import cn from 'classnames';
import {Checkbox, Divider} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

import {IUser} from '@/shared/models';

import s from './OptionUser.module.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps {
  option: IUser;
  multiple?: boolean;
  selected?: boolean;
  isHasMobile?: boolean;
  className?: string;
}

const OptionUser: FC<IProps> = ({
  option,
  className,
  multiple = false,
  selected,
  isHasMobile,
  ...props
}) => {
  return (
    <li {...props} className={cn(className, s.option)}>
      {multiple && <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />}
      <div className={s.content}>
        <span className={s.name}>{option.name}</span>
        <Divider
          orientation={'vertical'}
          variant="middle"
          flexItem
          sx={{ml: '10px', mr: '10px', borderColor: '#556675'}}
        />
        <span className={s.email}>{option.email}</span>
      </div>
      {isHasMobile && <PhoneIphoneIcon />}
    </li>
  );
};

export default memo(OptionUser);
