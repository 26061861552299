import React, {FC, useEffect, useMemo, useState} from 'react';
import {Button, CircularProgress, Collapse, Stack, Typography} from '@mui/material';

import {IQueryArg} from '@/stores/api/chats';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import useLinearLoading from '@/shared/hooks/useLinearLoading.ts';
import {useGetCompaniesQuery} from '@/stores/api/companies.ts';
import {IChat} from '@/shared/models/chatModel.ts';
import {ICompany} from '@/shared/models/companyModel.ts';
import {BlackHr, DividerHr, EllipsisTypography} from '@/shared/styles/chatStyles';
import ChatInfoTasks from '@/scenes/ChatsPage/components/ChatInfoTasks/ChatInfoTasks';
import {MetaListItem} from '@/components/MetaListItem';

interface IProps {
  chatData?: IChat;
}

const ChatInfoCompanies: FC<IProps> = ({chatData}) => {
  const [isOpenCompanies, setIsOpenCompanies] = useState<boolean>(false);

  const companiesQuery = useMemo(() => {
    const page = {
      limit: 0,
      offset: 0
    };

    const filters: {id?: string; has_customer?: string} = {};

    if (chatData?.isBot) {
      filters.has_customer = String(chatData?.chatCustomers?.[0].customerId);
    } else {
      filters.id = String(chatData?.companyId);
    }

    const include = 'company_responsibles,company_responsibles.user';

    const fields = 'bitrix_id,company_responsibles.id';

    const params: IQueryArg = {
      page,
      filters,
      include,
      fields
    };

    return params;
  }, [chatData]);

  const {
    data: companiesData,
    isLoading: isLoadingCompanies,
    isFetching: isFetchingCompanies
  } = useGetCompaniesQuery(companiesQuery, {
    skip: !chatData || !companiesQuery,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    setIsOpenCompanies(!isFetchingCompanies && !isLoadingCompanies);
  }, [isFetchingCompanies, isLoadingCompanies]);

  useLinearLoading(isFetchingCompanies || isLoadingCompanies);

  return (
    <Stack padding={6}>
      <Button
        variant={'contained'}
        color={'secondary'}
        onClick={() => setIsOpenCompanies(prevState => !prevState && !isFetchingCompanies)}
      >
        <Typography variant="h2">Компании</Typography>
      </Button>
      {(!chatData || isFetchingCompanies || isLoadingCompanies) && (
        <Stack padding={6} alignItems={'center'}>
          <CircularProgress color="inherit" />
        </Stack>
      )}
      <Collapse
        in={isOpenCompanies && !isFetchingCompanies && !isLoadingCompanies}
        timeout="auto"
        unmountOnExit
      >
        {companiesData && (
          <>
            <Stack direction="column" spacing={2} paddingTop={8} paddingBottom={8}>
              {companiesData?.data.map((company: ICompany, index: number) => (
                <React.Fragment key={company.bitrixId}>
                  <Stack paddingTop={2}>
                    <Typography variant="subtitle2" fontSize={16}>
                      {company.name}
                    </Typography>
                    <Stack>
                      <DividerHr />
                      <Stack paddingLeft={8}>
                        <MetaListItem
                          field="Битрикс ID"
                          title={String(company.bitrixId)}
                          linkTo={`https://peb.bitrix24.ru/crm/company/details/${company.bitrixId}/`}
                        >
                          {company.bitrixId || '-'}
                        </MetaListItem>
                      </Stack>
                      <Stack paddingTop={4}>
                        <Typography variant="subtitle1" fontSize={14} paddingLeft={8}>
                          Прикрепленные сотрудники
                        </Typography>
                        {company.companyResponsibles?.map(companyResponsible => (
                          <>
                            <DividerHr />
                            <Stack direction={'column'} paddingLeft={8}>
                              <EllipsisTypography variant="body1">
                                {formatUserName(companyResponsible.user)}
                              </EllipsisTypography>
                              <EllipsisTypography variant="body2" sx={{color: 'var(--mute-color)'}}>
                                {companyResponsible.user?.position}
                              </EllipsisTypography>
                            </Stack>
                          </>
                        ))}
                      </Stack>
                    </Stack>
                    <ChatInfoTasks companyId={company.id} />
                    {index < companiesData.data.length - 1 && <BlackHr />}
                  </Stack>
                </React.Fragment>
              ))}
            </Stack>
          </>
        )}
      </Collapse>
    </Stack>
  );
};

export default ChatInfoCompanies;
