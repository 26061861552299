import React, {FC, memo, useState, useMemo} from 'react';
import {GridRowParams, GridSortModel} from '@mui/x-data-grid';
import {generatePath, useNavigate} from 'react-router';

import {Table} from '@/components/Table';
import {RoutePaths} from '@/shared/constants/route';
import useLinearLoading from '@/shared/hooks/useLinearLoading.ts';

import {useGetTasksData} from '../../hooks/useGetTasksData';

import {useColumns} from './useColumns';
import {useSupportTasksData} from './useSupportTasksData';
import s from './SupportTasksTable.module.css';

interface IProps {}

const PAGE_SIZE = 20;

const SupportTasksTable: FC<IProps> = () => {
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE
  });

  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

  const {data, isLoading, isFetching} = useGetTasksData({
    paginationModel,
    setPaginationModel,
    sortModel
  });

  const columns = useColumns();

  const rowCount = useMemo(() => {
    if (data?.meta?.totalCount) {
      return data?.meta?.totalCount || 0;
    }
    return 0;
  }, [data?.meta]);

  const {data: tableData} = useSupportTasksData(data?.data);

  const handleRowClick = (task: GridRowParams) => {
    const path = generatePath(RoutePaths.SupportTaskPage, {taskId: `${task.id}`});
    navigate(path);
  };

  useLinearLoading(isFetching);

  return (
    <div className={s.wrapper}>
      {/*<Stack direction="row" spacing={1} sx={{ mb: 1 }}>*/}
      {/*  <Button size="small" onClick={handleToggleColumns}>*/}
      {/*    Колонки*/}
      {/*  </Button>*/}
      {/*</Stack>*/}
      <Table
        columns={columns}
        rows={tableData}
        rowCount={rowCount}
        pagination
        paginationMode="server"
        sortingMode="server"
        pageSizeOptions={[PAGE_SIZE]}
        loading={isLoading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        keepNonExistentRowsSelected
        sortModel={sortModel}
        onSortModelChange={newSortModel => setSortModel(newSortModel)}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default memo(SupportTasksTable);
