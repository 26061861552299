import React, {FC, HTMLAttributes, useEffect, useState} from 'react';
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import {ComboBox} from '@/components/UIKit';
import {IUser} from '@/shared/models';
import {useGetUsersQuery} from '@/stores/api/user';
import {useSupportTaskWatchersMutation} from '@/stores/api/support-task-page/support-task-watchers';
// import {ISupportTask} from '@/shared/models/supportTaskModel';

interface IProps {
  open: boolean;
  onClose: () => void;
  task: any;
  userId?: number;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 480,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const AddTaskWatcher: FC<IProps> = ({open, onClose, task, userId}) => {
  const [initialSelectedEmployees, setInitialSelectedEmployees] = useState<number[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<number[]>([]);
  const [commentForSpectators, setCommentForSpectators] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {data: possibleWatchers, isLoading: isWatchersLoading} = useGetUsersQuery(
    {
      'filters[id]': `not_in|${task ? `${task.authorId},${userId}` : ''}`,
      'filters[active]': 1,
      'filters[is_vacation]': 0
    },
    {
      skip: !task?.departmentId
    }
  );

  const OptionsValues =
    possibleWatchers?.filter(watcher => selectedEmployees.includes(watcher.id)) ?? [];

  useEffect(() => {
    if (task?.supportTaskSpectators && possibleWatchers) {
      const selected = Object.values(task.supportTaskSpectators).map(
        (spectator: any) => spectator.userId
      );
      setInitialSelectedEmployees(selected);
      setSelectedEmployees(selected);
    }
  }, [task, possibleWatchers]);

  useEffect(() => {
    if (task?.supportTaskSpectators && possibleWatchers) {
      const selected = Object.values(task.supportTaskSpectators).map(
        (spectator: any) => spectator.userId
      );
      setSelectedEmployees(selected);
    }
  }, [task, possibleWatchers]);

  useEffect(() => {
    if (task?.spectatorSupportTaskComment) {
      setCommentForSpectators(task.spectatorSupportTaskComment.content);
    }
  }, [task]);

  const [submitWatchers] = useSupportTaskWatchersMutation();

  const handleSubmit = async (event: React.FormEvent) => {
    setIsLoading(true);
    event.preventDefault();

    const payload: any = {
      supportTaskId: task.id,
      userIds: selectedEmployees
    };

    const newWatchers = selectedEmployees.filter(id => !initialSelectedEmployees.includes(id));

    if (
      newWatchers.length > 0 &&
      typeof commentForSpectators === 'string' &&
      commentForSpectators.trim()
    ) {
      payload.spectatorSupportTaskComment = {
        content: commentForSpectators
      };
    }

    await submitWatchers(payload);
    setIsLoading(false);
    onClose();
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const spectatorSupportTaskComment = task?.spectatorSupportTaskComment?.content;

  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: IUser[] | string | IUser | (string | IUser)[] | null
  ) => {
    if (Array.isArray(value)) {
      const newSelectedEmployees = value.map(watcher =>
        typeof watcher === 'object' ? watcher.id : 0
      );
      setSelectedEmployees(newSelectedEmployees.filter(id => id !== 0));
    }
  };
  return (
    <Modal
      open={open}
      aria-labelledby="Перевод в другой отдел"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <form action="" onSubmit={handleSubmit}>
          <Typography sx={{marginBottom: 15}} variant="h2">
            Наблюдатели
          </Typography>
          <ComboBox<IUser>
            isLoading={isWatchersLoading}
            options={possibleWatchers ?? []}
            variantInput="standard"
            fieldLabel="Добавить или удалить"
            disableCloseOnSelect
            multiple
            sx={{
              minWidth: '240px'
            }}
            value={OptionsValues as any}
            disableClearable
            onChange={handleChange}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: IUser,
              {selected}: {selected: boolean}
            ) => (
              <li {...props} key={option.id}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                {`${option.name}`}
              </li>
            )}
            renderTags={(values: IUser[], getTagProps) => {
              return values.map((option: IUser, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip variant="outlined" label={`${option.name}`} {...getTagProps({index})} />
              ));
            }}
          />
          {!spectatorSupportTaskComment && (
            <TextField
              id="content"
              placeholder="Комментарий для наблюдателей"
              value={commentForSpectators}
              onChange={e => setCommentForSpectators(e.target.value)}
              fullWidth
              multiline
              autoComplete="off"
              rows={6}
              sx={{paddingTop: 20}}
              disabled={Boolean(spectatorSupportTaskComment)}
            />
          )}
          {spectatorSupportTaskComment && (
            <div style={{marginTop: 20}}>
              Комментарий для наблюдателя: {spectatorSupportTaskComment}
            </div>
          )}
          <Grid container justifyContent="space-between" sx={{paddingTop: 20}}>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={onClose}
                sx={{minWidth: 140}}
                disabled={false}
              >
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disabled={selectedEmployees.length === 0}
                sx={{minWidth: 140}}
              >
                {isLoading ? <CircularProgress size={24} /> : 'ОК'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default AddTaskWatcher;
