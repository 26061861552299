import React from 'react';
import {Checkbox, FormGroup, FormControlLabel, FormLabel} from '@mui/material';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';

import {IOption} from '@/shared/models/tasksFilterModel';

interface IProps<T extends IOption, TField extends FieldValues> {
  label?: string;
  control: Control<TField>;
  name: Path<TField>;
  options: T[];
  disabledCheckboxes: (string | number)[];
  onOptionChange: (name: string, value: string) => void;
  className?: string;
}

const FormMultiCheckbox = <T extends IOption, TField extends FieldValues>({
  name,
  control,
  label,
  options,
  className,
  disabledCheckboxes = [],
  onOptionChange
}: IProps<T, TField>) => {
  return (
    <FormGroup>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <div className={className}>
        {options.map(option => {
          return (
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  render={({field}) => {
                    const {value} = field;

                    return (
                      <Checkbox
                        checked={value.includes(option.value)}
                        disabled={disabledCheckboxes.includes(option.id)}
                        onChange={() => {
                          if (!option.value) {
                            return;
                          }
                          onOptionChange(name, option.value);
                        }}
                        sx={{
                          '&.Mui-disabled': {
                            color: theme => theme.palette.primary.main
                          }
                        }}
                      />
                    );
                  }}
                  control={control}
                />
              }
              label={option.name}
              key={option.id}
              disabled={disabledCheckboxes.includes(option.id)}
            />
          );
        })}
      </div>
    </FormGroup>
  );
};

export default FormMultiCheckbox;
