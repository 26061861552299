import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ILogin} from '@/shared/models/authModel';
import {IUser} from '@/shared/models';

export interface IAuthState {
  isAuth?: boolean;
  accessToken?: string;
  refreshToken?: string;
  userInfo?: IUser;
}

const initialState: IAuthState = {
  isAuth: false
};

export const logout = createAction('authState/logout');

export const authStateSlice = createSlice({
  name: 'authState',
  initialState,
  reducers: {
    setAuthTokens: (state, action: PayloadAction<ILogin>) => {
      state.isAuth = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    setUserInfo: (state, {payload}: PayloadAction<IUser>) => {
      state.userInfo = payload;
    },
    resetAuthState: () => {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(logout, () => {
      return initialState;
    });
  }
});

export const {resetAuthState, setAuthTokens, setUserInfo} = authStateSlice.actions;

export const authStateReducer = authStateSlice.reducer;
