import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
  EFilterName,
  IFilterState,
  IOption,
  TFilterCombination
} from '@/shared/models/tasksFilterModel';
import {logout} from '@/stores/AuthStore';
import {apiWithDictionaries} from '@/stores/api/dictionaries';

import {initialState, updateFiltersFromDictApi} from './utils';

export const supportTasksFilterStateSlice = createSlice({
  name: 'supportTasksFilterState',
  initialState,
  reducers: {
    updateListOptionByKey: (
      state,
      action: PayloadAction<{key: EFilterName; options: IOption[]}>
    ) => {
      if (action.payload) {
        const {key, options} = action.payload;
        const initOptions = initialState.filterList[key].options;

        state.filterList[key] = {
          ...state.filterList[key],
          options: [...initOptions, ...options]
        };
      }
    },
    updateTempValueByKey: (
      state: IFilterState,
      action: PayloadAction<{key: EFilterName; value?: string}>
    ) => {
      if (action.payload) {
        const {key, value} = action.payload;
        if (key) {
          state.tempFilterValues[key] = value;
        }
      }
    },
    updateValueByKey: (
      state: IFilterState,
      action: PayloadAction<{key: TFilterCombination; value?: string}>
    ) => {
      if (action.payload) {
        const {key, value} = action.payload;
        if (key) {
          state.filterValues[key] = value;
        }
      }
    },
    // updateAdditionalValueByKey: (state, action: PayloadAction<{key: EFilterName; value?: string}>) => {
    //   if (action.payload) {
    //     const {key, value} = action.payload;
    //     state.additionalValues[key] = value;
    //   }
    // },
    // // TODO
    // updateDatesValueByKey: (state, action: PayloadAction<{key: EFilterName; value?: string}>) => {
    //   if (action.payload) {
    //     const {key, value} = action.payload;
    //     state.filterListDates[key] = value;
    //   }
    // },
    toggleUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdated = action.payload;
    },
    resetFilterValues: state => {
      state.filterValues = initialState.filterValues;
    },
    resetState: state => {
      state.tempFilterValues = initialState.tempFilterValues;
      state.filterValues = initialState.filterValues;
      state.isUpdated = initialState.isUpdated;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => {
        return initialState;
      })
      // .addMatcher<any>(apiWithDictionaries.endpoints.getAllStatuses.matchFulfilled, (...args) =>
      //   updateFiltersFromDictApi(...args)(EFilterName.Statuses)
      // )
      .addMatcher<any>(apiWithDictionaries.endpoints.getAllUnits.matchFulfilled, (...args) =>
        updateFiltersFromDictApi(...args)(EFilterName.Units)
      );
  }
});

export const {
  updateListOptionByKey,
  updateTempValueByKey,
  updateValueByKey,
  toggleUpdate,
  resetFilterValues,
  resetState
} = supportTasksFilterStateSlice.actions;

export const supportTasksFilterReducer = supportTasksFilterStateSlice.reducer;
