import React, {FC} from 'react';
import {Backdrop, Box, Button, Grid, Modal, TextField, Typography} from '@mui/material';

import {ETaskStatuses} from '@/shared/constants/taskStatuses';
import {formatDate} from '@/shared/utils/dateUtils';

interface IProps {
  open: boolean;
  delayData?: Date | null;
  onChangeStatus?: (status: number) => void;
  setIsDelayConfirmationOpen: (isOpen: boolean) => void;
  reasonDelay?: string;
  setReasonDelay?: any;
  setIsCalendarOpen?: any;
  isCreateTask?: boolean;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 480,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const DelayTaskConfirmation: FC<IProps> = ({
  open,
  delayData,
  onChangeStatus,
  setIsDelayConfirmationOpen,
  reasonDelay,
  setReasonDelay,
  setIsCalendarOpen,
  isCreateTask
}) => {
  const handleSubmit = async () => {
    if (!isCreateTask) {
      if (onChangeStatus) {
        onChangeStatus(ETaskStatuses.Delay);
      }
    }
    setIsDelayConfirmationOpen(false);
    setIsCalendarOpen(false);
  };
  const formattedDelayDate = delayData ? formatDate(delayData) : 'Нет даты';

  return (
    <Modal
      open={open}
      aria-labelledby="Перенести решение задачи"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <form action="" onSubmit={handleSubmit}>
          <Typography sx={{marginBottom: 15}} variant="h2">
            Перенести решение задачи на {formattedDelayDate}?
          </Typography>
          <TextField
            id="content"
            placeholder="Причина"
            value={reasonDelay}
            onChange={e => setReasonDelay(e.target.value)}
            fullWidth
            multiline
            autoComplete="off"
            rows={6}
          />
          <Grid sx={{paddingTop: 15}} container justifyContent="space-between">
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={() => {
                  setIsDelayConfirmationOpen(false);
                  setReasonDelay('');
                }}
                sx={{minWidth: 140}}
                disabled={false}
              >
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSubmit}
                type="button"
                variant="contained"
                color="primary"
                disabled={false}
                sx={{minWidth: 140}}
              >
                Перенести
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default DelayTaskConfirmation;
