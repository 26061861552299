import {useEffect} from 'react';

import {EFilterName, IOption} from '@/shared/models/tasksFilterModel';
import {allTypesQueryState} from '@/stores/api/dictionaries';

type TUpdate = (key: EFilterName, options: IOption[]) => void;

export const useTaskTypesFilters = (onUpdate: TUpdate) => {
  const {data: types} = allTypesQueryState({});

  useEffect(() => {
    if (types) {
      onUpdate(EFilterName.Types, types);
    }
  }, [onUpdate, types]);

  return;
};
