import {api} from '@/stores/api';
import {ISupportTaskResponse} from '@/shared/models/supportTaskModel';

interface IFileArg {
  name: string;
  file: string;
}

interface ICreateTaskArg {
  title: string;
  task_id?: number | string;
  company_id: number | string;
  unit_id?: number | string;
  department_id?: number | string;
  user_id?: number;
  task_type_id: number | string;
  support_task_comment: {
    content: string;
    attachments?: IFileArg[];
  };
}

export const apiWithCreateSupportTask = api.injectEndpoints({
  endpoints: builder => ({
    // form
    createSupportTask: builder.mutation<ISupportTaskResponse, ICreateTaskArg>({
      query: (data: ICreateTaskArg) => ({
        url: '/v1/support_tasks/',
        method: 'post',
        data
      }),
      // обновляем таблицу
      invalidatesTags: [{type: 'SUPPORT_TASKS', id: 'LIST'}]
    })
  }),
  overrideExisting: true
});

export const {useCreateSupportTaskMutation} = apiWithCreateSupportTask;
