import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
  EFilterName,
  IFilterState,
  IOption,
  TFilterCombination
} from '@/shared/models/tasksFilterModel';
import {logout} from '@/stores/AuthStore';

import {initialState} from './utils';

export const subtasksFilterStateSlice = createSlice({
  name: 'subtasksFilterState',
  initialState,
  reducers: {
    updateListOptionByKey: (
      state,
      action: PayloadAction<{key: EFilterName; options: IOption[]}>
    ) => {
      if (action.payload) {
        const {key, options} = action.payload;
        const initOptions = initialState.filterList[key].options;

        state.filterList[key] = {
          ...state.filterList[key],
          options: [...initOptions, ...options]
        };
      }
    },
    updateTempValueByKey: (
      state: IFilterState,
      action: PayloadAction<{key: EFilterName; value?: string}>
    ) => {
      if (action.payload) {
        const {key, value} = action.payload;
        if (key) {
          state.tempFilterValues[key] = value;
        }
      }
    },
    updateValueByKey: (
      state: IFilterState,
      action: PayloadAction<{key: TFilterCombination; value?: string}>
    ) => {
      if (action.payload) {
        const {key, value} = action.payload;
        if (key) {
          state.filterValues[key] = value;
        }
      }
    },
    toggleUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdated = action.payload;
    },
    resetFilterValues: state => {
      state.filterValues = initialState.filterValues;
    },
    resetState: state => {
      state.tempFilterValues = initialState.tempFilterValues;
      state.filterValues = initialState.filterValues;
      state.isUpdated = initialState.isUpdated;
    }
  },
  extraReducers: builder => {
    builder.addCase(logout, () => {
      return initialState;
    });
  }
});

export const {
  updateListOptionByKey,
  updateTempValueByKey,
  updateValueByKey,
  toggleUpdate,
  resetFilterValues,
  resetState
} = subtasksFilterStateSlice.actions;

export const subtasksFilterReducer = subtasksFilterStateSlice.reducer;
