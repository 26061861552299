export const getCounterFilters = (useUserPrefix = false) => {
  const counterKey = useUserPrefix ? 'has_unread_comments' : 'has_user_unread_comments';

  const counterFilters: Record<string, string> = {
    new_count: 'filters[new_tasks]',
    overdue_count: 'filters[overdue_tasks]',
    work_count: 'filters[work_tasks]',
    unread_comments_count: `filters[${counterKey}]`,
    spectator_count: 'filters[spectator_tasks]'
  };

  return counterFilters;
};
