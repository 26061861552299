import {useEffect, useMemo, useState} from 'react';

import {
  allDepartmentsQueryState,
  allSourcesQueryState,
  allStatusesQueryState,
  allTypesQueryState,
  allUnitsQueryState
} from '@/stores/api/dictionaries';
import {IDepartment, ISource, IStatus, IType, IUnit} from '@/shared/models/dictionaryModel';
import {departmentNotDefined} from '@/shared/constants/departmentConstants';
import {useAppDispatch} from '@/stores/hooks';
import {setDepartmentNotDefinedId} from '@/stores/TaskConditionsStore';

export const usePrepareDict = () => {
  const dispatch = useAppDispatch();
  const [isReady, setIsReady] = useState(false);
  const {data: departments, isSuccess: isDepartmentsSuccess} = allDepartmentsQueryState({});
  const {data: types, isSuccess: isTypesSuccess} = allTypesQueryState({});
  const {data: statuses, isSuccess: isStatusesSuccess} = allStatusesQueryState({});
  const {data: sources, isSuccess: isSourcesSuccess} = allSourcesQueryState({});
  const {data: units, isSuccess: isUnitsSuccess} = allUnitsQueryState({});

  useEffect(() => {
    if (
      isDepartmentsSuccess &&
      isTypesSuccess &&
      isStatusesSuccess &&
      isSourcesSuccess &&
      isUnitsSuccess
    ) {
      setIsReady(true);
    }
  }, [isDepartmentsSuccess, isSourcesSuccess, isStatusesSuccess, isTypesSuccess, isUnitsSuccess]);

  useEffect(() => {
    if (departments) {
      // если отдел неопределен, записываем его id на будущее
      const notDefDep = departments.find(({name}) => name === departmentNotDefined);

      if (notDefDep?.id) {
        dispatch(setDepartmentNotDefinedId(notDefDep.id));
      }
    }
  }, [departments, dispatch]);

  const departmentsIds: Record<string, IDepartment> = useMemo(() => {
    if (!departments) {
      return {};
    }
    return departments.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr
      };
    }, {});
  }, [departments]);

  const typesIds: Record<string, IType> = useMemo(() => {
    if (!types) {
      return {};
    }
    return types.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr
      };
    }, {});
  }, [types]);

  const statusesIds: Record<string, IStatus> = useMemo(() => {
    if (!statuses) {
      return {};
    }
    return statuses.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr
      };
    }, {});
  }, [statuses]);

  const sourcesIds: Record<string, ISource> = useMemo(() => {
    if (!sources) {
      return {};
    }
    return sources.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr
      };
    }, {});
  }, [sources]);

  const unitsIds: Record<string, IUnit> = useMemo(() => {
    if (!units) {
      return {};
    }
    return units.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr
      };
    }, {});
  }, [units]);

  return {
    departmentsIds,
    typesIds,
    statusesIds,
    sourcesIds,
    unitsIds,
    isReady
  };
};
