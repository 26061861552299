export interface IOption {
  id: string | number;
  name: string;
  isActive?: boolean;
  companyTypeId?: number;
  value?: string;
}

export interface IDateRange {
  start?: string;
  end?: string;
}

export enum EFilterName {
  Units = 'unit_id',
  Departments = 'department_id',
  Users = 'user_id',
  Statuses = 'task_status_id',
  Direction = 'direction',
  Sources = 'task_source_id',
  Types = 'task_type_id',
  CreatedAt = 'created_at',
  DoneAt = 'done_at',
  Head = 'head',
  Author = 'author_id',
  Employee = 'employee',
  CompanyId = 'company_id',
  SenderEmail = 'senderEmail'
}

export const DateFilterNames = [EFilterName.CreatedAt, EFilterName.DoneAt];

export interface IFilterOption<T> {
  fieldName?: EFilterName;
  fieldLabel?: string;
  placeholder?: string;
  defaultValue?: T | T[];
  options: T[];
  isLoading?: boolean;
  isMultiply?: boolean;
  value?: T | null;
  selectAll?: boolean;
  minWidth?: number;
  type?: 'date';
}

export enum EAdditionalValues {
  Page = 'page',
  Sort = 'sort'
}

export const combo = {...EFilterName, ...EAdditionalValues};
export type TFilterCombination = (typeof combo)[keyof typeof combo];

export type TTempFilterValues = Record<EFilterName, string | undefined>;
export type TFilterValues = Record<TFilterCombination, string | undefined>;
export type TDateFilterRange = Record<string, IDateRange | undefined>;

export interface IFilterState {
  filterList: Record<string, IFilterOption<IOption>>;
  tempFilterValues: TTempFilterValues;
  filterValues: TFilterValues;
  filterListDates: TDateFilterRange;
  isFetched: boolean;
  isUpdated: boolean;
}

export type TFilterParams = Record<EFilterName, string>;
