import {IOption} from '@/shared/models/tasksFilterModel';
import {ITask} from '@/shared/models/tasksDataModel';
import {ICompany} from '@/shared/models/companyModel';
import {IFile} from '@/shared/models/fileModel';
import {IDepartment} from '@/shared/models/dictionaryModel';
import {IUser} from '@/shared/models';

export type FormValues = {
  company: ICompany;
  department: IDepartment;
  employee: IUser;
  taskType: IOption;
  title: string;
  task: ITask;
  content: string;
  attachments?: IFile[];
};

export const prepareRequestData = (data: FormValues) => {
  const {company, department, employee, taskType, title, task, content, attachments} = data;

  // При создании подзадачи если отдел кросс-юнит отправляем unit_id выбранного клиента, иначе unit_id выбранного отдела
  const unitId = department?.isCrossUnit ? company.unitId : department.unitDepartment?.unitId;

  const params = {
    title,
    company_id: company.id,
    user_id: employee.id,
    task_type_id: taskType.id,
    department_id: department.id,
    unit_id: unitId,
    subtask_comment: {
      content,
      attachments
    }
  };

  if (task?.id) {
    return {
      ...params,
      task_id: task.id
    };
  }
  return params;
};
