import {useEffect, useMemo} from 'react';

import {prepareSortFunc} from '@/shared/utils/sortUtils';
import {prepareUrlParams} from '@/shared/utils/urlParamsUtils';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {TPaginationModel, ITableHookProps} from '@/shared/models/tableModel';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {updateValueByKey} from '@/stores/TasksFilterStore';
import {EAdditionalValues, EFilterName} from '@/shared/models/tasksFilterModel';
import {individualCompanies, innerSupportCompanies} from '@/shared/constants/companyConstants';
import {supportTasksFilterValuesSelector} from '@/stores/SupportTasksFilterStore/SupportTasksFilterSelector.ts';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector.ts';
import {useGetSupportTasksQuery} from '@/stores/api/support-tasks-table/support-tasks-table.ts';
import {counterSelector, searchSelector} from '@/stores/SearchStateStore/SearchStateSelector.ts';
import {getCounterFilters} from '@/shared/utils/countersUtils';
import {withoutUser} from '@/shared/constants/userConstants.ts';

interface QueryParams {
  page: {
    limit: number;
    offset: number;
  };
  sort?: string;
  'filters[department_id]'?: any;
  'filters[user_id]'?: any;
  'filters[author_id]'?: any;
  'filters[task_status_id]'?: any;
  'filters[task_source_id]'?: any;
  'filters[task_type_id]'?: any;
  'filters[unit_id]'?: any;
  'filters[company_id]'?: any;
  'counters[user_unread_comments]'?: number;
  [key: string]: any;
}

export const useGetTasksData = ({
  paginationModel,
  setPaginationModel,
  sortModel
}: ITableHookProps) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(userInfoSelector);
  const filterValues = useAppSelector(supportTasksFilterValuesSelector);
  const searchState = useAppSelector(searchSelector);
  const counterState = useAppSelector(counterSelector);

  const {isReady} = usePrepareDict();

  const sort = useMemo(() => {
    if (!sortModel.length) {
      return 'active_support_tasks';
    }
    return prepareSortFunc(sortModel);
  }, [sortModel]);

  const showOnlyMyTasks = useMemo(() => {
    let i = 0;
    if (searchState !== '') {
      return false;
    }
    for (const entry of Object.entries<any>(filterValues)) {
      const [key, val] = entry;
      // @ts-ignore
      if (![EAdditionalValues.Page, EAdditionalValues.Sort].includes(key) && val) {
        i++;
      }
    }

    return i === 0;
  }, [filterValues, searchState]);

  useEffect(() => {
    dispatch(
      updateValueByKey({
        key: EAdditionalValues.Page,
        value: `${paginationModel.page}`
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page]);

  const urlPage: number = filterValues[EAdditionalValues.Page]
    ? parseInt(filterValues[EAdditionalValues.Page] || '0', 10)
    : 0;

  useEffect(() => {
    setPaginationModel((state: TPaginationModel) => {
      if (state.page === urlPage) {
        return state;
      }
      return {
        pageSize: state.pageSize,
        page: urlPage
      };
    });
  }, [setPaginationModel, urlPage]);

  const query = useMemo(() => {
    const params: QueryParams = {
      page: {
        limit: paginationModel.pageSize,
        offset: paginationModel.page === 0 ? 0 : paginationModel.page * paginationModel.pageSize
      },
      sort,
      'filters[head]': prepareUrlParams(filterValues[EFilterName.Head]),
      'filters[user_id]': prepareUrlParams(filterValues[EFilterName.Users]),
      'filters[author_id]': prepareUrlParams(filterValues[EFilterName.Author]),
      'filters[task_status_id]': prepareUrlParams(filterValues[EFilterName.Statuses]),
      'filters[task_source_id]': prepareUrlParams(filterValues[EFilterName.Sources]),
      'filters[task_type_id]': prepareUrlParams(filterValues[EFilterName.Types]),
      'filters[company_id]': prepareUrlParams(filterValues[EFilterName.CompanyId]),
      // ts
      'counters[unread_comments]': currentUser.id
    };

    // Для задач и подзадач при выбранном фильтре в запросе использовать filters[physic_companies]=1
    if (filterValues[EFilterName.CompanyId] === individualCompanies) {
      // @ts-ignore
      params['filters[physic_companies]'] = 1;
    }

    if (filterValues[EFilterName.CompanyId] === innerSupportCompanies) {
      // @ts-ignore
      params['filters[inner_companies]'] = 1;
    }

    if (filterValues[EFilterName.Users] === withoutUser) {
      params['filters[user_id]'] = 'is|null';
    }

    // вынесено, потому что падает ошибка, если отправляется пустой ответ
    if (filterValues[EFilterName.Direction]) {
      // @ts-ignore
      params['filters[direction]'] = filterValues[EFilterName.Direction];
    }

    const allCountersFilters = getCounterFilters(true);
    const counterFilter = allCountersFilters[counterState as keyof typeof allCountersFilters];

    // В разделе тех. поддержки в запрос списка задач нужно добавить filters[my_support_tasks]=1
    if (showOnlyMyTasks && counterFilter !== 'filters[spectator_tasks]') {
      // @ts-ignore
      params['filters[my_support_tasks]'] = 1;
    }

    if (typeof counterState !== 'undefined' && counterState) {
      // @ts-ignore
      params[counterFilter] =
        counterFilter === 'filters[has_unread_comments]' ||
        counterFilter === 'filters[spectator_tasks]'
          ? `${currentUser.id}`
          : 1;
    }

    if (searchState) {
      return {
        ...params,
        find: searchState
      };
    }

    return params;
  }, [
    paginationModel.pageSize,
    paginationModel.page,
    sort,
    filterValues,
    currentUser.id,
    showOnlyMyTasks,
    counterState,
    searchState
  ]);

  const {data, isLoading, isFetching} = useGetSupportTasksQuery(query, {
    skip: !isReady,
    refetchOnMountOrArgChange: true,
    pollingInterval: 30000
  });

  return {
    data,
    isLoading,
    isFetching
  };
};
