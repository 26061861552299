import React, {FC, memo, useEffect, useState} from 'react';
import {Box, Stack} from '@mui/material';

import {InputFile} from '@/components/UIKit/InputFile';
import {IFile} from '@/shared/models/fileModel';
import prepareAddFiles from '@/shared/utils/addFiles';

import {SimpleFile} from './components/SimpleFile';
import s from './AddFilesSection.module.css';

interface IProps {
  files: IFile[];
  onDeleteFile: (id: number) => void;
  onChangeFileInput: (files: IFile[]) => void;
}

const AddFilesSection: FC<IProps> = ({files = [], onChangeFileInput, onDeleteFile}) => {
  const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);

  const handleFileInput = async (files: FileList) => {
    if (files) {
      const formattedFiles = await prepareAddFiles(files);

      if (formattedFiles) {
        const combineFiled = [...selectedFiles, ...formattedFiles];
        setSelectedFiles(state => [...state, ...formattedFiles]);
        onChangeFileInput(combineFiled);
      }
    }
  };

  useEffect(() => {
    return () => {
      setSelectedFiles([]);
    };
  }, []);

  return (
    <Box>
      <Stack direction="column" spacing={4}>
        {files.map((file, index) => (
          <SimpleFile key={file.name} file={file} onDeleteFile={() => onDeleteFile(index)} />
        ))}
      </Stack>
      <InputFile
        id="create-task-attachments"
        block
        multiple
        onChange={handleFileInput}
        className={s.file}
      />
    </Box>
  );
};

export default memo(AddFilesSection);
