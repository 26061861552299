import {api} from '@/stores/api';
import {ISubtask, ISubtaskResponse, ISubtaskUpdateRequest} from '@/shared/models/subtaskModel';
import {formatKeys, hasInvalidatedChanges} from '@/shared/utils/responseUtils';
import {
  authorFields,
  companyFields,
  parentTaskFields,
  subtaskCommentsFields,
  userFields
} from '@/shared/utils/requestParamsUtils';
import {withSocket} from '@/shared/constants/appConstants.ts';

export const apiWithSubtaskPage = api.injectEndpoints({
  endpoints: builder => ({
    getSubtaskById: builder.query<ISubtask, {taskId?: string}>({
      query: ({taskId}: {taskId: string}) => ({
        url: `/v1/subtasks/${taskId}`,
        method: 'get',
        params: {
          include:
            'user,author,subtask_comments,subtask_comments.user,subtask_comments.files,company,task',
          fields:
            'id,title,ticket,department_id,task_status_id,task_type_id,company.bitrix_id,deadline_at,done_at,created_at,is_has_files,' +
            [userFields, subtaskCommentsFields, companyFields, authorFields, parentTaskFields].join(
              ','
            )
        }
      }),
      transformResponse: (result: {data: ISubtaskResponse}): ISubtask | Promise<ISubtask> => {
        return formatKeys<ISubtaskResponse, ISubtask>(result.data);
      },

      // @ts-ignore
      providesTags: (_result, _error, arg) => {
        return [{type: 'SUBTASK', id: arg}];
      }
    }),

    updateSubtask: builder.mutation<void, {taskId: string} & ISubtaskUpdateRequest>({
      query: ({taskId, ...patch}) => ({
        url: `/v1/subtasks/${taskId}`,
        method: 'PUT',
        data: patch
      }),
      async onQueryStarted({taskId, ...patch}, {dispatch, queryFulfilled}) {
        if (withSocket) {
          return;
        }
        // Pessimistic Update
        try {
          const {data: updatedSubtask} = await queryFulfilled;

          const _patchResult = dispatch(
            apiWithSubtaskPage.util.updateQueryData('getSubtaskById', {taskId}, draft => {
              if (hasInvalidatedChanges(patch)) {
                // инвалидируем кеш
                console.debug('invalidate cache SUBTASK');
                dispatch(api.util.invalidateTags(['SUBTASK']));
                return;
              }

              const updatedFields = Object.keys(patch).reduce((acc, curr) => {
                return {
                  ...acc,
                  // @ts-ignore
                  [curr]: updatedSubtask[curr] || patch[curr]
                };
              }, {});
              Object.assign(draft, formatKeys(updatedFields));
            })
          );
        } catch (e) {
          console.error(e);
        }
      }
    })
  }),
  overrideExisting: true
});

export const {useGetSubtaskByIdQuery, useUpdateSubtaskMutation} = apiWithSubtaskPage;

export const getSubtaskByIdQueryState = apiWithSubtaskPage.endpoints.getSubtaskById.useQueryState;
