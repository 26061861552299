import React, {FC, Fragment} from 'react';
import {Stack} from '@mui/material';

import {ITaskUpdateDataRequest} from '@/shared/models/tasksDataModel';
import {ISubtaskUpdateRequest} from '@/shared/models/subtaskModel';
import {TaskEmployeeBox} from '@/components/TaskEditSection/components/TaskEmployeeBox';
import {TaskCompanyBox} from '@/components/TaskEditSection/components/TaskCompanyBox';
import {TaskTypeBox} from '@/components/TaskEditSection/components/TaskTypeBox';
import {TaskUserBox} from '@/components/TaskEditSection/components/TaskUserBox';
import {TaskUnitBox} from '@/components/TaskEditSection/components/TaskUnitBox';
import {TaskDepartmentBox} from '@/components/TaskEditSection/components/TaskDepartmentBox';
import {TaskStatusBox} from '@/components/TaskEditSection/components/TaskStatusBox';
import {TaskDelayBox} from '@/components/TaskEditSection/components/TaskDelayBox';

interface IProps {
  task?: any; //ITask | ISubtask | ISupportTask;
  isLoading?: boolean;
  isSuccess?: boolean;
  isDisabled?: boolean;
  controls: string[];
  onChangeData: (params: ITaskUpdateDataRequest | ISubtaskUpdateRequest) => void;
}

const TaskEditSection: FC<IProps> = ({
  task,
  controls,
  isLoading,
  isSuccess,
  isDisabled,
  onChangeData
}) => {
  const fieldsComponents = {
    company: <TaskCompanyBox task={task} isLoading={isLoading} isDisabled={isDisabled} />,
    employee: (
      <TaskEmployeeBox
        task={task}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onChangeData={onChangeData}
      />
    ),
    unit: (
      <TaskUnitBox
        task={task}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onChangeData={onChangeData}
      />
    ),
    department: (
      <TaskDepartmentBox
        task={task}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onChangeData={onChangeData}
      />
    ),
    user: (
      <TaskUserBox
        task={task}
        onChangeData={onChangeData}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isDisabled={isDisabled}
        fieldLabel="Исполнитель задачи"
      />
    ),
    taskType: (
      <TaskTypeBox
        task={task}
        onChangeData={onChangeData}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    ),
    taskStatus: (
      <TaskStatusBox
        isDisabled={isDisabled}
        task={task}
        isLoading={isLoading}
        onChangeData={onChangeData}
      />
    ),
    delay: <TaskDelayBox task={task} isDisabled={isDisabled} onChangeData={onChangeData} />
  };

  if (!task) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={8}
      useFlexGap
      flexWrap="wrap"
      alignItems="center"
      sx={{marginBottom: '0.6rem'}}
    >
      {controls.map((item, index) => {
        if (item in fieldsComponents) {
          // @ts-ignore
          return <Fragment key={`${item}-${index}`}>{fieldsComponents[item]}</Fragment>;
        }
        return null;
      })}
    </Stack>
  );
};

export default TaskEditSection;
