export const toCamelCase = (str: string) => {
  return str.replace(/_([a-z])/g, match => match[1].toUpperCase());
};

const specialCharts = {
  laquo: '«',
  raquo: '»',
  amp: '&',
  lt: '<',
  gt: '>',
  quot: '"',
  '#039': "'",
  nbsp: ' '
};

export const formatHTML = (str = '') => {
  if (!str) {
    return '';
  }

  return (
    str
      .replace('</p>', '</p>\n\n')
      .replace('<br />', '\n\n')
      // удалить HTML теги
      .replace(/(<([^>]+)>)/gi, '')
      // @ts-ignore
      .replace(/&([^;]+);/g, (m, c) => specialCharts[c])
      // удалить спец символы
      .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&lt;|&raquo;|&laquo;|&gt;/g, ' ')
  );
};

export const getParticipantsLabel = (count: number): string => {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return `${count} участников`;
  }

  if (lastDigit === 1) {
    return `${count} участник`;
  }

  if (lastDigit >= 2 && lastDigit <= 4) {
    return `${count} участника`;
  }

  return `${count} участников`;
};
