import {api} from '@/stores/api';
import {ICommonArgs, ICommonResponse} from '@/shared/models/commonModel';
import {ISubtaskResponse} from '@/shared/models/subtaskModel';
import {formatKeys} from '@/shared/utils/responseUtils';
import {ITask, ITaskResponse} from '@/shared/models/tasksDataModel';
import {ETaskStatuses} from '@/shared/constants/taskStatuses';

interface IFileArg {
  name: string;
  file: string;
}

interface ICreateTaskArg {
  title: string;
  company_id: number | string;
  user_id: number | string;
  task_type_id: number | string;
  department_id?: number | string;
  task_id?: number | string;
  subtask_comment: {
    content: string;
    attachments?: IFileArg[];
  };
}

interface IQueryArg extends ICommonArgs {
  companyId?: number;
}
const taskStatusId = ETaskStatuses.Closed;

export const apiWithCreateSubtask = api.injectEndpoints({
  endpoints: builder => ({
    // form
    createSubtask: builder.mutation<ISubtaskResponse, ICreateTaskArg>({
      query: (data: ICreateTaskArg) => ({
        url: '/v1/subtasks/',
        method: 'post',
        data
      }),
      // обновляем таблицу
      invalidatesTags: [{type: 'TASKS', id: 'LIST'}]
    }),
    // form create subtask
    getParentTasks: builder.query<ITask[], IQueryArg>({
      query: ({companyId, ...params}) => ({
        url: '/v1/tasks/',
        method: 'get',
        params: {
          include: '',
          fields: 'id,title,company_id',
          'filters[company_id]': companyId,
          'filters[task_status_id]': `!=|${taskStatusId}`,
          ...params
        }
      }),
      transformResponse: (result: ICommonResponse<ITaskResponse[]>): ITask[] => {
        return result.data.map(item => {
          return formatKeys<ITaskResponse, ITask>(item);
        });
      }
    })
  }),
  overrideExisting: true
});

export const {useCreateSubtaskMutation, useGetParentTasksQuery} = apiWithCreateSubtask;
