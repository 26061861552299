import pickBy from 'lodash/pickBy';

import {api} from '@/stores/api/index';
import {ICommonArgs, ICommonResponse} from '@/shared/models/commonModel';
import {formatKeys} from '@/shared/utils/responseUtils';
import {IUser, IUserResponse} from '@/shared/models';
import {formatUserName} from '@/shared/utils/fieldsUtils';

interface IQueryArg extends ICommonArgs {
  filters?: {
    company_id: number;
    is_has_mobile?: number;
    active?: number;
    bitrix_api_id?: number;
    customer_id?: string;
  };
}

export const apiWithEmployees = api.injectEndpoints({
  endpoints: builder => ({
    getEmployees: builder.query<IUser[] | undefined, IQueryArg>({
      query: params => ({
        url: '/v1/employees/',
        method: 'get',
        params: pickBy(params)
      }),
      transformResponse: (result: ICommonResponse<IUserResponse[]>): IUser[] => {
        return result.data.map(item => {
          const formatedData = formatKeys<IUserResponse, IUser>(item);
          return {
            ...formatedData,
            name: formatUserName(formatedData)
          };
        });
      },
      // @ts-ignore
      providesTags: result => {
        // @ts-ignore
        return result && result?.data
          ? [
              // @ts-ignore
              ...result.data.map(({id}) => ({type: 'EMPLOYEES', id})),
              {type: 'EMPLOYEES', id: 'LIST'}
            ]
          : [{type: 'EMPLOYEES', id: 'LIST'}];
      }
    })
  }),
  overrideExisting: true
});

export const {useGetEmployeesQuery} = apiWithEmployees;
