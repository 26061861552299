import React, {FC, memo} from 'react';
import {Backdrop, CircularProgress} from '@mui/material';

interface IProps {
  visible?: boolean;
}

const ModalLoader: FC<IProps> = ({visible = false}) => {
  return (
    <Backdrop
      sx={{color: theme => theme.palette.primary.main, zIndex: theme => theme.zIndex.modal + 2}}
      open={visible}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default memo(ModalLoader);
