import {api} from '@/stores/api';
import {ICommonArgs} from '@/shared/models/commonModel';
import {authorFields, companyFields, userFields} from '@/shared/utils/requestParamsUtils';

interface IGetQueryArg extends ICommonArgs {
  taskId: number;
}

export const apiWithSubtasks = api.injectEndpoints({
  endpoints: builder => ({
    // table
    getSubtasksByTaskId: builder.mutation<any, IGetQueryArg>({
      query: ({taskId, ...params}) => ({
        url: '/v1/subtasks/',
        method: 'get',
        params: {
          sort: 'active_subtasks',
          include: ['company,user,author'].join(','),
          fields: [
            'id,title,task_id,ticket,department_id,created_at,done_at,deadline_at,task_status_id,task_type_id,unit_id,is_has_files',
            companyFields,
            userFields,
            authorFields
          ]
            .filter(Boolean)
            .join(','),
          'filters[task_id]': taskId,
          ...params
        }
      })
    })
  }),
  overrideExisting: true
});

export const {useGetSubtasksByTaskIdMutation} = apiWithSubtasks;
