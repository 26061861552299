import React, {FC} from 'react';
import {useRouteError, isRouteErrorResponse} from 'react-router-dom';

import {GuestLayout} from '@/layouts/GuestLayout';

const ErrorPage: FC = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 401) {
      // ...
    } else if (error.status === 404) {
      // ...
    }

    return (
      <GuestLayout>
        <h1>Ошибка {error.status}</h1>
        <p>{error.statusText}</p>
        {error.data?.message && (
          <p>
            <i>{error.data.message}</i>
          </p>
        )}
      </GuestLayout>
    );
  } else if (error instanceof Error) {
    return (
      <GuestLayout>
        <h1>Непредвиденная ошибка</h1>
        <p>
          Что-то пошло не так. Попробуйте обновить страницу или вернуться позже. <br />
        </p>
        <p>
          <i>{error.message}</i>
        </p>
      </GuestLayout>
    );
  } else {
    return <></>;
  }
};

export default ErrorPage;
