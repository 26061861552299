import CONFIG from '@/shared/constants/config';

import fileStorageAPI from './fileStorageAPI';

/**
 * Авторизация S3
 * @returns {Promise}
 */
export const loginFileStorage = () => {
  return fileStorageAPI.post(
    'v3/auth/tokens',
    JSON.stringify({
      auth: {
        identity: {
          methods: ['password'],
          password: {
            user: {
              id: CONFIG.fileStorage.user,
              password: CONFIG.fileStorage.key
            }
          }
        }
      }
    }),
    {
      options: {
        skipAuth: true
      }
    }
  );
};

export const getFile = (filePath: string, config: any = {}) => {
  return fileStorageAPI.get(filePath, config);
};
