import React, {FC, useEffect, useState} from 'react';
import {Button, CircularProgress, Collapse, Stack, Typography} from '@mui/material';

import {formatUserName} from '@/shared/utils/fieldsUtils';
import {IChatCustomer, IChatUser} from '@/shared/models/chatModel.ts';
import {DividerHr, EllipsisTypography} from '@/shared/styles/chatStyles';

interface IProps {
  isLoadingChat?: boolean;
  isFetchingChat?: boolean;
  chatUsers?: IChatUser[];
  chatCustomers?: IChatCustomer[];
}

const ChatInfoParticipants: FC<IProps> = ({
  isLoadingChat,
  isFetchingChat,
  chatUsers,
  chatCustomers
}) => {
  const [isOpenParticipants, setIsOpenParticipants] = useState<boolean>(false);

  useEffect(() => {
    setIsOpenParticipants(!isFetchingChat && !isLoadingChat);
  }, [isFetchingChat, isLoadingChat]);

  return (
    <Stack padding={6}>
      <Button
        variant={'contained'}
        color={'secondary'}
        onClick={() => setIsOpenParticipants(prevState => !prevState && !isFetchingChat)}
      >
        <Typography variant="h2">Участники чата</Typography>
      </Button>
      {(isFetchingChat || isLoadingChat || !chatUsers || !chatCustomers) && (
        <Stack padding={6} alignItems={'center'}>
          <CircularProgress color="inherit" />
        </Stack>
      )}
      <Collapse
        in={isOpenParticipants && !isFetchingChat && !isLoadingChat}
        timeout="auto"
        unmountOnExit
      >
        {chatUsers && (
          <>
            <Stack direction="column" spacing={2} paddingTop={8}>
              <Typography variant="subtitle2" fontSize={16}>
                Сотрудники
              </Typography>
              {chatUsers.map(chatUser => (
                <>
                  <DividerHr />
                  <Stack direction={'column'}>
                    <EllipsisTypography variant="body1">
                      {formatUserName(chatUser.user)}
                    </EllipsisTypography>
                    <EllipsisTypography variant="body2" sx={{color: 'var(--mute-color)'}}>
                      {chatUser.user.email}
                    </EllipsisTypography>
                  </Stack>
                </>
              ))}
            </Stack>
          </>
        )}
        {chatCustomers && (
          <>
            <Stack direction="column" spacing={2} paddingTop={8}>
              <Typography variant="subtitle2" fontSize={16}>
                Клиенты
              </Typography>
              {chatCustomers.map(chatCustomer => (
                <>
                  <DividerHr />
                  <Stack direction={'column'}>
                    <EllipsisTypography variant="body1">
                      {formatUserName(chatCustomer.customer)}
                    </EllipsisTypography>
                    <EllipsisTypography variant="body2" sx={{color: 'var(--mute-color)'}}>
                      {chatCustomer.customer.email}
                    </EllipsisTypography>
                  </Stack>
                </>
              ))}
            </Stack>
          </>
        )}
      </Collapse>
    </Stack>
  );
};

export default ChatInfoParticipants;
