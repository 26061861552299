import React, {FC} from 'react';
import {Outlet, useLocation} from 'react-router';
import {Navigate} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {LinearProgress} from '@mui/material';

import {useAppSelector} from '@/stores/hooks';
import {authSelector} from '@/stores/AuthStore/AuthStateSelector';
import {RoutePaths} from '@/shared/constants/route';
import {useMetaInfo} from '@/shared/hooks/useMetaInfo';
import {getIsLinearLoading, getIsLoading} from '@/stores/AppStateStore/AppStateSelector';
import {ModalLoader} from '@/components/ModalLoader';

import {NavMenu} from './components/NavMenu/index';
import 'react-toastify/dist/ReactToastify.css';
import s from './ProtectedPageLayout.module.css';

interface IProps {}

const ProtectedPageLayout: FC<IProps> = () => {
  const isAuth = useAppSelector(authSelector);
  const isLoading = useAppSelector(getIsLoading);
  const isLinearLoading = useAppSelector(getIsLinearLoading);
  useMetaInfo({skip: !isAuth});

  const location = useLocation();

  if (!isAuth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={RoutePaths.LoginPage} state={{from: location}} replace />;
  }

  return (
    <div className={s.container}>
      <NavMenu />
      <div className="scene-wrapper">
        <div className="scene-linear-loader">{isLinearLoading ? <LinearProgress /> : ' '}</div>
        <Outlet />
      </div>
      <ModalLoader visible={isLoading} />
      <ToastContainer />
    </div>
  );
};

export default ProtectedPageLayout;
