import React, {FC, memo, useEffect} from 'react';
import cn from 'classnames';
import {createFilterOptions, FilterOptionsState} from '@mui/material';

import {ComboBox} from '@/components/UIKit';
import {ITask} from '@/shared/models/tasksDataModel';
import {IUser} from '@/shared/models';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {
  setUserError,
  taskDepartmentSelector,
  taskStatusSelector,
  taskUserSelector,
  updateUser
} from '@/stores/TaskEditStore';
import {OptionUser} from '@/components/UIKit/OptionUser';
import {useGetUsersQuery} from '@/stores/api/user';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {ISubtask} from '@/shared/models/subtaskModel';
import {ISupportTask} from '@/shared/models/supportTaskModel';
import {checkTaskUserMsg} from '@/shared/utils/taskUtils';
import {ETaskStatuses} from '@/shared/constants/taskStatuses';

interface IProps {
  task?: ITask | ISubtask | ISupportTask;
  fieldLabel?: string;
  isLoading?: boolean;
  isSuccess?: boolean;
  isDisabled?: boolean;
  onChangeData: (params: Record<string, number>) => void;
}

const TaskUserBox: FC<IProps> = ({task, fieldLabel = '', isLoading, isDisabled, onChangeData}) => {
  const dispatch = useAppDispatch();
  const editedUser = useAppSelector(taskUserSelector);
  const editedDepartment = useAppSelector(taskDepartmentSelector);
  const editedStatus = useAppSelector(taskStatusSelector);
  const [isValueChanged, setIsValueChanged] = React.useState(false);

  const isProgressStatus = editedStatus?.id === ETaskStatuses.Progress;

  const {users, isUsersLoading} = useGetUsersQuery(
    {
      filters: {
        has_departments: editedDepartment?.id,
        active: 1,
        is_vacation: 0
      }
    },
    {
      skip: !editedDepartment?.id,
      selectFromResult: ({data, isLoading}) => ({
        users: data ? data.filter(o => o.id !== editedUser?.id) : [],
        isUsersLoading: isLoading
      })
    }
  );

  useEffect(() => {
    dispatch(setUserError(checkTaskUserMsg(isProgressStatus && !editedUser)));
  }, [dispatch, isProgressStatus, editedUser]);

  const handleChangeUser = (value?: IUser) => {
    dispatch(updateUser(value));
    if (value?.id) {
      value?.id !== task?.userId ? setIsValueChanged(true) : setIsValueChanged(false);

      dispatch(setUserError(null));

      onChangeData({
        user_id: value.id
      });
    }
  };

  useEffect(() => {
    if (task?.userId && task?.user) {
      dispatch(
        updateUser({
          ...task.user,
          name: formatUserName(task.user)
        })
      );
      return;
    }

    dispatch(updateUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, task?.userId]);

  const filter = createFilterOptions<IUser>({
    trim: true,
    stringify: option => option.name + option.email
  });

  return (
    <ComboBox<IUser>
      options={users || []}
      variantInput="standard"
      fieldLabel={fieldLabel}
      isLoading={isLoading || isUsersLoading}
      sx={{
        minWidth: '400px'
      }}
      disableClearable
      value={editedUser || null}
      onChange={(_, newValue) => {
        // @ts-ignore
        handleChangeUser(newValue);
      }}
      placeholder="Выберите исполнителя"
      renderOption={(props, option: IUser) => {
        return <OptionUser {...props} key={option.id} option={option} />;
      }}
      filterOptions={(options: IUser[], params: FilterOptionsState<IUser>) => {
        return filter(options, params);
      }}
      disabled={isDisabled}
      inputClassName={cn({'combo-box-changed': isValueChanged})}
    />
  );
};

export default memo(TaskUserBox);
