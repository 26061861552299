import {api} from '@/stores/api';
import {ICommonArgs, ICommonResponse} from '@/shared/models/commonModel';
import {formatKeys} from '@/shared/utils/responseUtils';
import {ISubtask, ISubtaskResponse} from '@/shared/models/subtaskModel.ts';

interface IGetQueryArg extends ICommonArgs {
  taskId: number;
}

export const apiWithTaskPageSubtasks = api.injectEndpoints({
  endpoints: builder => ({
    // get subtasks by task id on Task page
    getSubtasksById: builder.query<any, IGetQueryArg>({
      query: ({taskId, ...params}) => ({
        url: '/v1/subtasks/',
        method: 'get',
        params: {
          fields: 'id',
          'filters[task_id]': taskId,
          ...params
        }
      }),
      transformResponse: (result: ICommonResponse<ISubtaskResponse[]>) => {
        return result.data.map((item: any) => {
          return formatKeys<ISubtaskResponse, ISubtask>(item);
        });
      }
    })
  }),
  overrideExisting: true
});

export const {useGetSubtasksByIdQuery} = apiWithTaskPageSubtasks;
