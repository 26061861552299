import React, {FC} from 'react';

import {SendAllConfirmContent} from '@/scenes/TaskPage/components/SendAllConfirmContent';
import {Chat} from '@/components/Chat';
import {IFile} from '@/shared/models/fileModel';
import {useCreateTaskCommentMutation} from '@/stores/api/task-page/task-comment';
import {IMessage} from '@/shared/models/messageModel';
import {downloadFile} from '@/shared/utils/downloadFiles';
import {useAppSelector} from '@/stores/hooks';
import {fileStorageTokenSelector} from '@/stores/FileStorageStore';
import {IUser} from '@/shared/models';

interface IProps {
  taskId: string;
  status?: number;
  isLoading: boolean;
  isDisabled: boolean;
  isDisabledSend?: boolean;
  isDisabledSendAll?: boolean;
  isDisabledInput?: boolean;
  isDisabledInputFile?: boolean;
  isDisplaySystemComments: boolean;
  isSendAll?: boolean;
  taskComments?: IMessage[];
  recipient?: IUser;
  source?: number;
}

const TaskChat: FC<IProps> = ({
  taskId,
  status,
  taskComments = [],
  isLoading,
  isDisabled,
  isDisabledSend,
  isDisabledSendAll,
  isDisabledInput,
  isDisabledInputFile,
  isDisplaySystemComments,
  isSendAll,
  recipient,
  source
}) => {
  const [submitComment, {isLoading: isLoadingSubmit}] = useCreateTaskCommentMutation();
  const s3Token = useAppSelector(fileStorageTokenSelector);

  const handleMessageSend = async (message: string, files: IFile[] = [], toAll?: boolean) => {
    if (!taskId) {
      console.warn('Задача не выбрана');
      return;
    }

    if (toAll) {
      await submitComment({
        taskId,
        content: message,
        attachments: files,
        isAll: true
      });
      return;
    }

    await submitComment({
      taskId,
      content: message,
      attachments: files
    });
  };

  const handleGetFile = async (file: IFile) => {
    downloadFile(file, s3Token);
  };

  return (
    <Chat
      messages={taskComments}
      status={status}
      source={source}
      isLoadingMessages={isLoading}
      isLoadingSubmit={isLoadingSubmit}
      isDisabled={isDisabled}
      onMessageSend={handleMessageSend}
      onGetFile={handleGetFile}
      hasAllButton
      isTaskChat={true}
      isDisabledSend={isDisabledSend}
      isDisabledSendAll={isDisabledSendAll}
      isDisabledInput={isDisabledInput}
      isDisabledInputFile={isDisabledInputFile}
      isDisplaySystemComments={isDisplaySystemComments}
      isSendAll={isSendAll}
      confirmContent={() => <SendAllConfirmContent recipient={recipient} />}
    />
  );
};

export default TaskChat;
