import React, {FC, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Checkbox, createFilterOptions} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import {ComboBox} from '@/components/UIKit';
import {IFilterOption, IOption} from '@/shared/models/tasksFilterModel';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const selectAllOption = {name: 'Все', id: 'select-all', isActive: true};

interface IProps extends IFilterOption<IOption> {
  onChange: (value?: IOption[]) => void;
}

const TasksFilterMultiItems: FC<IProps> = ({fieldName, selectAll, options, onChange, ...rest}) => {
  const [urlParams] = useSearchParams();
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);

  const idsFromUrl = fieldName && urlParams.get(fieldName);

  useEffect(() => {
    // init many
    if (selectAll && options) {
      if (idsFromUrl) {
        const foundOptions = options.filter(
          val => val.id && idsFromUrl.split(',').find(o => o == val.id)
        );
        foundOptions.length > 0 && setSelectedOptions(foundOptions);
        return;
      }

      setSelectedOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, idsFromUrl]);

  const allSelected = options.length === selectedOptions.length;

  // const getOptionSelected = (option: IOption, anotherOption: IOption) =>
  //   option?.id === anotherOption?.id;

  const getOptionLabel = (option: IOption) => `${option?.name}`;

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedOptions(options);
    } else {
      handleClearOptions();
    }
  };

  const handleClearOptions = () => {
    setSelectedOptions([]);
  };

  const handleToggleOption = (selectedOptions: IOption[]) => {
    setSelectedOptions(selectedOptions);
  };

  const handleChange = (
    _event: React.SyntheticEvent,
    selectedOptions: IOption | IOption[],
    reason: string
  ) => {
    if (Array.isArray(selectedOptions)) {
      if (reason === 'selectOption' || reason === 'removeOption') {
        if (selectedOptions.find(option => option.id === selectAllOption.id)) {
          handleToggleSelectAll();

          // const result = options.filter(el => el.id !== selectAllOption.id);
          return onChange(undefined);
        } else {
          handleToggleOption(selectedOptions);
          return onChange(selectedOptions);
        }
      } else if (reason === 'clear') {
        handleClearOptions && handleClearOptions();
      }
    }
  };

  /**
   * RENDER
   */
  const renderOption = (props: object, option: IOption, {selected}: {selected: boolean}) => {
    if (!option) {
      return null;
    }

    const selectAllProps =
      option.id === selectAllOption.id // To control the state of 'select-all' checkbox
        ? {checked: allSelected}
        : {};

    return (
      <li {...props} key={option.id}>
        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} {...selectAllProps} />
        {getOptionLabel(option)}
      </li>
    );
  };

  const filter = createFilterOptions<IOption>({
    trim: true
  });

  return (
    <ComboBox<IOption>
      {...rest}
      key={fieldName}
      options={options}
      multiple
      variantInput="standard"
      // @ts-ignore
      onChange={handleChange}
      disableCloseOnSelect
      sx={{
        marginRight: '2.4rem',
        marginBottom: '1.2rem',
        minWidth: '180px',
        maxWidth: '260px',
        position: 'relative',
        zIndex: 1
      }}
      disableClearable
      // @ts-ignore
      value={selectedOptions}
      renderOption={renderOption}
      renderTags={(values: IOption[], _getTagProps) => {
        if (values.length === options.length && selectAll) {
          return selectAllOption.name;
        }

        // return values.map((value, index: number) => {
        //   if (value && typeof value === 'object' && 'name' in value) {
        //     const {name} = value;
        //     return `${value.id}`;
        //     // return name + (index < values.length - 1 ? ', ' : '');
        //   }
        // });

        return `${values.length} выбрано`;
      }}
      // @ts-ignore
      filterOptions={(options, params) => {
        // @ts-ignore
        const filtered = filter(options, params);

        if (selectAll) {
          return [selectAllOption, ...filtered];
        }
        return filtered;
      }}
    />
  );
};

export default TasksFilterMultiItems;
