import React, {FC, memo} from 'react';
import cn from 'classnames';
import {createFilterOptions, FilterOptionsState} from '@mui/material';

import {ComboBox} from '@/components/UIKit';
import {IUser} from '@/shared/models';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {
  setEmployeeError,
  taskCompanySelector,
  taskEmployeeSelector,
  updateEmployee
} from '@/stores/TaskEditStore';
import {useGetEmployeesQuery} from '@/stores/api/employees';
import {OptionUser} from '@/components/UIKit/OptionUser';
import {ITask} from '@/shared/models/tasksDataModel';

interface IProps {
  task?: ITask;
  isLoading?: boolean;
  isDisabled?: boolean;
  onChangeData: (params: Record<string, number | undefined>) => void;
}

const TaskEmployeeBox: FC<IProps> = ({task, isLoading, isDisabled, onChangeData}) => {
  const dispatch = useAppDispatch();
  const editedEmployee = useAppSelector(taskEmployeeSelector);
  const editedCompany = useAppSelector(taskCompanySelector);
  const [isValueChanged, setIsValueChanged] = React.useState(false);

  const {data: employeeList, isLoading: isLoadingList} = useGetEmployeesQuery(
    {
      filters: {
        company_id: editedCompany?.id,
        active: 1,
        bitrix_api_id: 1
      }
    },
    {
      skip: !editedCompany?.id
    }
  );

  const handleChangeEmployee = (value?: IUser) => {
    if (editedCompany?.id && value?.id) {
      dispatch(updateEmployee(value));
      dispatch(setEmployeeError(null));

      const params: Record<string, number | undefined> = {
        company_id: editedCompany.id,
        employee_id: value.id
      };

      value?.id !== task?.employeeId ? setIsValueChanged(true) : setIsValueChanged(false);

      onChangeData(params);
    }
  };

  const filter = createFilterOptions<IUser>({
    trim: true,
    stringify: option => option.name + option.email
  });

  return (
    <ComboBox<IUser>
      options={employeeList || []}
      variantInput="standard"
      fieldLabel="Сотрудник"
      isLoading={isLoading || isLoadingList}
      sx={{
        minWidth: '400px'
      }}
      disableClearable
      value={
        editedEmployee
          ? {
              ...editedEmployee,
              name: editedEmployee.isSpam ? `${editedEmployee.name} (СПАМ)` : editedEmployee.name
            }
          : null
      }
      onChange={(_, newValue) => {
        // @ts-ignore
        handleChangeEmployee(newValue);
      }}
      placeholder="Выберите сотрудника"
      renderOption={(props, option: IUser) => {
        return <OptionUser {...props} key={option.id} option={option} />;
      }}
      filterOptions={(options: IUser[], params: FilterOptionsState<IUser>) => {
        return filter(options, params);
      }}
      disabled={isDisabled}
      inputClassName={cn({'combo-box-changed': isValueChanged})}
    />
  );
};

export default memo(TaskEmployeeBox);
