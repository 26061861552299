import React, {FC, useMemo} from 'react';

import {useGetChatByIdQuery} from '@/stores/api/chats';
import useLinearLoading from '@/shared/hooks/useLinearLoading.ts';
import {ChatInfo} from '@/shared/styles/chatStyles';
import {ChatInfoParticipants} from '@/scenes/ChatsPage/components/ChatInfoParticipants';
import ChatInfoCompanies from '@/scenes/ChatsPage/components/ChatInfoCompanies/ChatInfoCompanies';

interface IProps {
  chatId?: string;
}

const ChatInfoSection: FC<IProps> = ({chatId}) => {
  const chatQuery = useMemo(
    () => ({
      chatId: chatId,
      include: 'chat_users,chat_users.user,chat_customers,chat_customers.customer,chat_group'
    }),
    [chatId]
  );

  const {
    data: chatData,
    isLoading: isLoadingChat,
    isFetching: isFetchingChat
  } = useGetChatByIdQuery(chatQuery, {
    skip: !chatId || !chatQuery,
    refetchOnMountOrArgChange: true
  });

  useLinearLoading(isFetchingChat || isLoadingChat);

  return (
    <ChatInfo>
      <ChatInfoParticipants
        isLoadingChat={isLoadingChat}
        isFetchingChat={isFetchingChat}
        chatUsers={chatData?.chatUsers}
        chatCustomers={chatData?.chatCustomers}
      />
      <ChatInfoCompanies chatData={chatData} />
    </ChatInfo>
  );
};

export default ChatInfoSection;
