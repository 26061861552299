import React, {FC, useState} from 'react';
import Button from '@mui/lab/LoadingButton';
import {addDays, format} from 'date-fns';
import {Typography, useTheme} from '@mui/material';

import {ReactComponent as BoxIcon} from '@/assets/box.svg';
import {
  isIndividualCompanySelector,
  setCompanyError,
  setEmployeeError,
  setTypeError,
  taskEmployeeSelector
} from '@/stores/TaskSettingsStore';
import {useAppDispatch, useAppSelector} from '@/stores/hooks.ts';
import {DateRangePicker} from '@/components/UIKit/DateRangePicker';
import {DelayTaskConfirmation} from '@/modals/confirmation/DelayTaskConfirmation';

interface IProps {
  isDisabled?: boolean;
  selectedDateDelay?: Date | null;
  setSelectedDateDelay?: React.Dispatch<React.SetStateAction<Date | null>>;
  reasonDelay?: string;
  setReasonDelay?: any;
  errorTypeMsg?: string | null;
  isLoading?: boolean;
  onChangeStatus: (status: number) => void;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
const DelayButton: FC<IProps> = ({
  isDisabled,
  selectedDateDelay,
  setSelectedDateDelay,
  reasonDelay,
  setReasonDelay,
  onChangeStatus,
  isLoading,
  errorTypeMsg
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isDelayConfirmationOpen, setIsDelayConfirmationOpen] = useState(false);
  const editedEmployee = useAppSelector(taskEmployeeSelector);

  const isIndividualCompany = useAppSelector(isIndividualCompanySelector);

  const handleCalendarOpen = () => {
    if (errorTypeMsg) {
      dispatch(setTypeError(errorTypeMsg));
    }
    if (isIndividualCompany) {
      dispatch(setCompanyError('Выберите клиента'));
    }
    if (!editedEmployee) {
      dispatch(setEmployeeError('Выберите сотрудника'));
    }

    setIsCalendarOpen(!isIndividualCompany && editedEmployee && !errorTypeMsg);
  };

  const handleDateDelayChange = (val: Value) => {
    const newDateDelay = Array.isArray(val) ? val[0] : val;
    if (setSelectedDateDelay) {
      setSelectedDateDelay(newDateDelay);
    }
    setIsDelayConfirmationOpen(true);
  };

  return (
    <>
      <div className="task-header-button">
        <Button
          type="button"
          variant="contained"
          color="secondary"
          disabled={isDisabled}
          loading={isLoading}
          loadingIndicator=""
          onClick={handleCalendarOpen}
        >
          <BoxIcon />
          <Typography
            sx={{
              [theme.breakpoints.down('md')]: {
                display: 'none'
              },
              paddingRight: 10,
              paddingLeft: 10
            }}
          >
            Отложить
          </Typography>
        </Button>

        {isCalendarOpen && (
          <DateRangePicker
            placeholder="Выберите дату"
            start={undefined}
            end={selectedDateDelay ? format(selectedDateDelay, 'yyyy-MM-dd') : undefined}
            isOpenStraightaway
            singleDate
            isDelayTask
            setIsCalendarOpen={setIsCalendarOpen}
            minDate={addDays(new Date(), 1)}
            handleDateDelayChange={handleDateDelayChange}
            hideButton
          />
        )}
      </div>

      {isDelayConfirmationOpen && (
        <DelayTaskConfirmation
          open={isDelayConfirmationOpen}
          onChangeStatus={onChangeStatus}
          delayData={selectedDateDelay}
          setIsDelayConfirmationOpen={setIsDelayConfirmationOpen}
          reasonDelay={reasonDelay}
          setReasonDelay={setReasonDelay}
          setIsCalendarOpen={setIsCalendarOpen}
        />
      )}
    </>
  );
};

export default DelayButton;
