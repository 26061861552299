import React, {FC, memo} from 'react';
import {Divider, Typography} from '@mui/material';

import {IFile} from '@/shared/models/fileModel';

import ChatFileDelete from '../ChatFile/ChatFileDelete';

import s from './ChatFileList.module.css';

interface IProps {
  files: IFile[];
  onDeleteFile: (id: number) => void;
}

const ChatFileList: FC<IProps> = ({files = [], onDeleteFile}) => {
  if (!files.length) {
    return null;
  }

  return (
    <>
      <Divider light />
      <Typography variant="h3" className={s.filesTitle}>
        Прикрепленные файлы:
      </Typography>
      <div className={s.filesList}>
        {files.map((file, index) => (
          <ChatFileDelete
            className={s.filesListItem}
            key={index}
            file={file}
            onDeleteFile={() => onDeleteFile(index)}
          />
        ))}
      </div>
    </>
  );
};

export default memo(ChatFileList);
