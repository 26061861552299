import {useCallback, useEffect, useRef} from 'react';
import useWebSocket from 'react-use-websocket';
import {Draft} from '@reduxjs/toolkit';

import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {authTokenSelector} from '@/stores/AuthStore/AuthStateSelector';
import {ESocketEvent} from '@/shared/models/socketTypes';
import {api} from '@/stores/api';
import {IMessage} from '@/shared/models/messageModel';
import {formatKeys} from '@/shared/utils/responseUtils';
import usePrevious from '@/shared/hooks/usePrevious';
import {withSocket} from '@/shared/constants/appConstants';
import {ISubtask, ISubtaskResponse} from '@/shared/models/subtaskModel';

const SOCKET_URL = import.meta.env.APP_SOCKET_URL || '';

export const useSubtaskStreaming = ({taskId}: {taskId?: string}) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(authTokenSelector);
  const WS_URL = SOCKET_URL + `/subtasks/${taskId}`;
  const didUnmount = useRef(false);
  const prevToken = usePrevious(accessToken);

  const {lastMessage} = useWebSocket(
    WS_URL,
    {
      onOpen: () => console.debug('opened'),
      onClose: () => console.debug('closed'),
      share: false,
      shouldReconnect: () => {
        /*
      useWebSocket will handle unmounting for you, but this is an example of a
      case in which you would not want it to automatically reconnect
    */
        if (prevToken && prevToken !== accessToken) {
          console.log('reconnect', {prevToken, accessToken});
          return true;
        }
        return didUnmount.current === false;
      },
      queryParams: {
        token: accessToken
      }
    },
    withSocket
  );

  const handleTaskComment = useCallback(
    (message: IMessage) => {
      dispatch(
        api.util.updateQueryData(
          // @ts-ignore
          'getSubtaskById',
          {taskId},
          (draft: Draft<ISubtask>) => {
            const comment: IMessage = formatKeys(message);
            console.log('new comment', comment);
            if (draft.subtaskComments?.length) {
              if (draft.subtaskComments.findIndex(o => o.id === comment.id) === -1) {
                draft.subtaskComments.push(comment);
              }
            } else {
              draft.subtaskComments = [comment];
            }
          }
        )
      );
    },
    [dispatch, taskId]
  );

  const handleUpdateTask = useCallback(
    (message: IMessage) => {
      const patch = formatKeys<Partial<ISubtaskResponse>, Partial<ISubtask>>(message);

      dispatch(
        // @ts-ignore
        api.util.updateQueryData('getSubtaskById', {taskId}, (draft: any = {}) => {
          return {
            ...draft,
            ...patch,
            subtaskComments: [...(draft.subtaskComments || []), ...(patch.subtaskComments || [])]
          };
        })
      );
    },
    [dispatch, taskId]
  );

  // Run when a new WebSocket message is received (lastMessage)
  useEffect(() => {
    if (lastMessage !== null) {
      const commentData = JSON.parse(lastMessage.data);
      console.debug('Got a new message: ', commentData);

      switch (commentData.type) {
        case ESocketEvent.CreateSubtaskComment:
          handleTaskComment(commentData.data);
          break;

        case ESocketEvent.UpdateSubtask:
          handleUpdateTask(commentData.data);
          break;
      }
    }
  }, [handleTaskComment, handleUpdateTask, lastMessage]);

  return null;
};
