import {useDispatch} from 'react-redux';
import {useCallback, useEffect} from 'react';
import {setIsLinearLoading} from 'src/stores/AppStateStore';

export default (isLoading?: boolean) => {
  const dispatch = useDispatch();

  const handleLoading = useCallback(
    (loading?: boolean) => {
      dispatch(setIsLinearLoading(!!loading));
    },
    [dispatch]
  );

  useEffect(() => {
    handleLoading(isLoading);

    return () => {
      handleLoading(false);
    };
  }, [isLoading, handleLoading]);

  return {showModalLoading: handleLoading};
};
