import React, {FC, useCallback, useState} from 'react';
import {isEqual} from 'lodash';
import {LoadingButton} from '@mui/lab';
import {useSelector} from 'react-redux';
import {Backdrop, Box, Button, Fade, Grid, Modal, Typography} from '@mui/material';

import {ITask, ITaskUpdateDataRequest} from '@/shared/models/tasksDataModel';
import {useUpdateTaskMutation} from '@/stores/api/task-page/task-page';
import {TaskEditSection} from '@/components/TaskEditSection';
import {toCamelCase} from '@/shared/utils/stringUtils';
import {ISupportTask} from '@/shared/models/supportTaskModel';
import {ISubtask} from '@/shared/models/subtaskModel';
import {taskEditErrorsSelector} from '@/stores/TaskEditStore';

interface IProps {
  open: boolean;
  task?: ITask | ISubtask | ISupportTask;
  taskId?: string;
  taskTicket?: number;
  setIsEditTaskOpen: (isOpen: boolean) => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 480,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const EditTask: FC<IProps> = ({open, task, taskId, taskTicket, setIsEditTaskOpen}) => {
  const [submitUpdateTask, {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess}] =
    useUpdateTaskMutation();

  const disabled = false;
  const [editedParams, setEditedParams] = useState<ITaskUpdateDataRequest>({});
  const editTaskErrorMessages = useSelector(taskEditErrorsSelector);
  const isDisabledSave = editTaskErrorMessages?.length > 0;

  const handleChangeParams = useCallback(
    (params: ITaskUpdateDataRequest) => {
      setEditedParams(prev => {
        const updatedParams = {...prev};

        Object.keys(params).forEach(key => {
          const camelCaseKey = toCamelCase(key);
          // @ts-ignore
          if (task && !isEqual(task[camelCaseKey], params[key])) {
            // @ts-ignore
            updatedParams[key] = params[key];
          } else {
            // @ts-ignore
            delete updatedParams[key];
          }

          if (
            (key === 'task_comment' || key === 'delay_task_comment') &&
            params[key]?.content === ''
          ) {
            // @ts-ignore
            delete updatedParams[key];
          }
        });

        return updatedParams;
      });
    },
    [task]
  );

  const handleSave = useCallback(async () => {
    if (!taskId) {
      console.warn('taskId не найден');
      return;
    }

    const params = {
      taskId,
      data: editedParams,
      meta: {
        is_check_can_edit: false
      }
    };

    const isEmpty = Object.keys(params).filter(key => key !== 'taskId').length === 0;
    if (!isEmpty) {
      await submitUpdateTask(params);
    }

    setIsEditTaskOpen(false);
  }, [editedParams, submitUpdateTask, taskId, setIsEditTaskOpen]);

  return (
    <Modal
      open={open}
      aria-labelledby="Редактирование задачи"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <Typography sx={{marginBottom: 15}} variant="h2">
          Редактирование задачи № <span className="link">{taskTicket}</span>
        </Typography>
        <TaskEditSection
          task={task}
          controls={[
            'company',
            'employee',
            'unit',
            'department',
            'user',
            'taskType',
            'taskStatus',
            'delay'
          ]}
          onChangeData={handleChangeParams}
          isLoading={isUpdateLoading}
          isSuccess={isUpdateSuccess}
          isDisabled={disabled}
        />
        <Fade in={!!editTaskErrorMessages}>
          <div style={{textAlign: 'center'}}>
            {editTaskErrorMessages &&
              editTaskErrorMessages.map((msg, index) => (
                <Typography key={index} color="error" fontSize={15} marginTop={5}>
                  {msg}
                </Typography>
              ))}
          </div>
        </Fade>
        <Grid sx={{paddingTop: 15}} container justifyContent="space-between">
          <Grid item>
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={() => {
                setIsEditTaskOpen(false);
              }}
              sx={{minWidth: 140}}
              disabled={disabled}
            >
              Отмена
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSave}
              loading={isUpdateLoading}
              loadingIndicator="Сохранение…"
              sx={{minWidth: 140}}
              disabled={isDisabledSave}
            >
              Сохранить
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditTask;
