import React, {FC, useMemo} from 'react';
import {Chip} from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

import {deadlineColor, deadlineTime} from '@/shared/utils/dateUtils.ts';
import {matchDeadlineTheme} from '@/components/DeadlineChip/DeadlineChip.tsx';

interface IProps {
  deadlineAt?: string | null;
  createdAt?: string;
  doneAt?: string;
}

const BitrixDeadlineChip: FC<IProps> = ({deadlineAt, createdAt, doneAt, ...rest}) => {
  const deadlineDelta = deadlineAt ? deadlineTime(deadlineAt, doneAt) : null;

  const bgColor = useMemo(() => {
    if (deadlineAt && createdAt) {
      return deadlineColor(deadlineAt, createdAt, doneAt);
    }
    return null;
  }, [createdAt, deadlineAt, doneAt]);

  const textColor: any = useMemo(() => {
    if (bgColor) {
      return matchDeadlineTheme[bgColor];
    }
    return 'black';
  }, [bgColor]);

  if (!deadlineDelta) {
    return null;
  }

  return (
    <Chip
      avatar={<AccessAlarmIcon sx={{fill: textColor}} />}
      label={`Крайний срок клиента: ${deadlineDelta}`}
      sx={{
        color: textColor,
        backgroundColor: bgColor || 'none',
        height: 'auto',
        borderRadius: 1,
        '& .MuiChip-label': {
          display: 'block',
          whiteSpace: 'normal',
          paddingTop: '9px',
          paddingBottom: '9px'
        }
      }}
      {...rest}
    />
  );
};

export default BitrixDeadlineChip;
