import {api} from '@/stores/api';
import {ITaskModel, ITaskResponse, ITaskUpdateRequest} from '@/shared/models/tasksDataModel';
import {formatKeys, hasInvalidatedChanges} from '@/shared/utils/responseUtils';
import {
  companyFields,
  employeeFields,
  fromEmployeeFields,
  taskCommentsFields,
  toCopyEmployeeIds,
  userFields
} from '@/shared/utils/requestParamsUtils';
import {ICommonResponse, IPagination} from '@/shared/models/commonModel.ts';
import {withSocket} from '@/shared/constants/appConstants.ts';

const includeFields =
  'user,employee,task_comments,task_comments.employee,task_comments.user,task_comments.files,company,from_employee,to_copy_employee_ids,to_copy_employees,delay_task_comment';

export const apiWithTaskPage = api.injectEndpoints({
  endpoints: builder => ({
    getTaskById: builder.query<IPagination<ITaskModel>, {taskId?: string}>({
      query: ({taskId}: {taskId: string}) => ({
        url: `/v1/tasks/${taskId}`,
        method: 'get',
        params: {
          include: includeFields,
          fields:
            'id,title,ticket,department_id,task_source_id,task_status_id,task_type_id,company.bitrix_id,direction,deadline_at,done_at,created_at,is_has_files,unit_id,is_has_subtasks,' +
            [
              userFields,
              taskCommentsFields,
              employeeFields,
              companyFields,
              fromEmployeeFields,
              toCopyEmployeeIds,
              'delay_at',
              'bitrix_id',
              'bitrix_deal_id',
              'bitrix_deadline_at'
            ].join(',')
        }
      }),

      transformResponse: (
        result: ICommonResponse<ITaskResponse>
      ): IPagination<ITaskModel> | Promise<IPagination<ITaskModel>> => {
        return {
          data: formatKeys<ITaskResponse, ITaskModel>(result.data),
          meta: formatKeys(result.meta)
        };
      },

      // @ts-ignore
      providesTags: (_result, _error, arg) => {
        return [{type: 'TASK', id: arg}];
      }
    }),

    updateTask: builder.mutation<void, {taskId: string} & ITaskUpdateRequest>({
      query: ({taskId, ...patch}) => ({
        url: `/v1/tasks/${taskId}`,
        method: 'PUT',
        data: patch
      }),

      async onQueryStarted({taskId, ...patch}, {dispatch, queryFulfilled}) {
        if (withSocket) {
          return;
        }
        // Pessimistic Update
        try {
          const {data: updatedTask} = await queryFulfilled;

          const _patchResult = dispatch(
            apiWithTaskPage.util.updateQueryData('getTaskById', {taskId}, draft => {
              if (hasInvalidatedChanges(patch.data)) {
                // инвалидируем кеш
                console.debug('[API] invalidate cache TASK');
                dispatch(api.util.invalidateTags(['TASK']));
                return;
              }

              // const newData = updatedTask.data.filter(val => val !== null);
              const updatedFields = Object.keys(patch.data).reduce((acc, curr) => {
                return {
                  ...acc,
                  // @ts-ignore
                  [curr]: updatedTask[curr] || patch.data[curr]
                };
              }, {});

              return {
                ...draft,
                data: {
                  ...draft.data,
                  ...formatKeys(updatedFields)
                }
              };
            })
          );
        } catch (e) {
          console.error(e);
        }
      }
    })
  }),
  overrideExisting: true
});

export const {useGetTaskByIdQuery, useUpdateTaskMutation} = apiWithTaskPage;
