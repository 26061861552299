import {IDepartment, IUnit} from '@/shared/models/dictionaryModel';

export const prepareDepartment = (department: IDepartment, unitsIds: Record<string, IUnit>) => {
  const unitId = department?.unitDepartment?.unitId;

  if (!unitId || !Object.keys(unitsIds).length) {
    return department;
  }

  const isCrossUnit = department?.isCrossUnit;

  if (isCrossUnit) {
    return department;
  }

  const unitName = unitsIds[unitId]?.name || unitId;

  return {
    ...department,
    name: `${department.name} (${unitName})`
  };
};
