import React, {FC, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {CircularProgress} from '@mui/material';

import {useRateTaskMutation} from '@/stores/api/task-page/task-rate';
import useLoading from '@/shared/hooks/useLoading';
import NegativeFeedback from '@/scenes/FeedbackPage/components/NegativeFeedback';
import PositiveFeedback from '@/scenes/FeedbackPage/components/PositiveFeedback';

import s from './FeedbackPage.module.css';

const FeedbackPage: FC = () => {
  const {hashId} = useParams();
  const [urlParams] = useSearchParams();
  const [ticket, setTicket] = useState<number | undefined>();
  const [initRating, setInitRating] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const taskId = useMemo(() => {
    if (!hashId) {
      return;
    }

    try {
      return hashId && window.atob(hashId);
    } catch (_err) {
      return hashId;
    }
  }, [hashId]);

  const rate = urlParams.get('rate');

  const [submitRate, {isLoading: isLoadingRate, isError}] = useRateTaskMutation();

  useLoading(isLoadingRate);

  // если сделать запрос /api/v1/tasks/77/rate с пустыми данными,
  // придёт информация о задаче
  const rateTask = async (rateParam: string | undefined) => {
    // @ts-ignore
    const {data, error} = await submitRate({
      taskId,
      rate: rateParam ? Number(rateParam) : undefined
    });

    if (error) {
      return;
    }

    // ппроверяем при загрузке, когда она не оценена
    if (!rateParam && data?.rate) {
      setInitRating(data?.rate);
    }
    if (data?.ticket) {
      setTicket(data.ticket);
    }
  };

  useEffect(() => {
    if (taskId) {
      // сначала ппроверяем есть ли оценка
      if (rate === '2' || rate === '1') {
        setIsLoading(true);
        rateTask(undefined);
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rate]);

  if (isLoading) {
    return <CircularProgress color="inherit" />;
  }

  if (isError && !ticket) {
    return (
      <div className={s.textWrapper}>
        <p className={s.text}>404</p>
      </div>
    );
  }

  if (initRating) {
    const rateText = initRating === 2 ? 'положительно' : 'отрицательно';

    return (
      <p className={s.text}>
        Вы уже оценили задачу №{ticket} {rateText}.<br />
        <br />К сожалению, заново оценить задачу нельзя.
      </p>
    );
  }

  // Из за тонкости архитектуры теперь 1 - плохая оценка, 2 - хорошая оценка
  if (rate === '2' && ticket && taskId) {
    return <PositiveFeedback taskId={taskId} ticket={ticket} onSubmit={submitRate} />;
  }

  if (rate === '1' && ticket && taskId) {
    return <NegativeFeedback taskId={taskId} ticket={ticket} />;
  }

  return (
    <div className={s.textWrapper}>
      <p className={s.text}>404</p>
    </div>
  );
};

export default FeedbackPage;
