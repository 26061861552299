import {configureStore, Middleware} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistCombineReducers,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist';
import {setupListeners} from '@reduxjs/toolkit/query';

import {api} from '@/stores/api';
import {authStateReducer} from '@/stores/AuthStore';
import {tasksFilterStateReducer} from '@/stores/TasksFilterStore';
import {fileStorageReducer} from '@/stores/FileStorageStore';
import {tasksTableStateReducer} from '@/stores/TasksTableStore';
import {taskSettingsStateReducer} from '@/stores/TaskSettingsStore';
import {appStateReducer} from '@/stores/AppStateStore';
import {taskConditionsReducer} from '@/stores/TaskConditionsStore';
import {supportTasksFilterReducer} from '@/stores/SupportTasksFilterStore';
import {searchStateReducer} from '@/stores/SearchStateStore';
import {taskChatStateReducer} from '@/stores/TaskChatStore';
import {subtasksFilterReducer} from '@/stores/SubtasksFilterStore';
import {taskEditStateReducer} from '@/stores/TaskEditStore';
import {chatListReducer} from '@/stores/ChatListStore';

const middlewares: ReadonlyArray<Middleware<never>> = [api.middleware];

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['authState', 'fileStorageState']
};

export const store = configureStore({
  reducer: persistCombineReducers(rootPersistConfig, {
    [api.reducerPath]: api.reducer,
    appState: appStateReducer,
    authState: authStateReducer,
    tasksFilter: tasksFilterStateReducer,
    subtasksFilter: subtasksFilterReducer,
    supportTasksFilter: supportTasksFilterReducer,
    fileStorageState: fileStorageReducer,
    tasksTableState: tasksTableStateReducer,
    taskEditState: taskEditStateReducer,
    taskSettingsState: taskSettingsStateReducer,
    taskConditions: taskConditionsReducer,
    searchState: searchStateReducer,
    taskChatState: taskChatStateReducer,
    chatListState: chatListReducer
  }),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(...middlewares)
});

// enable listener behavior for the store
setupListeners(store.dispatch);

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}

export type AppDispatch = typeof store.dispatch;
