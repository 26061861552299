import React, {FC, memo, useEffect, useMemo} from 'react';

import {ComboBox} from '@/components/UIKit';
import {IOption} from '@/shared/models/tasksFilterModel';
import {ITask} from '@/shared/models/tasksDataModel';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {taskTypeSelector, updateType} from '@/stores/TaskSettingsStore';
import {useGetTypesQuery} from '@/stores/api/filtered-dictionaries';
import {ISubtask} from '@/shared/models/subtaskModel';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';

interface IProps {
  task?: ITask | ISubtask;
  isDisabled?: boolean;
  onChangeData: (params: Record<string, number>) => void;
}

const TaskTypeBox: FC<IProps> = ({task, isDisabled, onChangeData}) => {
  const dispatch = useAppDispatch();
  const taskType = useAppSelector(taskTypeSelector);
  const {typesIds: allTypesIds} = usePrepareDict();

  const isTask = task && 'taskComments' in task;
  const isSubtask = task && 'subtaskComments' in task;
  const isSupportTask = task && 'supportTaskComments' in task;
  const taskDirection = task && 'direction' in task ? task?.direction : false;

  /**
   *    Получаем Список типов
   *    В карточке задачи:
   *  - Вставляем отдел задачи в фильтр в зависимости от direction задачи. Direction=True - filters[incoming_task_types], Dicrection=False - filters[outgoing_task_types]
   *    В создании подзадачи:
   *  - Вставляем выбранный отдел фильтр в filters[subtask_types]
   */
  const filterParam = useMemo(() => {
    if (isSupportTask) {
      // для техподдержки отдел не имеет значения
      return {
        'filters[is_support_task]': 1
      };
    }
    let param;
    if (isSubtask) {
      param = 'filters[subtask_types]';
    } else {
      param = taskDirection ? 'filters[incoming_task_types]' : 'filters[outgoing_task_types]';
    }

    return param
      ? {
          [param]: task?.departmentId
        }
      : {};
  }, [isSupportTask, isSubtask, task?.departmentId, taskDirection]);

  const {types, isTypeLoading} = useGetTypesQuery(filterParam, {
    selectFromResult: ({data, isLoading}) => ({
      types: data
        ? data.filter(val => {
            if (isSubtask) {
              return val.isSubtask;
            }

            if (isTask) {
              return val.isTask;
            }

            if (isSupportTask) {
              return val.isSupportTask;
            }

            return val;
          })
        : [],
      isTypeLoading: isLoading
    })
  });

  const initType = useMemo(() => {
    /**
     * При инициализации подставляем типы из полного списка
     * в отфильтрованной версии может не быть выбранного
     */
    if (!allTypesIds || !task?.taskTypeId) {
      return;
    }

    return allTypesIds[task.taskTypeId];
  }, [task?.taskTypeId, allTypesIds]);

  useEffect(() => {
    if (initType) {
      dispatch(updateType(initType));
    }
  }, [dispatch, initType]);

  const handleChangeType = (value?: IOption) => {
    dispatch(updateType(value));
    if (value) {
      onChangeData({
        task_type_id: Number(value.id)
      });
    }
  };

  return (
    <ComboBox<IOption>
      options={types || []}
      variantInput="standard"
      fieldLabel="Тип задачи"
      loading={isTypeLoading}
      sx={{
        minWidth: '400px'
      }}
      disableClearable
      value={taskType || null}
      // @ts-ignore
      onChange={(_, newValues: IOption | null) => {
        // @ts-ignore
        handleChangeType(newValues);
      }}
      placeholder="Выберите тип задачи"
      disabled={isDisabled}
    />
  );
};

export default memo(TaskTypeBox);
