import React, {FC} from 'react';
import {ListItemButton, Stack, Tooltip, Typography} from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import {IUser} from '@/shared/models';
import {formatUserName} from '@/shared/utils/fieldsUtils.ts';
import {formatRecentDate} from '@/shared/utils/dateUtils';
import {EColors} from '@/shared/constants/colors.ts';
import {formatHTML} from '@/shared/utils/stringUtils';

interface IProps {
  title: string;
  content?: string;
  createdAt?: string;
  isTelegram?: boolean;
  isWhatsapp?: boolean;
  user?: IUser;
  unreadCount?: number;
  isHasFiles?: boolean;
  isSelected: boolean;
  onClick: () => void;
}

const ChatListItem: FC<IProps> = ({
  title = 'Общий чат',
  content,
  createdAt,
  isTelegram,
  isWhatsapp,
  user,
  unreadCount,
  isHasFiles,
  isSelected,
  onClick
}) => {
  return (
    <>
      <ListItemButton
        selected={isSelected}
        onClick={onClick}
        sx={{
          ...styles.chatListItem,
          border: isSelected ? '1px solid rgba(0, 0, 0, 0.2)' : ''
        }}
      >
        <Stack sx={styles.wrapper}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={styles.header}
          >
            {isTelegram && (
              <Tooltip title={'Telegram'}>
                <TelegramIcon sx={styles.icon} />
              </Tooltip>
            )}
            {isWhatsapp && (
              <Tooltip title={'WhatsApp'}>
                <WhatsAppIcon sx={styles.icon} />
              </Tooltip>
            )}
            <Typography align="left" variant="h6" sx={styles.title}>
              {title}
            </Typography>
            <Typography align="right" variant="body2" sx={styles.date}>
              {createdAt ? formatRecentDate(createdAt) : '-'}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={styles.body}
          >
            <Typography sx={styles.content} variant="body1">
              {user && (
                <Typography variant="body1" color={EColors.Accent} component="span">
                  {formatUserName(user) + ': '}
                </Typography>
              )}
              {content && content.trim() !== '' && content.trim() !== '<p></p>'
                ? formatHTML(content)
                : isHasFiles
                ? 'Файлы'
                : 'Нет сообщений'}
            </Typography>
            {unreadCount !== 0 && (
              <Typography variant="body1" sx={styles.counter}>
                {unreadCount && unreadCount >= 9000 ? '9000+' : unreadCount}
              </Typography>
            )}
          </Stack>
        </Stack>
      </ListItemButton>
    </>
  );
};

const styles = {
  icon: {
    width: '20px',
    marginRight: '1px'
  },
  chatListItem: {
    borderRadius: 0.4,
    boxShadow:
      '0px 1px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.12)'
  },
  wrapper: {
    width: '100%',
    padding: '3px 0'
  },
  header: {
    marginBottom: 2
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '85%',
    fontSize: '1.4rem'
  },
  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '85%'
  },
  date: {
    marginLeft: 'auto',
    fontSize: '14px'
  },
  body: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  counter: {
    backgroundColor: EColors.Accent,
    color: EColors.White,
    borderRadius: 10,
    fontSize: '12px',
    lineHeight: '24px',
    padding: '0 6px',
    textAlign: 'center',
    shadowColor: EColors.Accent,
    shadowOffset: {
      width: 0,
      height: 6
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.05,
    elevation: 8,
    minWidth: 24,
    marginLeft: 'auto'
  }
};

export default ChatListItem;
