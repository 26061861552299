import {toast} from 'react-toastify';

import {IFile} from '@/shared/models/fileModel';

import {toBase64} from './convertBase64';

export default async function prepareAddFiles(fileList: FileList) {
  let maxId = 100;
  const files: IFile[] = [];

  for (const item of fileList) {
    //  50 МБ
    if (item.size < 52428800) {
      const fileBase = (await toBase64(item)) as string;
      const finedIndex = fileBase.indexOf('base64');
      const clearBase = fileBase.substring(finedIndex + 7);
      const file = {
        file: clearBase,
        name: item.name,
        type: item.type,
        size: item.size,
        id: maxId++
      };
      files.push(file);
    } else {
      toast.error('Размер файла больше 50Mb', {
        theme: 'colored'
      });
    }
  }

  return files as IFile[];
}
