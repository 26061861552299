export const LightColors = {
  BgColor: '#EEF0F3',
  Text: '#132436',
  Negative: '#DF2228',
  Positive: '#1C9A65',
  Accent: '#007AFF',
  White: '#fff',
  Muted: '#A2B4C6',
  CardBg: '#fff',
  CommentBg: '#EEF0F3',
  CommentLabel: '#768BA0'
} as const;

export const DarkColors = {
  BgColor: '#020036',
  Text: '#fff',
  Negative: '#FF4040',
  Positive: '#1C9A65',
  Accent: '#007AFF',
  White: '#fff',
  Muted: '#A2B4C6',
  CardBg: '#fff',
  CommentBg: '#3c4858',
  CommentLabel: '#768BA0'
} as const;

export const EColors = LightColors;
