import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {logout} from '@/stores/AuthStore';
import {ICompany} from '@/shared/models/companyModel';
import {IOption} from '@/shared/models/tasksFilterModel';
import {IUser} from '@/shared/models';
import {apiWithTaskPage} from '@/stores/api/task-page/task-page';
import {RootState} from '@/stores';
import {ITask} from '@/shared/models/tasksDataModel';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {IDepartment, ITaskStatus, IUnit} from '@/shared/models/dictionaryModel';
import {IPagination} from '@/shared/models/commonModel.ts';

interface IProps {
  company: {
    data?: ICompany;
    isLoading?: boolean;
    error?: string | null;
  };
  employee: {
    data?: IUser;
    isLoading?: boolean;
    error?: string | null;
  };
  user: {
    data?: IUser;
    isLoading?: boolean;
    error?: string | null;
  };
  unit: {
    data?: IUnit;
    isLoading?: boolean;
    error?: string | null;
  };
  department: {
    data?: IDepartment;
    isLoading?: boolean;
    error?: string | null;
  };
  taskType: {
    data?: IOption;
    isLoading?: boolean;
    error?: string | null;
  };
  taskStatus: {
    data?: ITaskStatus;
    isLoading?: boolean;
    error?: string | null;
  };
  delayAt: {
    error?: string | null;
  };
}

const initialState: IProps = {
  company: {
    data: undefined,
    isLoading: false
  },
  user: {
    data: undefined,
    isLoading: false
  },
  unit: {
    data: undefined,
    isLoading: false
  },
  department: {
    data: undefined,
    isLoading: false
  },
  employee: {
    data: undefined,
    isLoading: false
  },
  taskType: {
    data: undefined,
    isLoading: false
  },
  taskStatus: {
    data: undefined,
    isLoading: false
  },
  delayAt: {
    error: null
  }
};

export const taskEditStateSlice = createSlice({
  name: 'taskEditState',
  initialState,
  reducers: {
    updateCompany: (state, action: PayloadAction<ICompany | undefined>) => {
      state.company = {
        ...state.company,
        data: action.payload
      };
    },
    setCompanyError: (state, action: PayloadAction<string | null>) => {
      state.company = {
        ...state.company,
        error: action.payload
      };
    },
    updateEmployee: (state, action: PayloadAction<IUser | undefined>) => {
      state.employee = {
        ...state.employee,
        data: action.payload
      };
    },
    setEmployeeError: (state, action: PayloadAction<string | null>) => {
      state.employee = {
        ...state.employee,
        error: action.payload
      };
    },
    updateUnit: (state, action: PayloadAction<IUnit | undefined>) => {
      state.unit = {
        ...state.unit,
        data: action.payload
      };
    },
    updateDepartment: (state, action: PayloadAction<IDepartment | undefined>) => {
      state.department = {
        ...state.department,
        data: action.payload
      };
    },
    setDepartmentError: (state, action: PayloadAction<string | null>) => {
      state.department = {
        ...state.department,
        error: action.payload
      };
    },
    updateUser: (state, action: PayloadAction<IUser | undefined>) => {
      state.user = {
        ...state.user,
        data: action.payload
      };
    },
    setUserError: (state, action: PayloadAction<string | null>) => {
      state.user = {
        ...state.user,
        error: action.payload
      };
    },
    updateType: (state, action: PayloadAction<IOption | undefined>) => {
      state.taskType = {
        ...state.taskType,
        data: action.payload
      };
    },
    setTypeError: (state, action: PayloadAction<string | null>) => {
      state.taskType = {
        ...state.taskType,
        error: action.payload
      };
    },
    updateTaskStatus: (state, action: PayloadAction<ITaskStatus | undefined>) => {
      state.taskStatus = {
        ...state.taskStatus,
        data: action.payload
      };
    },
    setDelayAtError: (state, action: PayloadAction<string | null>) => {
      state.delayAt = {
        ...state.delayAt,
        error: action.payload
      };
    },
    clearErrors: state => {
      state.company.error = null;
      state.employee.error = null;
      state.taskType.error = null;
      state.department.error = null;
      state.delayAt.error = null;
      state.user.error = null;
    },
    resetState: () => {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => {
        return initialState;
      })
      .addMatcher<any>(
        apiWithTaskPage.endpoints.getTaskById.matchFulfilled,
        (state: RootState, action: PayloadAction<IPagination<ITask>>) => {
          if (action.payload) {
            const {data} = action.payload;
            const employee = data.employee || {};
            state.employee.data = {
              ...employee,
              name: formatUserName(employee)
            };

            return state;
          }

          return initialState;
        }
      );
  }
});

export const {
  updateCompany,
  updateEmployee,
  updateUnit,
  updateDepartment,
  updateUser,
  updateType,
  updateTaskStatus,
  setCompanyError,
  setEmployeeError,
  setDepartmentError,
  setTypeError,
  setUserError,
  setDelayAtError,
  clearErrors,
  resetState
} = taskEditStateSlice.actions;

export const taskEditStateReducer = taskEditStateSlice.reducer;
