import React, {FC} from 'react';
import cn from 'classnames';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {ReactComponent as AddFileIcon} from './assets/file.svg';
import s from './InputFile.module.css';

interface IProps {
  id?: string;
  multiple?: boolean;
  block?: boolean;
  onChange: (files: FileList) => void;
  className?: string;
  isDisabled?: boolean;
}

const InputFile: FC<IProps> = ({
  id = 'addFile',
  multiple = false,
  block = false,
  onChange,
  className,
  isDisabled
}) => {
  const onChangeInputFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      onChange(event.currentTarget.files);
    }
  };

  return (
    <div className={cn(s.inputFile, className, {[s.block]: block, [s.disabled]: isDisabled})}>
      <input
        type="file"
        className={s.field}
        onChange={onChangeInputFile}
        id={id}
        multiple={multiple}
        onClick={event => {
          // workaround
          // onChange не срабатывает при добавление тех же самых файлов, что и ранее
          // https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component
          // @ts-ignore
          event.currentTarget.value = null;
        }}
      />
      <label className={cn(s.label, {[s.labelBlock]: block})} htmlFor={id}>
        {block ? (
          <>
            <span className={s.labelIcon}>
              <AddCircleOutlineIcon width={20} />
            </span>
            <strong>Прикрепить файл</strong>
          </>
        ) : (
          <AddFileIcon />
        )}
      </label>
    </div>
  );
};

export default InputFile;
