import {useDispatch} from 'react-redux';
import {useCallback, useEffect} from 'react';

import {setIsLoading} from '@/stores/AppStateStore';

export default (isLoading?: boolean) => {
  const dispatch = useDispatch();

  const handleLoading = useCallback(
    (loading?: boolean) => {
      dispatch(setIsLoading(!!loading));
    },
    [dispatch]
  );

  useEffect(() => {
    handleLoading(isLoading);

    return () => {
      handleLoading(false);
    };
  }, [isLoading, handleLoading]);

  return {showModalLoading: handleLoading};
};
