import {useEffect, useMemo} from 'react';

import {prepareSortFunc} from '@/shared/utils/sortUtils';
import {prepareUrlParams} from '@/shared/utils/urlParamsUtils';
import {useGetTasksQuery} from '@/stores/api/tasks-table/tasks';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {TPaginationModel, ITableHookProps} from '@/shared/models/tableModel';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {tasksFilterValuesSelector} from '@/stores/TasksFilterStore/TasksFilterStateSelector';
import {updateValueByKey} from '@/stores/TasksFilterStore';
import {EAdditionalValues, EFilterName} from '@/shared/models/tasksFilterModel';
import {individualCompanies} from '@/shared/constants/companyConstants';
import {counterSelector, searchSelector} from '@/stores/SearchStateStore/SearchStateSelector.ts';
import {getCounterFilters} from '@/shared/utils/countersUtils';
import {withoutUser} from '@/shared/constants/userConstants.ts';

interface QueryParams {
  page: {
    limit: number;
    offset: number;
  };
  sort?: string;
  'filters[department_id]'?: any;
  'filters[user_id]'?: any;
  'filters[task_status_id]'?: any;
  'filters[task_source_id]'?: any;
  'filters[task_type_id]'?: any;
  'filters[unit_id]'?: any;
  'filters[company_id]'?: any;
  'counters[user_unread_comments]'?: number;
  'filters[created_at]'?: string;
  'filters[done_at]'?: string;
  [key: string]: any;
}

export const useGetTasksData = ({
  paginationModel,
  setPaginationModel,
  sortModel
}: ITableHookProps) => {
  const dispatch = useAppDispatch();

  const filterValues = useAppSelector(tasksFilterValuesSelector);
  const searchState = useAppSelector(searchSelector);
  const counterState = useAppSelector(counterSelector);

  const {isReady} = usePrepareDict();

  const sort = useMemo(() => {
    if (!sortModel.length) {
      return 'active_tasks';
    }
    return prepareSortFunc(sortModel);
  }, [sortModel]);

  const showOnlyMyTasks = useMemo(() => {
    let i = 0;
    if (searchState !== '') {
      return false;
    }
    for (const entry of Object.entries<any>(filterValues)) {
      const [key, val] = entry;
      // @ts-ignore
      if (![EAdditionalValues.Page, EAdditionalValues.Sort].includes(key) && val) {
        i++;
      }
    }

    return i === 0;
  }, [filterValues, searchState]);

  useEffect(() => {
    dispatch(
      updateValueByKey({
        key: EAdditionalValues.Page,
        value: `${paginationModel.page}`
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page]);

  const urlPage: number = filterValues[EAdditionalValues.Page]
    ? parseInt(filterValues[EAdditionalValues.Page] || '0', 10)
    : 0;

  useEffect(() => {
    setPaginationModel((state: TPaginationModel) => {
      if (state.page === urlPage) {
        return state;
      }
      return {
        pageSize: state.pageSize,
        page: urlPage
      };
    });
  }, [setPaginationModel, urlPage]);

  const query = useMemo(() => {
    const params: QueryParams = {
      page: {
        limit: paginationModel.pageSize,
        offset: paginationModel.page === 0 ? 0 : paginationModel.page * paginationModel.pageSize
      },
      sort,
      'filters[department_id]': prepareUrlParams(filterValues[EFilterName.Departments]),
      'filters[head]': prepareUrlParams(filterValues[EFilterName.Head]),
      'filters[user_id]': prepareUrlParams(filterValues[EFilterName.Users]),
      'filters[task_status_id]': prepareUrlParams(filterValues[EFilterName.Statuses]),
      'filters[task_source_id]': prepareUrlParams(filterValues[EFilterName.Sources]),
      'filters[task_type_id]': prepareUrlParams(filterValues[EFilterName.Types]),
      'filters[unit_id]': prepareUrlParams(filterValues[EFilterName.Units]),
      'filters[company_id]': prepareUrlParams(filterValues[EFilterName.CompanyId]),
      'counters[user_unread_comments]': 1
    };

    if (showOnlyMyTasks) {
      params['filters[my_tasks]'] = 1;
    }

    // Для задач и подзадач при выбранном фильтре в запросе использовать filters[physic_companies]=1
    if (filterValues[EFilterName.CompanyId] === individualCompanies) {
      params['filters[physic_companies]'] = 1;
    }

    // вынесено, потому что падает ошибка, если отправляется пустой ответ
    // значение -1 отправляется как 0
    if (filterValues[EFilterName.Direction]) {
      params['filters[direction]'] =
        filterValues[EFilterName.Direction] == -1 ? 0 : filterValues[EFilterName.Direction];
    }

    const allCountersFilters = getCounterFilters();
    const counterFilter = allCountersFilters[counterState as keyof typeof allCountersFilters];

    if (counterState) {
      // @ts-ignore
      params[counterFilter] = 1;
    }

    if (filterValues[EFilterName.CreatedAt]) {
      params['filters[created_at]'] = filterValues[EFilterName.CreatedAt];
    }

    if (filterValues[EFilterName.DoneAt]) {
      params['filters[done_at]'] = filterValues[EFilterName.DoneAt];
    }

    if (filterValues[EFilterName.Users] === withoutUser) {
      params['filters[user_id]'] = 'is|null';
    }

    if (searchState) {
      return {
        ...params,
        find: searchState
      };
    }

    return params;
  }, [
    paginationModel.pageSize,
    paginationModel.page,
    sort,
    filterValues,
    showOnlyMyTasks,
    searchState,
    counterState
  ]);

  const {data, isLoading, isFetching} = useGetTasksQuery(query, {
    skip: !isReady,
    refetchOnMountOrArgChange: true,
    pollingInterval: 30000
  });

  return {
    data,
    isLoading,
    isFetching
  };
};
