import axios, {CreateAxiosDefaults} from 'axios';

import Config from '@/shared/constants/config';
import {store} from '@/stores';
import {loginFileStorage} from '@/stores/fileStorage/api';
import {setToken} from '@/stores/FileStorageStore';

const fileStorageAPI = axios.create({
  baseURL: Config.fileStorage.url,
  headers: {}
} as CreateAxiosDefaults);

export const handleS3Storage = async () => {
  const response: any = await loginFileStorage();

  if (response?.token) {
    store.dispatch(setToken(response?.token));
  }

  return response?.token;
};

// default refresh token state value
let isTokenRefreshFetched = false;

fileStorageAPI.interceptors.request.use(
  async config => {
    if (config?.options?.skipAuth) {
      return config;
    }
    const token = store.getState().fileStorageState.token;

    if (token) {
      config.headers['X-Auth-Token'] = token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

fileStorageAPI.interceptors.response.use(
  function (response) {
    return response?.data;
  },
  async function (err) {
    const error = err?.response;
    console.debug('error', error);

    if (error?.status === 401 && !isTokenRefreshFetched) {
      // set fetched status
      isTokenRefreshFetched = true;

      // TODO: refresh token
      const newTokenResponse = await handleS3Storage();
      console.debug('new token', newTokenResponse);
      err.config.headers['X-Auth-Token'] = newTokenResponse?.user?.id;
      return fileStorageAPI(err.config);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default fileStorageAPI;
