import React, {FC, memo} from 'react';
import {Button, useTheme} from '@mui/material';

import {ETaskStatuses, TaskStatusNames} from '@/shared/constants/taskStatuses';

interface IProps {
  taskStatus?: ETaskStatuses;
}

const style = {
  minWidth: 150,
  maxWidth: 180,
  height: 40,
  bgcolor: 'background.paper',
  '&.Mui-disabled': {
    color: 'black'
  }
};

const SupportTaskStatusButton: FC<IProps> = ({taskStatus}) => {
  const theme = useTheme();

  const isDisabled = true;

  const statusMessage = taskStatus ? TaskStatusNames[taskStatus] : 'Загружается...';

  return (
    <>
      <Button
        variant="text"
        color="inherit"
        disabled={isDisabled}
        sx={{
          ...style,
          [theme.breakpoints.down('lg')]: {
            minWidth: 0,
            paddingLeft: 4,
            paddingRight: 4
          }
        }}
      >
        {statusMessage}
      </Button>
    </>
  );
};

export default memo(SupportTaskStatusButton);
