import {differenceInSeconds, format, formatDuration, intervalToDuration} from 'date-fns';
import {ru} from 'date-fns/locale';
import {grey} from '@mui/material/colors';

const formatDistanceLocale = {
  xSeconds: '{{count}}сек.',
  xMinutes: '{{count}}мин.',
  xHours: '{{count}}ч.',
  xDays: '{{count}}д.',
  xMonths: '{{count}}мес.',
  xYears: '{{count}}г.'
} as const;

const shortRuLocale = {
  formatDistance: (token: string, count: number) => {
    // @ts-ignore
    return formatDistanceLocale[token].replace('{{count}}', count);
  }
};

export const formatDate = (date: number | string | Date, pattern = 'dd.MM.yyyy') => {
  if (!date) {
    return '';
  }

  if (date === '-') {
    return date;
  }

  let datetime: Date;

  if (typeof date === 'string') {
    datetime = new Date(date);
  } else if (typeof date === 'number') {
    datetime = new Date(date * 1000);
  } else {
    datetime = date;
  }

  return format(datetime, pattern, {
    locale: ru
  });
};

export const deadlineTime = (deadlineAt: string, doneAt?: string) => {
  if (!deadlineAt) {
    return;
  }
  const maxDate = doneAt ? new Date(doneAt) : new Date();
  const deadlineDate = new Date(deadlineAt);

  const diff = differenceInSeconds(deadlineDate, maxDate);

  if (Math.abs(diff) < 60) {
    return '<1 мин.';
  }

  const dateInterval = intervalToDuration({
    start: maxDate,
    end: deadlineDate
  });

  return formatDuration(dateInterval, {
    format: ['years', 'months', 'days', 'hours', 'minutes'],
    locale: shortRuLocale
  });
};

export const EDeadlineColors = {
  White: '#fff',
  Red: '#FF4040',
  Black: grey[700],
  None: 'transparent'
} as const;

export const deadlineColor = (deadlineAt: string, createdAt: string, doneAt?: string) => {
  if (!deadlineAt) {
    return EDeadlineColors.None;
  }
  const maxDate = doneAt ? new Date(doneAt) : new Date();
  // 20%
  const diff = differenceInSeconds(new Date(deadlineAt), maxDate);
  // 100%;
  const max = differenceInSeconds(new Date(createdAt), maxDate);

  // задача выполнена к текущему моменту
  // if (doneAt && isBefore(new Date(doneAt), new Date())) {
  //   return EDeadlineColors.None;
  // }

  if (diff < 0) {
    return EDeadlineColors.Black;
  }

  if (Math.abs(diff) < Math.abs(max) * 0.2) {
    return EDeadlineColors.Red;
  }

  return EDeadlineColors.White;
};

export const formatRecentDate = (date: number | string | Date, hasToday = false) => {
  const datetime = new Date(date);

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const lastMonth = new Date();
  lastMonth.setFullYear(lastMonth.getMonth() - 1);

  if (
    datetime.getDate() === today.getDate() &&
    datetime.getMonth() === today.getMonth() &&
    datetime.getFullYear() === today.getFullYear()
  ) {
    return hasToday ? 'Сегодня' : format(datetime, 'HH:mm');
  }

  if (
    datetime.getDate() === yesterday.getDate() &&
    datetime.getMonth() === yesterday.getMonth() &&
    datetime.getFullYear() === yesterday.getFullYear()
  ) {
    return 'Вчера';
  }

  if (datetime.getFullYear() === today.getFullYear()) {
    return format(datetime, 'dd.MM');
  }

  return formatDate(date);
};
