import {useMemo} from 'react';

import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {useAppSelector} from '@/stores/hooks';

export const useResponsiblesFilters = () => {
  const currentUser = useAppSelector(userInfoSelector);

  const subordinateUserIds = useMemo(() => {
    if (!currentUser?.subordinates) {
      return;
    }

    const subordinates = currentUser.subordinates.map(subordinate => {
      return subordinate.subordinateId;
    });
    return [...subordinates, currentUser.id].join(',');
  }, [currentUser]);

  const unitIds = useMemo(() => {
    if (!currentUser?.userUnits) {
      return;
    }

    return currentUser.userUnits
      .map(unit => {
        return unit.unitId;
      })
      .join(',');
  }, [currentUser]);

  const responsiblesFilters = useMemo(() => {
    if (!currentUser) {
      return {};
    }

    /**
     * возможность сотрудникам техподдержки получить список
     * клиентов при создании задачи на странице техподдержки
     */
    const isSupportDepartment = currentUser.userDepartments?.some(
      department => department.departmentId === 26
    );

    if (isSupportDepartment) {
      return {
        'filters[or_inner_support_company]': '2'
      };
    }

    /**
     *  в селекторе “Клиент” бухгалтер видит только тех клиентов к которым привязан бухгалтер:
     *  если is_head = False и is_head_unit = False
     *  подставляем в запрос списка клиентов
     *  фильтр filters[has_responsibles]= id текущего авторизованного пользователя
     */
    const isBuh = !currentUser.isHead && !currentUser.isHeadUnit;

    if (isBuh) {
      return {
        'filters[has_responsibles]': `${currentUser.id}`
      };
    }

    /**
     * руководитель отдела видит только тех клиентов к которым привязаны бухгалтера его отдела:
     * Если is_head = True подставляем в фильтр filters[has_responsibles]= все id через запятую из массива в токене subordinates.user_id
     */
    const isHead = currentUser.isHead;

    if (isHead && subordinateUserIds) {
      return {
        'filters[has_responsibles]': subordinateUserIds
      };
    }

    /**
     * руководитель юнита видит всех клиентов в рамках привязанных к нему юнитов:
     * Если is_head_unit=True подставляем в фильтр filters[unit_id]=in| все id через запятую из массива в токене user_units
     */

    const isHeadUnit = currentUser.isHeadUnit;

    if (isHeadUnit && unitIds) {
      return {
        'filters[unit_id]': 'in|' + unitIds
      };
    }

    return {};
  }, [currentUser, subordinateUserIds, unitIds]);

  return responsiblesFilters;
};
