import React, {FC, memo, useState} from 'react';
import {useLocation} from 'react-router';
import {Backdrop, Button, Fade, Modal} from '@mui/material';

import {CreateTask} from '@/modals/create/CreateTask/index';
import {CreateSubtask} from '@/modals/create/CreateSubtask/index';
import {CreateSupportTask} from '@/modals/create/CreateSupportTask';
import {RoutePaths} from '@/shared/constants/route';

import s from './CreateButtons.module.css';

interface IProps {}

const CreateButtons: FC<IProps> = () => {
  const {pathname} = useLocation();
  const isTechSupport = pathname === RoutePaths.MainTechSupport;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSubtask, setOpenSubtask] = useState(false);
  const handleOpenSubtask = () => setOpenSubtask(true);
  const handleCloseSubtask = () => setOpenSubtask(false);

  const [openSupportTask, setOpenSupportTask] = useState(false);
  const handleOpenSupportTask = () => setOpenSupportTask(true);
  const handleCloseSupportTask = () => setOpenSupportTask(false);

  return (
    <>
      <div className={s.buttons}>
        <Fade
          key={1}
          mountOnEnter
          unmountOnExit
          in={!isTechSupport}
          style={{display: isTechSupport ? 'none' : 'block'}}
        >
          <div className={s.buttons}>
            <Button type="button" variant="contained" color="error" onClick={handleOpen}>
              Создать задачу
            </Button>
            <Button type="button" variant="contained" color="primary" onClick={handleOpenSubtask}>
              Создать подзадачу
            </Button>
          </div>
        </Fade>
        <Fade
          key={2}
          mountOnEnter
          unmountOnExit
          in={isTechSupport}
          style={{display: !isTechSupport ? 'none' : 'block'}}
        >
          <Button
            type="button"
            variant="contained"
            color="error"
            onClick={handleOpenSupportTask}
            sx={{whiteSpace: 'nowrap'}}
          >
            Создать задачу в ТП
          </Button>
        </Fade>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Окно создания задачи"
        closeAfterTransition
        disablePortal
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <CreateTask onClose={handleClose} />
      </Modal>
      <Modal
        open={openSubtask}
        onClose={handleCloseSubtask}
        aria-labelledby="Окно создания подзадачи"
        closeAfterTransition
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
        disableEnforceFocus
      >
        <CreateSubtask onClose={handleCloseSubtask} />
      </Modal>

      <Modal
        open={openSupportTask}
        onClose={handleCloseSubtask}
        aria-labelledby="Окно создания подзадачи"
        closeAfterTransition
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <CreateSupportTask onClose={handleCloseSupportTask} />
      </Modal>
    </>
  );
};

export default memo(CreateButtons);
