import React, {FC, useRef} from 'react';
import DOMPurify from 'dompurify';

import {useLinkClickHandlers} from './useLinkClickHandlers';

interface IProps {
  html: string;
}
const HtmlContent: FC<IProps> = ({html}) => {
  const ref = useRef<HTMLDivElement>(null);
  useLinkClickHandlers(ref);

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(html)
  });

  return (
    <div
      className="html-content-wrapper"
      style={{wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}
      dangerouslySetInnerHTML={sanitizedData()}
      ref={ref}
    />
  );
};

export default HtmlContent;
