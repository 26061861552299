import React, {FC, memo, useEffect, useMemo} from 'react';
import cn from 'classnames';

import {ComboBox} from '@/components/UIKit';
import {IOption} from '@/shared/models/tasksFilterModel';
import {ITask} from '@/shared/models/tasksDataModel';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {taskStatusSelector, updateTaskStatus} from '@/stores/TaskEditStore';
import {ISubtask} from '@/shared/models/subtaskModel';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {ITaskStatus} from '@/shared/models/dictionaryModel';
import {ISupportTask} from '@/shared/models/supportTaskModel';
import {useGetStatusesQuery} from '@/stores/api/filtered-dictionaries';
import {ETaskStatuses} from '@/shared/constants/taskStatuses.ts';

interface IProps {
  task?: ITask | ISubtask | ISupportTask;
  isLoading?: boolean;
  isDisabled?: boolean;
  onChangeData: (params: Record<string, number | boolean>) => void;
}

const TaskStatusBox: FC<IProps> = ({task, isLoading, isDisabled, onChangeData}) => {
  const dispatch = useAppDispatch();
  const editedStatus = useAppSelector(taskStatusSelector);
  const {statusesIds: statusesIds} = usePrepareDict();
  const [isValueChanged, setIsValueChanged] = React.useState(false);

  const {statuses, isStatusesLoading} = useGetStatusesQuery(
    {
      'filters[without_done]': 1
    },
    {
      selectFromResult: ({data, isLoading}) => ({
        statuses: data ? data.filter(o => o.id !== editedStatus?.id) : [],
        isStatusesLoading: isLoading
      })
    }
  );

  const initStatus = useMemo(() => {
    /**
     * При инициализации подставляем статус из полного списка
     * в отфильтрованной версии может не быть выбранного
     */
    if (!statusesIds || !task?.taskStatusId) {
      return;
    }

    return statusesIds[task.taskStatusId];
  }, [task?.taskStatusId, statusesIds]);

  useEffect(() => {
    if (initStatus) {
      dispatch(updateTaskStatus(initStatus));
    }
  }, [dispatch, initStatus]);

  useEffect(() => {
    editedStatus?.id !== task?.taskStatusId ? setIsValueChanged(true) : setIsValueChanged(false);
  }, [dispatch, editedStatus, task?.taskStatusId]);

  const handleChangeTaskStatus = (value?: ITaskStatus) => {
    dispatch(updateTaskStatus(value));
    if (value) {
      value.id !== task?.taskStatusId ? setIsValueChanged(true) : setIsValueChanged(false);

      onChangeData({
        task_status_id: Number(value.id),
        is_rate: value.id !== ETaskStatuses.Closed
      });
    }
  };

  return (
    <ComboBox<IOption>
      options={statuses || []}
      variantInput="standard"
      fieldLabel="Статус задачи"
      loading={isLoading || isStatusesLoading}
      sx={{
        minWidth: '400px'
      }}
      disableClearable
      value={editedStatus || null}
      // @ts-ignore
      onChange={(_, newValue?: ITaskStatus) => {
        handleChangeTaskStatus(newValue);
      }}
      placeholder="Выберите статус задачи"
      disabled={isDisabled}
      inputClassName={cn({'combo-box-changed': isValueChanged})}
    />
  );
};

export default memo(TaskStatusBox);
