import React, {FC, SyntheticEvent, useState} from 'react';
import {Backdrop, Box, Button, Grid, Modal, Typography} from '@mui/material';

import {TextEditor} from '@/components/TextEditor/index';

import s from './Correction.module.css';

interface IProps {
  title: string;
  sendButtonText: string;
  open: boolean;
  isLoading?: boolean;
  onSubmit: (content: string) => void;
  onClose: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 534,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '70vh',
  overflowY: 'auto'
};

const Correction: FC<IProps> = ({title, open, isLoading, sendButtonText, onSubmit, onClose}) => {
  const [value, setValue] = useState<string>('');

  const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(value);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Перевод в другой отдел"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <form action="" onSubmit={handleSubmit}>
          <Typography variant="h2">{title}</Typography>
          <Box sx={{mb: 20, mt: 10}}>
            <label htmlFor="content">Комментарий</label>
            <TextEditor
              id="content"
              value={value}
              className={s.editor}
              onChange={(value: string) => {
                setValue(value);
              }}
            />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={onClose}
                sx={{minWidth: 140}}
                disabled={false}
              >
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                sx={{minWidth: 140}}
              >
                {sendButtonText}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default Correction;
