import {api} from '@/stores/api';
import {ITaskResponse} from '@/shared/models/tasksDataModel';

export interface IRateTaskArg {
  taskId?: string;
  rate?: number;
  content?: string;
}

export const apiWithTaskRate = api.injectEndpoints({
  endpoints: builder => ({
    rateTask: builder.mutation<ITaskResponse, IRateTaskArg>({
      query: ({taskId, rate, content = ''}) => ({
        url: `/v1/tasks/${taskId}/rate`,
        method: 'put',
        data: {
          rate,
          task_comment: {
            content
          }
        }
      }),
      // @ts-ignore
      transformResponse(returnValue?: {data: ITaskResponse}): ITaskResponse | undefined {
        return returnValue?.data;
      }
    })
  }),
  overrideExisting: true
});

export const {useRateTaskMutation} = apiWithTaskRate;
