import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'src/stores';

import {
  EFilterName,
  IDateRange,
  IFilterOption,
  IOption,
  TDateFilterRange,
  TFilterValues,
  TTempFilterValues
} from '@/shared/models/tasksFilterModel';

export const tasksFilterSelector = (state: RootState) => {
  return state.tasksFilter.filterList as Record<EFilterName, IFilterOption<IOption>>;
};

export const tempTasksFilterValuesSelector = (state: RootState) => {
  return state.tasksFilter.tempFilterValues as TTempFilterValues;
};

export const tasksDateFilterSelector = (state: RootState) => {
  return state.tasksFilter.filterListDates as TDateFilterRange;
};

export const tasksFilterValuesSelector = (state: RootState) => {
  return state.tasksFilter.filterValues;
};

export const tasksFilterIsFetchSelector = (state: RootState) => {
  return state.tasksFilter.isFetched;
};

export const tasksFilterIsUpdatedSelector = (state: RootState) => {
  return state.tasksFilter.isUpdated;
};

export const tasksFilterHasValuesSelector = createSelector(
  tasksFilterValuesSelector,
  (values: TFilterValues) => {
    if (!values) {
      return false;
    }

    return Object.entries(values).filter(([key, val]) => key !== 'page' && !!val).length > 0;
  }
);

export const preparedFiltersSelector = createSelector(
  tempTasksFilterValuesSelector,
  tasksDateFilterSelector,
  (commonFilters, dateFilters = {}) => {
    const formatDateToString = Object.entries(dateFilters).reduce(
      (acc, curr: [string, IDateRange | undefined]) => {
        if (curr[1] && curr[1].start && curr[1].end) {
          return {
            ...acc,
            [`${curr[0]}`]: `between|${curr[1].start},${curr[1].end}`
          };
        }
        return {
          ...acc,
          [`${curr[0]}`]: ''
        };
      },
      {}
    );

    return {
      ...commonFilters,
      ...formatDateToString
    };
  }
);
