import React, {FC, memo, useMemo} from 'react';

import {Chat} from '@/components/Chat';
import {IFile} from '@/shared/models/fileModel';
import {downloadFile} from '@/shared/utils/downloadFiles';
import {useAppSelector} from '@/stores/hooks';
import {fileStorageTokenSelector} from '@/stores/FileStorageStore';
import {useCreateSubtaskCommentMutation} from '@/stores/api/subtask-page/subtask-comment';
import {IMessage} from '@/shared/models/messageModel';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';

interface IProps {
  taskId: string;
  status?: number;
  isLoading: boolean;
  subtaskComments?: IMessage[];
  isDisplaySystemComments: boolean;
}

const SubtaskChatWrapper: FC<IProps> = ({
  taskId,
  status,
  subtaskComments = [],
  isLoading,
  isDisplaySystemComments
}) => {
  const currentUser = useAppSelector(userInfoSelector);
  const [submitComment, {isLoading: isLoadingSubmit}] = useCreateSubtaskCommentMutation();

  const s3Token = useAppSelector(fileStorageTokenSelector);

  const handleGetFile = (file: IFile) => {
    downloadFile(file, s3Token);
  };

  const handleMessageSend = async (message: string, files: IFile[] = []) => {
    if (!taskId) {
      console.warn('Задача не выбрана');
      return;
    }

    await submitComment({
      taskId,
      content: message,
      attachments: files
    });
  };

  const messages = useMemo(() => {
    if (!subtaskComments) {
      return [];
    }
    return subtaskComments.map(message => {
      return {
        ...message,
        direction: message.direction ?? message.userId !== currentUser?.id
      };
    });
  }, [currentUser?.id, subtaskComments]);

  return (
    <Chat
      messages={messages}
      status={status}
      isLoadingMessages={isLoading}
      isLoadingSubmit={isLoadingSubmit}
      onMessageSend={handleMessageSend}
      onGetFile={handleGetFile}
      isDisplaySystemComments={isDisplaySystemComments}
    />
  );
};

export default memo(SubtaskChatWrapper);
