import {useCallback, useEffect, useRef} from 'react';
import useWebSocket from 'react-use-websocket';
import {Draft} from '@reduxjs/toolkit';

import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {authTokenSelector} from '@/stores/AuthStore/AuthStateSelector';
import {ESocketEvent} from '@/shared/models/socketTypes';
import {api} from '@/stores/api';
import {formatKeys} from '@/shared/utils/responseUtils.ts';
import usePrevious from '@/shared/hooks/usePrevious.ts';
import {withSocket} from '@/shared/constants/appConstants';
import {IChatMessage} from '@/shared/models/chatModel';
import {IPagination} from '@/shared/models/commonModel.ts';

const SOCKET_URL = import.meta.env.APP_SOCKET_URL || '';

export const useChatStreaming = (query: {
  chatId?: string;
  page?: {
    limit?: number;
    offset?: number;
  };
  attributes?: Record<string, any>;
}) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(authTokenSelector);
  const WS_URL = SOCKET_URL + `/chats/${query.chatId}`;
  const didUnmount = useRef(false);
  const prevToken = usePrevious(accessToken);

  const {lastMessage} = useWebSocket(
    WS_URL,
    {
      onOpen: () => console.debug('opened'),
      onClose: () => console.debug('closed'),
      share: false,
      shouldReconnect: () => {
        /*
      useWebSocket will handle unmounting for you, but this is an example of a
      case in which you would not want it to automatically reconnect
    */
        if (prevToken && prevToken !== accessToken) {
          console.log('reconnect', {prevToken, accessToken});
          return true;
        }
        return !didUnmount.current;
      },
      queryParams: {
        token: accessToken
      }
    },
    withSocket
  );

  const handleChatMessage = useCallback(
    (message: IChatMessage) => {
      dispatch(
        api.util.updateQueryData(
          // @ts-ignore
          'getChatMessageList',
          {...query},
          (draft: Draft<IPagination<IChatMessage[]>>) => {
            const comment: IChatMessage = formatKeys(message);
            comment.isWebsocket = true;

            console.log('new message', comment);

            if (draft.data?.length) {
              if (draft.data?.findIndex(o => o.id === comment.id) === -1) {
                draft.data.unshift(comment);
              }
            } else {
              draft.data = [comment];
            }
          }
        )
      );
    },
    [dispatch, query]
  );

  // Run when a new WebSocket message is received (lastMessage)
  useEffect(() => {
    if (lastMessage !== null) {
      const commentData = JSON.parse(lastMessage.data);
      console.debug('Got a new message: ', commentData);

      switch (commentData.type) {
        case ESocketEvent.CreateChatMessage:
          handleChatMessage(commentData.data);
          break;
      }
    }
  }, [handleChatMessage, lastMessage]);

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  return null;
};
