export enum ETaskStatuses {
  New = 1, // Новая
  Progress = 2, // Принята в работу
  Done = 3, // Выполнена
  Closed = 4, // Решена
  Error = 5, // Ошибочная задача
  Delay = 6, // Отложена
  Specify = 7, // На уточнении
  Rejected = 8 // Отклонена
}

export const errorTaskName = 'Ошибочная задача';

export const TaskStatusNames: {[key in ETaskStatuses]: string} = {
  [ETaskStatuses.New]: 'Новая',
  [ETaskStatuses.Progress]: 'Принята в работу',
  [ETaskStatuses.Done]: 'Выполнена',
  [ETaskStatuses.Closed]: 'Решена',
  [ETaskStatuses.Error]: 'Ошибочная задача',
  [ETaskStatuses.Delay]: 'Отложена',
  [ETaskStatuses.Specify]: 'На уточнении',
  [ETaskStatuses.Rejected]: 'Отклонена'
};
