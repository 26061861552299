import {generateFilePath} from '@/shared/utils/fileUtils';
import {IFile} from '@/shared/models/fileModel';
import {getFile} from '@/stores/fileStorage/api';

const downloadFileHelper = (blob: Blob, name = 'file') => {
  const aElement = document.createElement('a');
  aElement.setAttribute('download', name);
  const href = URL.createObjectURL(blob);
  aElement.href = href;
  aElement.setAttribute('target', '_blank');
  aElement.click();
  URL.revokeObjectURL(href);
};

export const downloadFile = async (file: IFile, token: string) => {
  if (!file || !token) {
    console.warn('Файл или токен отсутствует');
    return;
  }

  if (!file.path) {
    console.debug('Локальный файл', file);
    return;
  }

  const path = generateFilePath(file);

  try {
    const response = await getFile(path, {
      responseType: 'blob'
    });

    // @ts-ignore
    downloadFileHelper(new Blob([response]), file.originalName);
  } catch (e) {
    console.warn('Не могу получить файл');
  }
};

export const downloadAllFiles = async (taskId: string, token: string, path: string) => {
  if (!token) {
    console.warn('Файл или токен отсутствует');
    return;
  }

  try {
    const response = await getFile(path, {
      responseType: 'blob'
    });

    // @ts-ignore
    downloadFileHelper(new Blob([response]), `task-${taskId}.zip`);
  } catch (e) {
    console.warn('Не могу получить файл');
  }
};
