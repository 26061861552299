import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {generatePath, useNavigate} from 'react-router';
import {List} from '@mui/material';

import {useAppSelector} from '@/stores/hooks';
import {IQueryArg, useGetChatListQuery} from '@/stores/api/chats';
import {ChatListItem} from '@/scenes/ChatsPage/components/ChatListItem';
import useLinearLoading from '@/shared/hooks/useLinearLoading';
import {SearchField} from '@/components/SearchField';
import {searchSelector} from '@/stores/SearchStateStore/SearchStateSelector';
import {PAGE_SIZE} from '@/scenes/AllTasksPage/scenes/constants';
import {IChatChannel} from '@/shared/models/chatModel';
import {ISearchState} from '@/stores/SearchStateStore';
import {RoutePaths} from '@/shared/constants/route';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {ChatList} from '@/shared/styles/chatStyles';

interface IProps {
  chatId?: string;
  isFetchingMessages?: boolean;
}

const ChatListSection: FC<IProps> = ({chatId, isFetchingMessages}) => {
  const navigate = useNavigate();
  const searchState = useAppSelector(searchSelector);
  const currentUser = useAppSelector(userInfoSelector);
  const [prevSearchState, setPrevSearchState] = useState<ISearchState | null>(searchState);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE
  });

  const [chatList, setChatList] = useState<IChatChannel[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const chatsQuery = useMemo(() => {
    if (searchState !== prevSearchState) {
      setPaginationModel({page: 0, pageSize: PAGE_SIZE});
    }

    const page = {
      limit: paginationModel.pageSize,
      offset: paginationModel.page
    };

    const filters: {my_chats: string; is_notify: number; find_titles_and_messages?: string} = {
      my_chats: String(currentUser.id),
      is_notify: 0
    };

    if (searchState) {
      filters.find_titles_and_messages = searchState;
    }

    setPrevSearchState(searchState);

    const counters = {
      user_unread_chat_messages: currentUser.id
    };

    const params: IQueryArg = {
      page,
      filters,
      counters
    };

    return params;
  }, [currentUser, paginationModel.pageSize, paginationModel.page, searchState, prevSearchState]);

  const {
    data: chatData,
    isLoading: chatIsLoading,
    isFetching: chatIsFetching,
    refetch: refetchChatList
  } = useGetChatListQuery(chatsQuery, {
    skip: !chatsQuery,
    refetchOnMountOrArgChange: true,
    pollingInterval: 15000
  });

  const listRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (listRef.current && hasMore && !isLoadingMore) {
      const {scrollTop, scrollHeight, clientHeight} = listRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 0.5) {
        setIsLoadingMore(true);
        setPaginationModel(prev => ({
          ...prev,
          pageSize: prev.pageSize + PAGE_SIZE
        }));
      }
    }
  }, [listRef, hasMore, isLoadingMore]);

  const handleClickListItem = (newChatId?: string) => {
    const path = generatePath(RoutePaths.ChatPage, {
      chatId: `${newChatId}`
    });
    navigate(path);
  };

  useEffect(() => {
    const element = listRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (chatData?.data) {
      setChatList(chatData.data);
      setHasMore(chatData.data.length >= paginationModel.pageSize);
      setIsLoadingMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData, chatIsLoading]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [searchState, prevSearchState]);

  useEffect(() => {
    if (!chatIsFetching && !isFetchingMessages) {
      refetchChatList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingMessages]);

  useLinearLoading(isFetchingMessages || chatIsLoading || chatIsFetching || isLoadingMore);

  return (
    <ChatList ref={listRef}>
      <SearchField
        isStartAdornment={false}
        customRootStyles={{
          padding: '3%',
          position: 'sticky',
          top: -1,
          zIndex: 1
        }}
      />
      {chatList.map(chat => (
        <React.Fragment key={chat.id}>
          <List component="div" disablePadding>
            <ChatListItem
              title={chat.title}
              content={chat.latestChatMessage?.content}
              createdAt={chat.latestChatMessage?.createdAt}
              isTelegram={chat.isTelegram}
              isWhatsapp={chat.isWhatsapp}
              user={chat.latestChatMessage?.user || chat.latestChatMessage?.customer}
              unreadCount={chat.userUnreadChatMessagesCount}
              isHasFiles={chat.latestChatMessage?.isHasFiles}
              isSelected={Number(chatId) === chat.id}
              onClick={() => handleClickListItem(String(chat.id))}
            />
          </List>
        </React.Fragment>
      ))}
    </ChatList>
  );
};

export default ChatListSection;
