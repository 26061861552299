import React, {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Stack, Typography} from '@mui/material';

import ChatListSection from '@/scenes/ChatsPage/components/ChatListSection/ChatListSection';
import ChatMessageSection from '@/scenes/ChatsPage/components/ChatMessageSection/ChatMessageSection';
import ChatInfoSection from '@/scenes/ChatsPage/components/ChatInfoSection/ChatInfoSection';

interface IProps {}

const ChatsPage: FC<IProps> = () => {
  const {chatId} = useParams<{chatId?: string}>();

  const [isFetchingMessages, setIsFetchingMessages] = useState<boolean | undefined>(undefined);
  const [isOpenChatInfo, setIsOpenChatInfo] = useState<boolean>(false);

  useEffect(() => {
    setIsOpenChatInfo(false);
  }, [chatId]);

  return (
    <div className="scene" style={{display: 'flex', height: '100vh'}}>
      <header className="scene-header">
        <Typography variant="h1" gutterBottom={true} sx={{marginBottom: '2rem'}}>
          Чат
        </Typography>
      </header>
      <Stack direction="row" spacing={10} sx={{height: '92%'}}>
        <ChatListSection chatId={chatId} isFetchingMessages={isFetchingMessages} />
        <Stack
          direction="row"
          sx={{
            width: isOpenChatInfo ? '50%' : '75%',
            transition: 'width 0.3s'
          }}
        >
          {chatId && (
            <ChatMessageSection
              chatId={chatId}
              setIsFetchingMessages={setIsFetchingMessages}
              isOpenChatInfo={isOpenChatInfo}
              setIsOpenChatInfo={setIsOpenChatInfo}
            />
          )}
        </Stack>
        {isOpenChatInfo && <ChatInfoSection chatId={chatId} />}
      </Stack>
    </div>
  );
};

export default ChatsPage;
