import {api} from '@/stores/api/index';
import {IMailTemplate} from '@/shared/models/dictionaryModel';
import {ICommonArgs, ICommonResponse} from '@/shared/models/commonModel';

interface IQueryArg extends ICommonArgs {
  // 'filters[task_type_id]': number;
}

export const apiWithMailTemplates = api.injectEndpoints({
  endpoints: builder => ({
    getTemplates: builder.query<ICommonResponse<IMailTemplate[]>, IQueryArg>({
      query: params => ({
        url: '/v1/dictionaries/mailing_templates/',
        method: 'get',
        params: {
          ...params
        }
      })
    })
  }),
  overrideExisting: true
});

export const {useGetTemplatesQuery} = apiWithMailTemplates;
