import {deepmerge} from '@mui/utils';
import {ThemeOptions as TThemeOptions} from '@mui/material/styles';

import {themeOptions} from '@/theme';

const authOptions = {
  palette: {
    mode: 'dark',
    auth: {
      main: '#EEF0F3',
      contrastText: '#fff'
    }
  }
};

export const darkThemeOptions: TThemeOptions = deepmerge(themeOptions, authOptions);
