import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {logout} from '@/stores/AuthStore';

export interface ISearchState {
  search?: string;
  counter?: TCounter;
}

export type TCounter =
  | 'new_count'
  | 'overdue_count '
  | 'work_count'
  | 'unread_comments_count'
  | 'spectator_count'
  | undefined;

const initialState: ISearchState = {
  search: '',
  counter: undefined
};

export const searchStateSlice = createSlice({
  name: 'searchState',
  initialState,
  reducers: {
    setSearchState: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setCounterState: (state, action: PayloadAction<TCounter>) => {
      state.counter = action.payload;
    },
    resetState: () => {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(logout, () => {
      return initialState;
    });
  }
});

export const {setSearchState, setCounterState, resetState} = searchStateSlice.actions;

export const searchStateReducer = searchStateSlice.reducer;
