import {useMemo} from 'react';
import {createSelector} from '@reduxjs/toolkit';

import {useGetTaskByIdQuery} from '@/stores/api/task-page/task-page';
import {ITask, ITaskModel} from '@/shared/models/tasksDataModel';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {prepareDepartment} from '@/shared/utils/departmentUtils';

interface IProps {
  taskId?: string;
}

export const useTaskData = ({taskId}: IProps) => {
  const {unitsIds, typesIds, departmentsIds, sourcesIds, isReady} = usePrepareDict();

  const memoTaskData = useMemo(() => {
    return createSelector(
      (res: ITaskModel) => res,
      task => {
        if (!task) {
          return undefined;
        }

        return {
          ...task,
          department: prepareDepartment(departmentsIds[task.departmentId], unitsIds),
          user: {
            ...(task.user || {}),
            name: formatUserName(task?.user)
          },
          employee: {
            ...(task.employee || {}),
            name: formatUserName(task?.employee)
          },
          fromEmployee: {
            ...task.fromEmployee,
            name: formatUserName(task.fromEmployee)
          },
          type: task.taskTypeId ? typesIds[task.taskTypeId] : undefined,
          taskSource: task.taskSourceId ? sourcesIds[task.taskSourceId] : undefined,
          toCopyEmployees: (task.toCopyEmployees || []).map(item => {
            return {
              ...item,
              name: formatUserName(item)
            };
          })
        };
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const {data, isCanEdit, isLoading, isError, isSuccess} = useGetTaskByIdQuery(
    {taskId},
    {
      skip: !taskId || !isReady,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({data, isLoading, isError, isSuccess}) => ({
        isLoading,
        isError,
        isSuccess,
        // @ts-ignore
        data: memoTaskData(data?.data) as ITask,
        // запрещать редактировать информацию о задаче
        isCanEdit: data?.meta?.isCanEdit ?? true
      })
    }
  );

  return {
    data,
    isCanEdit,
    isLoading,
    isError,
    isSuccess
  };
};
