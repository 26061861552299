import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {logout} from '@/stores/AuthStore';

export interface IAppState {
  departmentNotDefinedId?: number;
  companyIndividualTypeId?: number;
}

const initialState: IAppState = {
  departmentNotDefinedId: undefined,
  companyIndividualTypeId: undefined
};

export const taskConditionsStateSlice = createSlice({
  name: 'taskConditionsState',
  initialState,
  reducers: {
    setDepartmentNotDefinedId: (state, action: PayloadAction<number | undefined>) => {
      state.departmentNotDefinedId = action.payload;
    },
    setIndividualTypeId: (state, action: PayloadAction<number | undefined>) => {
      state.companyIndividualTypeId = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(logout, () => {
      return initialState;
    });
  }
});

export const {setDepartmentNotDefinedId, setIndividualTypeId} = taskConditionsStateSlice.actions;

export const taskConditionsReducer = taskConditionsStateSlice.reducer;
