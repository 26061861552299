import {useEffect, useMemo} from 'react';

import {useGetParentTasksQuery} from '@/stores/api/main-page/create-subtasks';

interface IProps {
  companyId?: number;
  parentTaskId?: number;
  setValue: any;
}

export const useParentTasksLogic = ({companyId, parentTaskId, setValue}: IProps) => {
  const {data: parentTasks} = useGetParentTasksQuery({companyId});

  const filteredParentTasks = useMemo(() => {
    if (!parentTasks?.length) {
      return [];
    }
    return parentTasks.map(val => ({
      ...val,
      name: val.title
    }));
  }, [parentTasks]);

  /**
   * INIT STATE
   */

  const initParentTask = useMemo(() => {
    if (parentTaskId && parentTasks) {
      return parentTasks.find(o => o.id === parentTaskId);
    }
    return null;
  }, [parentTaskId, parentTasks]);

  useEffect(() => {
    if (initParentTask) {
      setValue('task', initParentTask);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParentTask]);

  return {
    filteredParentTasks
  };
};
