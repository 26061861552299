import React, {FC, useMemo} from 'react';
import {Chip} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import {deadlineColor, deadlineTime, EDeadlineColors} from '@/shared/utils/dateUtils';

interface IProps {
  deadlineAt?: string;
  createdAt?: string;
  doneAt?: string;
  componentType?: 'button' | 'chip';
}

interface ICompProps extends IProps {
  color?: any;
  label: string;
  sx: Record<string, string>;
  textColor?: string;
}

export const matchDeadlineTheme = {
  [EDeadlineColors.White]: 'black',
  [EDeadlineColors.Black]: 'white',
  [EDeadlineColors.Red]: 'white',
  [EDeadlineColors.None]: null
};

const DeadlineComponent: FC<ICompProps> = ({
  label,
  componentType,
  textColor = 'white',
  sx,
  ...rest
}) => {
  return componentType === 'button' ? (
    <Chip
      avatar={<AccessTimeIcon sx={{fill: textColor}} />}
      label={`До выполнения осталось: ${label}`}
      sx={{
        ...sx,
        height: 'auto',
        borderRadius: 1,
        '& .MuiChip-label': {
          display: 'block',
          whiteSpace: 'normal',
          paddingTop: '9px',
          paddingBottom: '9px'
        }
      }}
      {...rest}
    />
  ) : (
    <Chip label={label} {...rest} sx={sx} />
  );
};

const DeadlineChip: FC<IProps> = ({deadlineAt, createdAt, doneAt, componentType = 'button'}) => {
  const deadlineDelta = deadlineAt ? deadlineTime(deadlineAt, doneAt) : null;

  const bgColor = useMemo(() => {
    if (deadlineAt && createdAt) {
      return deadlineColor(deadlineAt, createdAt, doneAt);
    }
    return null;
  }, [createdAt, deadlineAt, doneAt]);

  const textColor: any = useMemo(() => {
    if (bgColor) {
      return matchDeadlineTheme[bgColor];
    }
    return 'black';
  }, [bgColor]);

  if (!deadlineDelta) {
    return null;
  }

  return (
    <DeadlineComponent
      textColor={textColor}
      sx={{pointerEvents: 'none', color: textColor, backgroundColor: bgColor || 'none'}}
      componentType={componentType}
      label={deadlineDelta}
    />
  );
};

export default DeadlineChip;
