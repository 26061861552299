import React from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  CircularProgress,
  InputAdornment,
  TextField,
  UseAutocompleteProps
} from '@mui/material';
import cn from 'classnames';

import {IFilterOption, IOption} from '@/shared/models/tasksFilterModel';
import {ReactComponent as ArrowIcon} from '@/assets/arrow.svg';
import {MetaListItem} from '@/components/MetaListItem';

type TComboProps<T> = Omit<IFilterOption<T>, 'fieldName'> & {
  variantInput?: 'outlined' | 'filled' | 'standard';
  required?: boolean;
  name?: string;
  inputClassName?: string;
};

const LIMIT_TAGS = 2;

const ComboBox = <T extends IOption>({
  fieldLabel,
  placeholder,
  isLoading = false,
  options = [],
  variantInput = 'outlined',
  required,
  name,
  inputClassName,
  ...rest
}: TComboProps<T> &
  Omit<AutocompleteProps<T, boolean, boolean, boolean>, 'renderInput'> &
  UseAutocompleteProps<T, boolean, boolean, boolean>) => {
  const underlineProps =
    variantInput === 'standard'
      ? {
          disableUnderline: true
        }
      : {};

  if (rest.disabled) {
    return (
      <div>
        <MetaListItem field={fieldLabel}>{rest.value?.name || '-'}</MetaListItem>
      </div>
    );
  }

  return (
    <MuiAutocomplete
      options={options}
      loading={isLoading}
      getOptionLabel={option => {
        return typeof option === 'string' ? option : option.name;
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id || value.id === 'select-all'}
      noOptionsText="Нет вариантов"
      loadingText="Загрузка..."
      size="small"
      limitTags={LIMIT_TAGS}
      renderOption={(props, option: T) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={params => (
        <div ref={params.InputProps.ref}>
          <TextField
            {...params}
            name={name}
            // @ts-ignore
            variant={variantInput}
            required={required}
            inputProps={{
              ...params.inputProps,
              className: cn(params.inputProps.className, inputClassName)
            }}
            InputProps={{
              ...params.InputProps,
              ...underlineProps,
              placeholder,
              startAdornment: fieldLabel && (
                <>
                  <InputAdornment position="start">
                    <span>{fieldLabel}: </span>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                </>
              )
            }}
          />
        </div>
      )}
      popupIcon={<ArrowIcon />}
      {...rest}
    />
  );
};

export default ComboBox;
