import React, {FC, memo, useEffect, useMemo} from 'react';
import cn from 'classnames';

import {ComboBox} from '@/components/UIKit';
import {IOption} from '@/shared/models/tasksFilterModel';
import {ITask} from '@/shared/models/tasksDataModel';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {
  setTypeError,
  taskDepartmentSelector,
  taskStatusSelector,
  taskTypeSelector,
  updateTaskStatus,
  updateType
} from '@/stores/TaskEditStore';
import {useGetTypesQuery} from '@/stores/api/filtered-dictionaries';
import {ISubtask} from '@/shared/models/subtaskModel';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {ISupportTask} from '@/shared/models/supportTaskModel';
import {checkTaskTypeMsg} from '@/shared/utils/taskUtils.ts';
import {ETaskStatuses} from '@/shared/constants/taskStatuses.ts';
import {spamTaskTypeName} from '@/shared/constants/taskTypes.ts';

interface IProps {
  task?: ITask | ISubtask | ISupportTask;
  isLoading?: boolean;
  isDisabled?: boolean;
  onChangeData: (params: Record<string, number>) => void;
}

const TaskTypeBox: FC<IProps> = ({task, isLoading, isDisabled, onChangeData}) => {
  const dispatch = useAppDispatch();
  const editedType = useAppSelector(taskTypeSelector);
  const editedDepartment = useAppSelector(taskDepartmentSelector);
  const editedStatus = useAppSelector(taskStatusSelector);
  const {typesIds: allTypesIds, statusesIds: allStatusesIds} = usePrepareDict();
  const [isValueChanged, setIsValueChanged] = React.useState(false);

  const isTask = task && 'taskComments' in task;
  const isSubtask = task && 'subtaskComments' in task;
  const isSupportTask = task && 'supportTaskComments' in task;
  const taskDirection = task && 'direction' in task ? task?.direction : false;

  const isProgressStatus = editedStatus?.id === ETaskStatuses.Progress;

  /**
   *    Получаем Список типов
   *    В карточке задачи:
   *  - Вставляем отдел задачи в фильтр в зависимости от direction задачи. Direction=True - filters[incoming_task_types], Dicrection=False - filters[outgoing_task_types]
   *    В создании подзадачи:
   *  - Вставляем выбранный отдел фильтр в filters[subtask_types]
   */
  const filterParam = useMemo(() => {
    if (isSupportTask) {
      // для техподдержки отдел не имеет значения
      return {
        'filters[is_support_task]': 1
      };
    }
    let param;
    if (isSubtask) {
      param = 'filters[subtask_types]';
    } else {
      param = taskDirection ? 'filters[incoming_task_types]' : 'filters[outgoing_task_types]';
    }

    return param
      ? {
          [param]: editedDepartment?.id
        }
      : {};
  }, [isSupportTask, isSubtask, editedDepartment?.id, taskDirection]);

  const {types, isTypeLoading} = useGetTypesQuery(filterParam, {
    selectFromResult: ({data, isLoading}) => ({
      types: data
        ? data.filter(type => {
            if (isSubtask) {
              return type.isSubtask && type.id !== editedType?.id;
            }

            if (isTask) {
              return type.isTask && type.id !== editedType?.id;
            }

            if (isSupportTask) {
              return type.isSupportTask && type.id !== editedType?.id;
            }

            return type.id !== editedType?.id;
          })
        : [],
      isTypeLoading: isLoading
    })
  });

  const initType = useMemo(() => {
    /**
     * При инициализации подставляем типы из полного списка
     * в отфильтрованной версии может не быть выбранного
     */
    if (!allTypesIds || !task?.taskTypeId) {
      return;
    }

    return allTypesIds[task.taskTypeId];
  }, [task?.taskTypeId, allTypesIds]);

  useEffect(() => {
    if (initType) {
      dispatch(updateType(initType));
    }
  }, [dispatch, initType]);

  useEffect(() => {
    isProgressStatus
      ? dispatch(setTypeError(checkTaskTypeMsg(editedType?.id, false)))
      : dispatch(setTypeError(null));
  }, [dispatch, editedType, isProgressStatus]);

  const handleChangeType = (value?: IOption) => {
    dispatch(updateType(value));

    if (value) {
      value.id !== task?.taskTypeId ? setIsValueChanged(true) : setIsValueChanged(false);

      const params: {task_type_id: number; task_status_id?: number} = {
        task_type_id: Number(value.id)
      };

      if (value.name === spamTaskTypeName) {
        dispatch(updateTaskStatus(allStatusesIds[ETaskStatuses.Closed]));

        params.task_status_id = ETaskStatuses.Closed;
      }

      dispatch(setTypeError(null));
      onChangeData(params);
    }
  };

  return (
    <ComboBox<IOption>
      options={types || []}
      variantInput="standard"
      fieldLabel="Тип задачи"
      loading={isLoading || isTypeLoading}
      sx={{
        minWidth: '400px'
      }}
      disableClearable
      value={editedType || null}
      // @ts-ignore
      onChange={(_, newValue: IOption) => {
        handleChangeType(newValue);
      }}
      placeholder="Выберите тип задачи"
      disabled={isDisabled}
      inputClassName={cn({'combo-box-changed': isValueChanged})}
    />
  );
};

export default memo(TaskTypeBox);
