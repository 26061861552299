import React, {FC, memo} from 'react';
import {Outlet} from 'react-router';
import {ToastContainer} from 'react-toastify';

import {ModalLoader} from '@/components/ModalLoader';
import {useAppSelector} from '@/stores/hooks';
import {getIsLoading} from '@/stores/AppStateStore/AppStateSelector';

import logo from './assets/guest-logo.png';
import s from './GuestLayout.module.css';

interface IProps {
  children?: React.ReactNode;
}

const GuestLayout: FC<IProps> = ({children}) => {
  const isLoading = useAppSelector(getIsLoading);

  return (
    <section className={s.container}>
      <div className={s.content}>
        <img className={s.logo} src={logo} alt="logo" />
        <div className={s.textWrapper}>
          <Outlet />
          {children}
        </div>
      </div>
      <ModalLoader visible={isLoading} />
      <ToastContainer />
    </section>
  );
};

export default memo(GuestLayout);
