import React, {forwardRef, useCallback, useEffect, useMemo, useState} from 'react';
import {FieldValues} from 'react-hook-form';

import {useDebounce} from '@/shared/hooks/useDebounce';
import {FormComboBox} from '@/components/Form/components/FormComboBox/index';
import {IFormComboBoxProps} from '@/components/Form/components/FormComboBox/FormComboBox';
import {IOption} from '@/shared/models/tasksFilterModel';
import {ICommonArgs} from '@/shared/models/commonModel';

interface IProps<O extends IOption, TField extends FieldValues>
  extends Omit<IFormComboBoxProps<O, TField>, 'options'> {
  getData: (query: ICommonArgs) => any;
  query: ICommonArgs;
}

const FormComboBoxComponent = <O extends IOption, TField extends FieldValues>({
  query,
  getData,
  isLoading,
  ...rest
}: IProps<O, TField>) => {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<IOption[]>([]);

  const loading = open && options.length === 0;

  const newQuery = useMemo(() => {
    if (inputValue) {
      return {
        ...query,
        find: inputValue
      };
    }

    return query;
  }, [inputValue, query]);

  /**
   * FETCH LIST
   */

  const loadData = useCallback(
    async (active = true) => {
      try {
        const response = await getData(newQuery);
        const responseData = 'data' in response ? response.data?.data : [];

        if (active) {
          setOptions([...responseData]);
        }
      } catch (err) {
        console.error('Ошибка загрузки списка');
      }
    },
    [getData, newQuery]
  );

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    loadData(active);

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  /**
   * Поиск через API
   */

  const handleChangeInput = (_event: React.SyntheticEvent, value: string, reason: string) => {
    if (reason === 'input' || (value && reason === 'reset')) {
      setInputValue(value);
    } else if (reason === 'clear') {
      setInputValue('');
    }
  };

  const debouncedValue = useDebounce(inputValue, 500);

  useEffect(() => {
    if (open) {
      loadData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <FormComboBox
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      inputValue={inputValue}
      onInputChange={handleChangeInput}
      filterOptions={(x: any) => x}
      isLoading={isLoading}
      {...rest}
    />
  );
};

const FormComboBoxWithConnect = forwardRef<HTMLInputElement, any>((props, ref) => {
  // @ts-ignore
  return <FormComboBoxComponent innerRef={ref} {...props} />;
});

FormComboBoxWithConnect.displayName = 'FormComboBoxWithConnect';

export default FormComboBoxWithConnect;
