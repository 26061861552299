export const snakeToPascal = (str = '') => {
  const arr = str.split('_');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1, arr[i].length);
  }

  arr[0] = arr[0].toLowerCase();
  return arr.join('') || str;
};

export const pascalToSnake = (key: string) => {
  const result = key.replace(/([A-Z])/g, ' $1');
  return result.split(' ').join('_').toLowerCase();
};

export function formatKeys<K extends object, T extends object>(data: K): T {
  const formattedData = {} as T;
  for (const key of Object.keys(data)) {
    const formattedKey = snakeToPascal(key);
    const prevValue = data[key as keyof typeof data];

    if (prevValue && typeof prevValue === 'object') {
      if (Array.isArray(prevValue)) {
        // @ts-ignore
        formattedData[formattedKey] = prevValue.map(val => {
          return typeof val === 'object' ? formatKeys(val) : val;
        });
      } else {
        // @ts-ignore
        formattedData[formattedKey] = formatKeys(prevValue);
      }
    } else {
      // @ts-ignore
      formattedData[formattedKey] = prevValue;
    }
  }
  return formattedData;
}

export const invalidatesFields =
  'user_id,employee_id,company_id,from_employee_id,to_copy_employees_ids,author_id';

export const hasInvalidatedChanges = (patch: any) => {
  const invalidatesFieldsArr = invalidatesFields.split(',');
  // если изменился любой первый элемент из массива invalidatesFields => инвалидируем кеш
  const index = Object.keys(patch).findIndex(val => {
    return invalidatesFieldsArr.includes(val);
  });

  return index > -1;
};
