import React, {FC, memo, useState, useMemo} from 'react';
import {GridSortModel} from '@mui/x-data-grid';

import {Table} from '@/components/Table';
import {PAGE_SIZE} from '@/scenes/AllTasksPage/scenes/constants';
import {TPaginationModel} from '@/shared/models/tableModel';
import useLinearLoading from '@/shared/hooks/useLinearLoading';

import {useColumns} from './hooks/useColumns';
import {useGetTasksData} from './hooks/useGetTasksData';
import {usePrepareTasksData} from './hooks/usePrepareTasksData';
import s from './TasksTable.module.css';

interface IProps {}

const TasksTable: FC<IProps> = () => {
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [paginationModel, setPaginationModel] = useState<TPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE
  });

  const {data, isLoading, isFetching} = useGetTasksData({
    paginationModel,
    setPaginationModel,
    sortModel
  });

  const columns = useColumns();

  const rowCount = useMemo(() => {
    if (data?.meta?.totalCount) {
      return data?.meta?.totalCount || 0;
    }
    return 0;
  }, [data?.meta]);

  const {data: tableData} = usePrepareTasksData(data?.data);

  useLinearLoading(isFetching);
  return (
    <div className={s.wrapper}>
      {/*<Stack direction="row" spacing={1} sx={{ mb: 1 }}>*/}
      {/*  <Button size="small" onClick={handleToggleColumns}>*/}
      {/*    Колонки*/}
      {/*  </Button>*/}
      {/*</Stack>*/}
      <Table
        columns={columns}
        rows={tableData}
        rowCount={rowCount}
        pagination
        paginationMode="server"
        sortingMode="server"
        pageSizeOptions={[PAGE_SIZE]}
        loading={isLoading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        keepNonExistentRowsSelected
        sortModel={sortModel}
        onSortModelChange={newSortModel => setSortModel(newSortModel)}
      />
    </div>
  );
};

export default memo(TasksTable);
