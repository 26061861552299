import React, {FC, memo, useState} from 'react';
import {Button, useTheme} from '@mui/material';
import {addDays, format, parseISO} from 'date-fns';

import {TaskStatusNames, ETaskStatuses} from '@/shared/constants/taskStatuses';
import {DelayTaskConfirmation} from '@/modals/confirmation/DelayTaskConfirmation';
import {DateRangePicker} from '@/components/UIKit/DateRangePicker';

interface IProps {
  taskStatus: ETaskStatuses;
  delayAt: string | null | undefined;
  selectedDateDelay?: Date | null;
  onChangeStatus?: (status: number) => void;
  setSelectedDateDelay?: React.Dispatch<React.SetStateAction<Date | null>>;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const style = {
  minWidth: 150,
  maxWidth: 180,
  height: 40,
  bgcolor: 'background.paper',
  '&.Mui-disabled': {
    color: 'black'
  }
};

const TaskStatusButton: FC<IProps> = ({
  taskStatus,
  delayAt = null,
  selectedDateDelay = null,
  setSelectedDateDelay,
  onChangeStatus
}) => {
  const theme = useTheme();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isDelayConfirmationOpen, setIsDelayConfirmationOpen] = useState(false);

  const statusMessage = TaskStatusNames[taskStatus] || 'Загружается...';
  const isClickable = taskStatus === ETaskStatuses.Delay;

  const formattedDelayAt = delayAt ? format(parseISO(delayAt), 'dd.MM.yyyy') : '';
  const handleCalendarOpen = () => {
    setIsCalendarOpen(true);
  };

  const handleDateDelayChange = (val: Value) => {
    const newDateDelay = Array.isArray(val) ? val[0] : val;
    if (setSelectedDateDelay && (newDateDelay instanceof Date || newDateDelay === null)) {
      setSelectedDateDelay(newDateDelay);
    }
    setIsDelayConfirmationOpen(true);
  };

  return (
    <>
      {isDelayConfirmationOpen && onChangeStatus && (
        <DelayTaskConfirmation
          open={isDelayConfirmationOpen}
          onChangeStatus={onChangeStatus}
          delayData={selectedDateDelay}
          setIsDelayConfirmationOpen={setIsDelayConfirmationOpen}
        />
      )}
      <div>
        <Button
          variant="text"
          color="inherit"
          onClick={handleCalendarOpen}
          disabled={!isClickable}
          sx={{
            ...style,
            [theme.breakpoints.down('lg')]: {
              minWidth: 0,
              paddingLeft: 4,
              paddingRight: 4
            }
          }}
        >
          {statusMessage} {isClickable && `до ${formattedDelayAt}`}
        </Button>
        {isCalendarOpen && (
          <DateRangePicker
            placeholder="Выберите дату"
            start={undefined}
            end={selectedDateDelay ? format(selectedDateDelay, 'yyyy-MM-dd') : undefined}
            isOpenStraightaway
            singleDate
            isDelayTask
            setIsCalendarOpen={setIsCalendarOpen}
            minDate={addDays(new Date(), 1)}
            handleDateDelayChange={handleDateDelayChange}
          />
        )}
      </div>
    </>
  );
};

export default memo(TaskStatusButton);
