import React, {FC} from 'react';

import {useUpdateUrlParams} from '@/scenes/AllTasksPage/hooks/useUpdateUrlParams';
import {TFilterValues} from '@/shared/models/tasksFilterModel.ts';
import {useAppDispatch, useAppSelector} from '@/stores/hooks.ts';
import {tasksFilterValuesSelector} from '@/stores/TasksFilterStore/TasksFilterStateSelector.ts';
import {resetFilterValues, updateValueByKey} from '@/stores/TasksFilterStore';

import {TasksFilters} from '../../components/TasksFilters/index';

import {TasksTable} from './components/TasksTable/index';

interface IProps {}

const Tasks: FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const filterValues: TFilterValues = useAppSelector(tasksFilterValuesSelector);

  const handleUpdateFilters = ({key, value}: any) => {
    dispatch(updateValueByKey({key, value}));
  };

  const handleResetFilters = () => {
    dispatch(resetFilterValues());
  };

  useUpdateUrlParams(filterValues, handleUpdateFilters, handleResetFilters);

  return (
    <>
      <TasksFilters />
      <TasksTable />
    </>
  );
};

export default Tasks;
