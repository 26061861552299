import React, {FC, memo, useState} from 'react';
import {Button, CircularProgress} from '@mui/material';

import {useAppSelector} from '@/stores/hooks';
import {fileStorageTokenSelector} from '@/stores/FileStorageStore';
import {downloadAllFiles} from '@/shared/utils/downloadFiles';
import {ReactComponent as DownloadIcon} from '@/assets/cloud-download.svg';
import {generateAllSubtaskFilesPath} from '@/shared/utils/fileUtils';

interface IProps {
  taskId: string;
}

const DownloadAllButton: FC<IProps> = ({taskId}) => {
  const [isLoading, setIsLoading] = useState(false);

  const s3Token = useAppSelector(fileStorageTokenSelector);

  const handleGetFiles = async () => {
    setIsLoading(true);
    const path = generateAllSubtaskFilesPath(taskId);
    await downloadAllFiles(taskId, s3Token, path);
    setIsLoading(false);
  };

  return (
    <Button
      startIcon={isLoading ? <CircularProgress color="inherit" size={24} /> : <DownloadIcon />}
      variant="text"
      onClick={handleGetFiles}
      disabled={isLoading}
    >
      {isLoading ? 'Скачивание архива...' : 'Скачать все файлы'}
    </Button>
  );
};

export default memo(DownloadAllButton);
