import React, {FC, memo, useState} from 'react';
import {Button, CircularProgress} from '@mui/material';
import cn from 'classnames';

import {useAppSelector} from '@/stores/hooks';
import {fileStorageTokenSelector} from '@/stores/FileStorageStore';
import {generateMessageFilesPath} from '@/shared/utils/fileUtils';
import {downloadAllFiles} from '@/shared/utils/downloadFiles';
import {ReactComponent as DownloadIcon} from '@/assets/cloud-download.svg';
import {IFile} from '@/shared/models/fileModel';

import s from './ChatFilesDownloadButton.module.css';

interface IProps {
  fileName: string;
  file: IFile;
  className?: string;
}

const ChatFilesDownloadButton: FC<IProps> = ({className, file, fileName}) => {
  const [isLoading, setIsLoading] = useState(false);

  const s3Token = useAppSelector(fileStorageTokenSelector);

  const handleGetFiles = async () => {
    setIsLoading(true);
    const path = generateMessageFilesPath(file);
    await downloadAllFiles(fileName, s3Token, path);
    setIsLoading(false);
  };

  return (
    <Button
      className={cn(s.button, className)}
      startIcon={isLoading ? <CircularProgress color="inherit" size={24} /> : <DownloadIcon />}
      variant="text"
      onClick={handleGetFiles}
      disabled={isLoading}
    >
      {isLoading ? 'Скачивание архива...' : 'Скачать файлы'}
    </Button>
  );
};

export default memo(ChatFilesDownloadButton);
