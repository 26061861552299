import React, {FC, memo, PropsWithChildren} from 'react';
import {Link} from 'react-router-dom';

import {RoutePaths} from '@/shared/constants/route';

import s from './MetaListItem.module.css';

interface IProps extends PropsWithChildren {
  field?: string;
  title?: string;
  name?: string;
  isLink?: boolean;
  linkTo?: string;
  isEmail?: boolean;
}

const MetaListItem: FC<IProps> = ({title, field, name, isLink, isEmail, children, linkTo}) => {
  const renderValue = () => {
    if (isLink && name) {
      return (
        <Link to={`${RoutePaths.Main}?${name}=${title}`} className="link">
          {children}
        </Link>
      );
    }

    if (linkTo && title) {
      return (
        <Link to={linkTo} target="_blank" rel="noopener noreferrer" className="link">
          {children}
        </Link>
      );
    }

    if (isEmail) {
      return <span className="link">{children}</span>;
    }
    return children;
  };

  return (
    <div className={s.metaListItem}>
      {field && <span>{field}:&nbsp;</span>}
      <span title={title ?? `${children}`} className={s.metaListItemValue}>
        {renderValue()}
      </span>
    </div>
  );
};

export default memo(MetaListItem);
