import React, {forwardRef} from 'react';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import cn from 'classnames';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Checkbox, CircularProgress, FilterOptionsState} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import {IOption} from '@/shared/models/tasksFilterModel';
import {ReactComponent as ArrowIcon} from '@/assets/arrow.svg';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface IFormComboBoxProps<O extends IOption, TField extends FieldValues> {
  id?: string;
  control: Control<TField>;
  name: Path<TField>;
  options: O[];
  placeholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  required?: boolean;
  renderOption?: (props: object, option: O | O[], state?: object) => React.ReactNode;
  filterOptions?: (options: O[], state: FilterOptionsState<O>) => O[];
  inputClassName?: string;
  multiple?: boolean;
}

const FormComboBoxComponent = <O extends IOption, TField extends FieldValues>(
  props: IFormComboBoxProps<O, TField> & {innerRef: React.Ref<HTMLInputElement> | null}
) => {
  const {
    innerRef,
    control,
    options = [],
    name,
    isLoading,
    isDisabled,
    id,
    required = false,
    renderOption,
    filterOptions,
    inputClassName,
    multiple = false,
    ...rest
  } = props;

  // mui autocomplete некоррект но работает с disable
  if (isDisabled) {
    return (
      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: required,
            message: 'Обязательное поле'
          }
        }}
        render={({field}) => {
          const {value} = field;
          return (
            <TextField
              ref={innerRef}
              placeholder={props.placeholder}
              disabled={isDisabled}
              value={value?.name}
              sx={{
                pt: '0.6rem',
                pb: '0.6rem',
                display: 'flex'
              }}
              inputProps={{
                className: inputClassName
              }}
              InputProps={{
                endAdornment: <ArrowIcon />
              }}
            />
          );
        }}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: data => {
          if (required) {
            if (data.length === 0) return false;
          }
          return true;
        },
        required: {
          value: required,
          message: 'Обязательное поле'
        }
      }}
      render={({field, fieldState: {error}}) => {
        const {onChange, value, ref} = field;

        return (
          <Autocomplete
            id={id}
            color="secondary"
            size="small"
            loading={isLoading}
            disabled={isDisabled}
            noOptionsText="Нет вариантов"
            loadingText="Загрузка..."
            renderOption={(props, option, selectedProps) => {
              if (renderOption) {
                return renderOption(props, option, selectedProps);
              }
              return (
                <li {...props} key={option.id}>
                  {multiple && (
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selectedProps.selected}
                    />
                  )}
                  {option.name}
                </li>
              );
            }}
            value={multiple ? value || [] : value || null}
            getOptionLabel={option => {
              // @ts-ignore
              return typeof option === 'string' ? option : option.name || option.title;
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, newValues) => {
              return onChange(newValues);
            }}
            options={options}
            popupIcon={<ArrowIcon />}
            sx={{
              endAdornment: {
                top: 'calc(50% - 10px)'
              }
            }}
            filterOptions={filterOptions}
            multiple={multiple}
            renderTags={(values: IOption[], _getTagProps) => {
              return values.map((value, index: number) => {
                if (value && typeof value === 'object' && 'name' in value) {
                  const {name} = value;
                  return name + (index < values.length - 1 ? ', ' : '');
                }
              });
            }}
            renderInput={params => (
              <TextField
                ref={innerRef}
                {...params}
                placeholder={props.placeholder}
                inputRef={ref}
                disabled={isDisabled}
                error={!!error}
                helperText={error?.message}
                inputProps={{
                  ...params.inputProps,
                  className: cn(params.inputProps.className, inputClassName)
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    </>
                  )
                }}
              />
            )}
            {...rest}
          />
        );
      }}
    />
  );
};

const FormComboBox = forwardRef<HTMLInputElement, any>((props, ref) => {
  // @ts-ignore
  return <FormComboBoxComponent innerRef={ref} {...props} />;
});

FormComboBox.displayName = 'FormComboBox';

export default FormComboBox;
