import {api} from '@/stores/api/index';
import {ILogin} from '@/shared/models/authModel';

interface ILoginResponse {
  access_token: string;
  refresh_token: string;
  token_type: 'bearer';
}

export const apiWithAuth = api.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<ILogin, FormData>({
      query: (data: FormData) => ({
        url: '/v1/auth/login',
        method: 'post',
        data,
        options: {
          skipAuth: true,
          skipMessage: true
        }
      }),

      transformResponse(returnValue?: ILoginResponse): ILogin | Promise<ILogin> {
        if (!returnValue) {
          return {
            accessToken: undefined,
            refreshToken: undefined
          };
        }
        return {
          accessToken: returnValue?.access_token,
          refreshToken: returnValue?.refresh_token
        };
      }
    })
  }),
  overrideExisting: true
});

export const {useLoginMutation} = apiWithAuth;
