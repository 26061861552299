import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/stores';
import {
  EFilterName,
  IFilterOption,
  IOption,
  TFilterValues,
  TTempFilterValues
} from '@/shared/models/tasksFilterModel';

export const supportTasksFilterSelector = (state: RootState) => {
  return state.supportTasksFilter.filterList as Record<EFilterName, IFilterOption<IOption>>;
};

export const tempSupportTasksFilterValuesSelector = (state: RootState) => {
  return state.supportTasksFilter.tempFilterValues as TTempFilterValues;
};

export const supportTasksFilterValuesSelector = (state: RootState) => {
  return state.supportTasksFilter.filterValues;
};

export const supportTasksFilterIsFetchSelector = (state: RootState) => {
  return state.supportTasksFilter.isFetched;
};

export const supportTasksFilterIsUpdatedSelector = (state: RootState) => {
  return state.supportTasksFilter.isUpdated;
};

export const supportTasksFilterHasValuesSelector = createSelector(
  supportTasksFilterValuesSelector,
  (values?: TFilterValues) => {
    if (!values) {
      return false;
    }
    return Object.entries(values).filter(([_key, val]) => !!val).length > 0;
  }
);
