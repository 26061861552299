import React, {FC} from 'react';
import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';

import {ReactComponent as ArrowIcon} from '@/assets/arrow.svg';

import s from './TableToolbar.module.css';

interface IProps {}

const TableToolbar: FC<IProps> = () => {
  return (
    <GridToolbarContainer className={s.toolbar}>
      <GridToolbarColumnsButton color={'secondary'} startIcon={null} endIcon={<ArrowIcon />} />
      <GridToolbarExport
        startIcon={null}
        color={'success'}
        csvOptions={{
          utf8WithBom: true
        }}
      />
    </GridToolbarContainer>
  );
};

export default TableToolbar;
