import React, {FC, useState} from 'react';
import {Grid, Tab, Tabs, Typography} from '@mui/material';
import {Outlet, useLocation} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {RoutePaths} from '@/shared/constants/route';
import {CreateButtons} from '@/scenes/AllTasksPage/components/CreateButtons';
import {SearchField} from '@/components/SearchField';
import {useCreateChipsCountsQuery} from '@/stores/api/main-page/create-chips-counts';
import {TCounter, setCounterState} from '@/stores/SearchStateStore';

import TasksCounters from './components/TasksCounters/TasksCounters';
import s from './AllTasksPage.module.css';

interface IProps {}

const AllTasksPage: FC<IProps> = () => {
  const location = useLocation();
  const {pathname} = location;
  const {data: chipsCounts} = useCreateChipsCountsQuery(undefined, {
    pollingInterval: 40000
  });

  const dispatch = useDispatch();
  const [selectedChips, setSelectedChips] = useState<{[key: string]: TCounter | null}>({
    [RoutePaths.Main]: null,
    [RoutePaths.MainSubtasks]: null,
    [RoutePaths.MainTechSupport]: null
  });

  const handleTabChange = () => {
    dispatch(setCounterState(undefined));
    setSelectedChips(prev => ({...prev, [pathname]: null}));
  };

  return (
    <div className="scene">
      <header className="scene-header">
        <Typography variant="h1" gutterBottom={true} sx={{marginBottom: '2rem'}}>
          Панель задач
        </Typography>
        <SearchField
          placeholder="Поиск задачи"
          isStartAdornment={true}
          customRootStyles={{maxWidth: '22%', minWidth: '180px'}}
        />
      </header>
      <Grid
        container
        sx={{
          borderBottom: 1,
          borderColor: '#DCE1E9',
          marginBottom: '1.7rem'
        }}
      >
        <Grid item xs={12} sm={6} md={8}>
          <Tabs value={pathname} onChange={handleTabChange}>
            <Tab
              label="Задача"
              component={RouterLink}
              to={{
                pathname: RoutePaths.Main,
                search: ''
              }}
              className={s.tab}
              value={RoutePaths.Main}
            />
            <TasksCounters
              dataCounter={chipsCounts?.tasks}
              tabPath={RoutePaths.Main}
              currentTab={pathname}
              setSelectedChip={chip =>
                setSelectedChips(prev => ({...prev, [RoutePaths.Main]: chip}))
              }
              selectedChip={selectedChips[RoutePaths.Main]}
            />
            <Tab
              label="Подзадача"
              component={RouterLink}
              to={{
                pathname: RoutePaths.MainSubtasks,
                search: ''
              }}
              className={s.tab}
              value={RoutePaths.MainSubtasks}
            />
            <TasksCounters
              dataCounter={chipsCounts?.subtasks}
              tabPath={RoutePaths.MainSubtasks}
              currentTab={pathname}
              setSelectedChip={chip =>
                setSelectedChips(prev => ({...prev, [RoutePaths.MainSubtasks]: chip}))
              }
              selectedChip={selectedChips[RoutePaths.MainSubtasks]}
            />
            <Tab
              label="Техподдержка"
              component={RouterLink}
              to={{
                pathname: RoutePaths.MainTechSupport,
                search: ''
              }}
              className={s.tab}
              value={RoutePaths.MainTechSupport}
            />
            <TasksCounters
              dataCounter={chipsCounts?.supportTasks}
              tabPath={RoutePaths.MainTechSupport}
              currentTab={pathname}
              setSelectedChip={chip =>
                setSelectedChips(prev => ({...prev, [RoutePaths.MainTechSupport]: chip}))
              }
              selectedChip={selectedChips[RoutePaths.MainTechSupport]}
              isSupportTab
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CreateButtons />
        </Grid>
      </Grid>
      <Outlet />
    </div>
  );
};

export default AllTasksPage;
