import React, {FC, SyntheticEvent, useState} from 'react';
import {Backdrop, Box, Button, Grid, Modal, TextField, Typography} from '@mui/material';

interface IProps {
  open: boolean;
  onSubmitTime: (value: string) => void;
  onClose: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 434,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const TimeSpend: FC<IProps> = ({open, onClose, onSubmitTime}) => {
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');

  const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const leadTime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    onSubmitTime(leadTime);
    onClose();
  };

  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    if (value === '' || (/^\d{0,2}$/.test(value) && parseInt(value, 10) <= 99)) {
      setHours(value);
    }
  };

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    if (value === '' || (/^\d{0,2}$/.test(value) && parseInt(value, 10) <= 99)) {
      setMinutes(value);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="Перевод в другой отдел"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <form action="" onSubmit={handleSubmit}>
          <Typography variant="h2">Потраченное время</Typography>
          <Grid container spacing={6} sx={{pt: 8, pb: 8}} justifyContent="center">
            <Grid item xs={3}>
              <TextField
                label="Часы"
                variant="outlined"
                fullWidth
                margin="normal"
                value={hours}
                onChange={handleHoursChange}
                inputProps={{maxLength: 2}}
                placeholder="00"
                InputLabelProps={{shrink: true}}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Минуты"
                variant="outlined"
                fullWidth
                margin="normal"
                value={minutes}
                onChange={handleMinutesChange}
                inputProps={{maxLength: 2}}
                placeholder="00"
                InputLabelProps={{shrink: true}}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={onClose}
                sx={{minWidth: 140}}
                disabled={false}
              >
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={hours === '' && minutes === ''}
                sx={{minWidth: 140}}
              >
                Списать
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default TimeSpend;
