import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {logout} from '@/stores/AuthStore';

export interface IAppState {
  isLoading: boolean;
  isLinearLoading: boolean;
}

const initialState: IAppState = {
  isLoading: false,
  isLinearLoading: false
};

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsLinearLoading: (state, action: PayloadAction<boolean>) => {
      state.isLinearLoading = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(logout, () => {
      return initialState;
    });
  }
});

export const {setIsLoading, setIsLinearLoading} = appStateSlice.actions;

export const appStateReducer = appStateSlice.reducer;
