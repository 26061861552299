import {api} from '@/stores/api';
import {ITaskModel, ITaskResponse} from '@/shared/models/tasksDataModel.ts';
import {
  ICommonArgs,
  ICommonResponse,
  IMeta,
  IMetaResponse,
  IPagination
} from '@/shared/models/commonModel.ts';
import {formatKeys} from '@/shared/utils/responseUtils.ts';
import {IMessage} from '@/shared/models/messageModel.ts';

interface IQueryArg extends ICommonArgs {}

interface IAddTaskCommentsChatArg {
  taskId: string;
  chatId: number;
  chatMessageIds: number[];
}

interface IFileArg {
  name: string;
  file: string;
}

interface ICreateTaskChatArg {
  chat_id: number;
  chat_message_ids: number[];
  title: string;
  company_id: number | string;
  employee_id: number | string;
  task_type_id: number | string;
  task_status_id: number | string;
  unit_id: number | string;
  user_id: number | string;
  department_id: number | string;
  inner_task_comment?: {
    content: string;
    attachments?: IFileArg[];
  };
  delay_at: string;
  delay_task_comment?: {
    content: string;
  };
}

export const apiWithChatPage = api.injectEndpoints({
  endpoints: builder => ({
    getCompanyActiveTasks: builder.query<IPagination<ITaskModel[]>, IQueryArg>({
      query: params => ({
        url: '/v1/tasks/',
        method: 'get',
        params: {
          fields: 'id,created_at,task_status_id,ticket,title',
          sort: 'active_tasks',
          ...params
        }
      }),
      transformResponse: (result: ICommonResponse<ITaskResponse[]>): IPagination<ITaskModel[]> => {
        return {
          meta: formatKeys<IMetaResponse, IMeta>(result.meta),
          data: result.data.map(item => {
            return formatKeys<ITaskResponse, ITaskModel>(item);
          })
        };
      },
      // @ts-ignore
      providesTags: result => {
        return result && result?.data
          ? [...result.data.map(({id}) => ({type: 'TASKS', id})), {type: 'TASKS', id: 'LIST'}]
          : [{type: 'TASKS', id: 'LIST'}];
      }
    }),
    fetchActiveTasks: builder.mutation<IPagination<ITaskModel[]>, IQueryArg>({
      query: params => ({
        url: '/v1/tasks/',
        method: 'get',
        params: {
          fields: 'id,created_at,task_status_id,ticket,title',
          sort: 'active_tasks',
          ...params
        }
      }),
      transformResponse: (result: ICommonResponse<ITaskResponse[]>): IPagination<ITaskModel[]> => {
        return {
          meta: formatKeys<IMetaResponse, IMeta>(result.meta),
          data: result.data.map(item => {
            return formatKeys<ITaskResponse, ITaskModel>(item);
          })
        };
      }
    }),
    createTaskChat: builder.mutation<ITaskResponse, ICreateTaskChatArg>({
      query: (data: ICreateTaskChatArg) => ({
        url: '/v1/tasks/chat',
        method: 'post',
        data
      }),
      invalidatesTags: [{type: 'TASKS', id: 'LIST'}]
    }),
    addTaskCommentsChat: builder.mutation<IMessage[], IAddTaskCommentsChatArg>({
      query: ({taskId, chatId, chatMessageIds}) => ({
        url: `/v1/tasks/${taskId}/task_comments/chat`,
        method: 'post',
        data: {
          chat_id: chatId,
          chat_message_ids: chatMessageIds
        }
      }),
      // @ts-ignore
      invalidatesTags: (_result, _error, id) => {
        return [{type: 'TASK', id}];
      }
    })
  }),
  overrideExisting: true
});

export const {
  useGetCompanyActiveTasksQuery,
  useFetchActiveTasksMutation,
  useAddTaskCommentsChatMutation,
  useCreateTaskChatMutation
} = apiWithChatPage;
