import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/stores';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {IUser} from '@/shared/models';
import {IDepartment} from '@/shared/models/dictionaryModel';
import {ICompany} from '@/shared/models/companyModel';
import {checkCompanyIndividual} from '@/shared/utils/companyUtils.ts';
import {companyIndividualTypeIdSelector} from '@/stores/TaskConditionsStore/TaskConditionsSelector.ts';

export const taskCompanySelector = (state: RootState) => {
  return state.taskSettingsState.company.data as ICompany;
};

export const taskEmployeeListSelector = createSelector(taskCompanySelector, company => {
  if (!company || !company?.employees?.length) {
    return [] as IUser[];
  }

  return (company.employees || []).map((val: IUser) => ({
    ...val,
    name: formatUserName(val)
  })) as IUser[];
});

export const taskEmployeeSelector = (state: RootState) => {
  return state.taskSettingsState.employee.data as IUser;
};

export const taskDepartmentSelector = (state: RootState) => {
  return state.taskSettingsState.department.data as IDepartment;
};

export const taskUserSelector = (state: RootState) => {
  return state.taskSettingsState.user.data as IUser;
};

export const taskTypeSelector = (state: RootState) => {
  return state.taskSettingsState.taskType.data;
};

/**
 * Errors
 * @param state
 */
export const taskEmployeeErrorSelector = (state: RootState) => {
  return state.taskSettingsState.employee.error;
};

export const taskCompanyErrorSelector = (state: RootState) => {
  return state.taskSettingsState.company.error;
};

export const taskTypeErrorSelector = (state: RootState) => {
  return state.taskSettingsState.taskType.error;
};

/**
 * Send to all
 * @param state
 */
export const toCopyEmployeesSelector = (state: RootState) => {
  return state.taskSettingsState.toCopyEmployees || [];
};

export const toCopyEmployeesIdsSelector = createSelector(toCopyEmployeesSelector, items => {
  if (!items) {
    return [];
  }
  return items.map((val: IUser) => val.id);
});

export const isIndividualCompanySelector = createSelector(
  companyIndividualTypeIdSelector,
  taskCompanySelector,
  (companyIndividualType, company) => {
    if (!company && !companyIndividualType) {
      return false;
    }
    return checkCompanyIndividual(companyIndividualType, company?.companyTypeId, company?.name);
  }
);

export const taskErrorsSelector = createSelector(
  taskTypeErrorSelector,
  taskCompanyErrorSelector,
  taskEmployeeErrorSelector,
  (type, company, employee) => {
    return [type, company, employee].filter(Boolean);
  }
);
