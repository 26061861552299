import React, {forwardRef, useMemo} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {Box, Button, Grid, Paper, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import cn from 'classnames';

import {FormComboBoxWithConnect} from '@/components/Form/components/FormComboBox';
import {ITask} from '@/shared/models/tasksDataModel';
import useLoading from '@/shared/hooks/useLoading';
import s from '@/components/Chat/Chat.module.css';
import {ChatMessage} from '@/components/Chat/components/ChatMessage';
import {IQueryArg} from '@/stores/api/chats.ts';
import {IChat, IChatMessage} from '@/shared/models/chatModel.ts';
import {
  useAddTaskCommentsChatMutation,
  useFetchActiveTasksMutation
} from '@/stores/api/chat-page/tasks.ts';
import {useGetCompaniesQuery} from '@/stores/api/companies.ts';
import {useAppSelector} from '@/stores/hooks.ts';
import {fileStorageTokenSelector} from '@/stores/FileStorageStore';
import {IFile} from '@/shared/models/fileModel.ts';
import {downloadFile} from '@/shared/utils/downloadFiles.ts';
import {IMessage} from '@/shared/models/messageModel.ts';

type FormValues = {
  task: ITask;
};

interface IProps {
  onClose: () => void;
  onSubmit: () => void;
  chatData?: IChat;
  selectedChatMessages: IChatMessage[];
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 786,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 12,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const AddTaskChatMessage = forwardRef<HTMLInputElement, IProps>((props, _ref) => {
  const {onClose, onSubmit, chatData, selectedChatMessages} = props;

  const s3Token = useAppSelector(fileStorageTokenSelector);

  const handleGetFile = async (file: IFile) => {
    await downloadFile(file, s3Token);
  };

  const [submitAddTaskCommentsChat, {isLoading: isAddLoading, isError}] =
    useAddTaskCommentsChatMutation();

  const {
    control,
    handleSubmit,
    formState: {errors}
  } = useForm<FormValues>({});

  // @ts-ignore
  const onClickSubmit: SubmitHandler<FormValues | undefined> = async data => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    if (chatData?.id && data) {
      const chatMessageIds = new Set(selectedChatMessages.map(message => message.id));
      const uniqueMessageIdsArray = Array.from(chatMessageIds);

      const requestData = {
        taskId: String(data.task.id),
        chatId: Number(chatData?.id),
        chatMessageIds: uniqueMessageIdsArray
      };

      const result: {data: IMessage[]} | {error: unknown} = await submitAddTaskCommentsChat(
        requestData
      );

      if ('data' in result && result?.data) {
        onSubmit();
      }
    }
  };

  useLoading(isAddLoading);

  const companiesFilters = useMemo(() => {
    const filters: {id?: string; has_customer?: string} = {};

    if (chatData?.isBot) {
      filters.has_customer = String(chatData?.chatCustomers?.[0].customerId);
    } else {
      filters.id = String(chatData?.companyId);
    }

    return filters;
  }, [chatData]);

  /**
   * СПИСОК КЛИЕНТОВ
   */
  const {data: companiesData} = useGetCompaniesQuery(
    {
      filters: companiesFilters
    },
    {
      skip: !companiesFilters
    }
  );

  /**
   * СПИСОК ЗАДАЧ
   */
  const tasksQuery = useMemo(() => {
    const page = {
      limit: 20,
      offset: 0
    };

    const companyIds = new Set(companiesData?.data.map(company => company.id));

    const filters = {
      company_id: 'in|' + [...companyIds].filter(Boolean).join(',') || '0',
      new_and_work_tasks: 1
    };

    const params: IQueryArg = {
      page,
      filters
    };

    return params;
  }, [companiesData]);

  const [fetchActiveTasks, {isLoading: isTasksLoading}] = useFetchActiveTasksMutation();

  return (
    <Box sx={style}>
      <form onSubmit={handleSubmit(onClickSubmit)}>
        <Grid container spacing={12} sx={{marginBottom: 12}}>
          <Grid item xs={12}>
            <Stack justifyContent="flex-end" sx={{height: '100%'}}>
              <label htmlFor="taskType">
                Задача <span className="imp">*</span>
              </label>
              <FormComboBoxWithConnect
                id="task"
                name="task"
                required
                isLoading={isTasksLoading}
                control={control}
                placeholder="Выберите задачу, к которой нужно прикрепить сообщение"
                getData={fetchActiveTasks}
                query={tasksQuery}
                renderOption={({className, ...props}: any, option: ITask) => {
                  return (
                    <li {...props} className={cn(className)} key={option.id}>
                      {option.title}
                    </li>
                  );
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{maxHeight: '70vh', marginBottom: 12}}>
            <label htmlFor="content">
              Описание задачи <span className="imp">*</span>
            </label>
            <Paper className={s.chat} sx={{height: '100%', overflowY: 'auto'}}>
              <div className={s.inner}>
                <ul className={s.messages}>
                  {selectedChatMessages &&
                    selectedChatMessages.length > 0 &&
                    selectedChatMessages.map(message => (
                      <li style={{listStyleType: 'none'}} key={message.id}>
                        <ChatMessage
                          key={message.id}
                          message={message}
                          onGetFile={handleGetFile}
                          isDisplaySystemComments={false}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onClose}
            sx={{minWidth: 154, borderWidth: 2}}
          >
            Отмена
          </Button>
          {isError && <span className="error">Ошибка создания задачи</span>}
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isAddLoading}
            loadingIndicator="Добавление…"
            sx={{minWidth: 154}}
            disabled={isAddLoading || isError}
          >
            Добавить
          </LoadingButton>
        </Grid>
      </form>
    </Box>
  );
});

AddTaskChatMessage.displayName = 'AddTaskChatMessage';

export default AddTaskChatMessage;
