import React, {FC} from 'react';
import Button from '@mui/lab/LoadingButton';
import {Stack, Typography} from '@mui/material';

import {ETaskStatuses} from '@/shared/constants/taskStatuses';
import {Correction} from '@/modals/confirmation/Correction';
import {useCreateSupportTaskCommentMutation} from '@/stores/api/support-task-page/support-task-comment';
import {useAppSelector} from '@/stores/hooks.ts';
import {isSupportUserSelector} from '@/stores/AuthStore/AuthStateSelector.ts';
import {CloseTaskGrade} from '@/modals/confirmation/CloseTaskGrade';
import TimeSpend from '@/modals/confirmation/TimeSpend/TimeSpend';

interface IProps {
  taskId: string;
  status: ETaskStatuses;
  isErrorTask?: boolean;
  isDisabled?: boolean;
  error?: string | null;
  isUpdateLoading?: boolean;
  onChangeStatus: (status: number) => void;
  isAuthor?: boolean;
  isExecutor?: boolean;
  isHead?: boolean;
  isHeadUnit?: boolean;
  isSupportHead?: boolean;
  handleTimeSpendSubmit: (value: string) => void;
}

const SupportTaskHeaderControls: FC<IProps> = ({
  taskId,
  status,
  isErrorTask,
  isDisabled,
  onChangeStatus,
  isUpdateLoading,
  error = null,
  isAuthor,
  isExecutor,
  isHead,
  isHeadUnit,
  isSupportHead,
  handleTimeSpendSubmit
}) => {
  const [open, setOpen] = React.useState(false);
  const [openClarification, setOpenClarification] = React.useState(false);
  const [openWork, setOpenWork] = React.useState(false);
  const [submitComment, {isLoading: isLoadingSubmit}] = useCreateSupportTaskCommentMutation();
  const isSupportUser = useAppSelector(isSupportUserSelector);
  const [isPopupGradeOpen, setPopupGradeOpen] = React.useState(false);
  const [isModalTimeSpendOpen, setModalTimeSpendOpen] = React.useState(false);

  const openModalTimeSpend = () => {
    setModalTimeSpendOpen(true);
  };

  const closeModalTimeSpend = () => {
    setModalTimeSpendOpen(false);
  };

  const openPopupGrade = () => {
    onChangeStatus(ETaskStatuses.Closed);
    setPopupGradeOpen(true);
  };

  const closePopupGrade = () => {
    setPopupGradeOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleForCorrection = async (message: string) => {
    if (message) {
      await submitComment({
        taskId,
        content: message
      });
    }

    onChangeStatus(ETaskStatuses.Progress);
    handleClose();
  };

  const handleForClarification = async (message: string) => {
    if (message) {
      await submitComment({
        taskId,
        content: message
      });
    }

    onChangeStatus(ETaskStatuses.Specify);
    setOpenClarification(false);
  };

  /**
   * Ответить на уточнение
   * Отправляем комментарий с флагом is_specify: true и задача переводится в предыдущий статус
   * @param message
   */
  const handleForWork = async (message: string) => {
    if (message) {
      await submitComment({
        taskId,
        content: message,
        is_specify: true
      });
    }

    // onChangeStatus(ETaskStatuses.Progress);
    setOpenWork(false);
  };

  return (
    <Stack sx={{position: 'relative'}}>
      <Stack spacing={8} direction="row">
        {!isAuthor && isSupportUser && status === ETaskStatuses.New && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={isDisabled}
            loading={isUpdateLoading}
            loadingIndicator=""
            onClick={() => onChangeStatus(ETaskStatuses.Progress)}
          >
            Взять в работу
          </Button>
        )}
        {(isAuthor || isHead || isHeadUnit) && status === ETaskStatuses.Specify && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={isDisabled}
            loading={isUpdateLoading}
            loadingIndicator=""
            onClick={() => setOpenWork(true)}
          >
            Ответить на уточнение
          </Button>
        )}
        {(isAuthor || isHead || isHeadUnit) && status === ETaskStatuses.Done && (
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            disabled={isDisabled}
            loading={isUpdateLoading}
            loadingIndicator=""
            onClick={handleOpen}
          >
            На исправление
          </Button>
        )}
        {/*Задача в статусе “Новая”. Кнопки у инициатора: “Закрыть”.*/}
        {(isErrorTask ||
          ((isAuthor || isHead || isHeadUnit) &&
            [ETaskStatuses.New, ETaskStatuses.Done].includes(status))) && (
          <Button
            variant="contained"
            color="primary"
            onClick={openPopupGrade}
            disabled={isDisabled}
            loading={isUpdateLoading}
            loadingIndicator="Завершить..."
          >
            Завершить
          </Button>
        )}
        {(isExecutor || isSupportHead) && status === ETaskStatuses.Progress && (
          <>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={isDisabled}
              loading={isUpdateLoading}
              loadingIndicator=""
              onClick={openModalTimeSpend}
            >
              Выполнено
            </Button>
          </>
        )}

        {isModalTimeSpendOpen && (
          <TimeSpend
            open={isModalTimeSpendOpen}
            onClose={closeModalTimeSpend}
            onSubmitTime={handleTimeSpendSubmit}
          />
        )}

        {((!isAuthor && isSupportUser && status === ETaskStatuses.New) ||
          ((isExecutor || isSupportHead) && status === ETaskStatuses.Progress)) && (
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isDisabled}
            loading={isUpdateLoading}
            loadingIndicator=""
            onClick={() => setOpenClarification(true)}
          >
            На уточнение
          </Button>
        )}
        {status === ETaskStatuses.Closed && <Typography component="h2">Завершено</Typography>}
      </Stack>

      {error && (
        <Typography variant="body2" color="error" sx={{position: 'absolute', bottom: -20}}>
          {error}
        </Typography>
      )}

      {isPopupGradeOpen && (
        <CloseTaskGrade open={isPopupGradeOpen} onClose={closePopupGrade} taskId={taskId} />
      )}

      <Correction
        open={open}
        onSubmit={handleForCorrection}
        onClose={handleClose}
        isLoading={isLoadingSubmit}
        sendButtonText="На исправление"
        title="На исправление"
      />

      <Correction
        open={openClarification}
        onSubmit={handleForClarification}
        onClose={() => setOpenClarification(false)}
        isLoading={isLoadingSubmit}
        sendButtonText="На уточнение"
        title="Вернуть на уточнение"
      />

      <Correction
        open={openWork}
        onSubmit={handleForWork}
        onClose={() => setOpenWork(false)}
        isLoading={isLoadingSubmit}
        sendButtonText="Ответить на уточнение"
        title="Ответить  на уточнение"
      />
    </Stack>
  );
};

export default SupportTaskHeaderControls;
