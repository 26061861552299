import React, {FC, memo, useCallback, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';

import {ComboBox} from '@/components/UIKit';
import {useFetchCompaniesMutation} from '@/stores/api/companies';
import {employeesFields} from '@/shared/utils/requestParamsUtils';
import {ICompany} from '@/shared/models/companyModel';
import {ITask} from '@/shared/models/tasksDataModel';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {
  isIndividualCompanySelector,
  setCompanyError,
  setEmployeeError,
  taskCompanySelector,
  updateCompany,
  updateEmployee
} from '@/stores/TaskEditStore';
import {ISubtask} from '@/shared/models/subtaskModel';
import {useDebounce} from '@/shared/hooks/useDebounce';
import {ICommonArgs} from '@/shared/models/commonModel';
import {checkTaskClientMsg, checkTaskEmployeeMsg} from '@/shared/utils/taskUtils';

interface IProps {
  task?: ITask | ISubtask;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const PAGE_SIZE = 50;

const TaskCompanyBox: FC<IProps> = ({task, isLoading, isDisabled}) => {
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<ICompany[]>([]);
  const company = useAppSelector(taskCompanySelector);
  const isIndividualCompany = useAppSelector(isIndividualCompanySelector);

  const loading = open && options.length === 0;

  const [fetchCompanies, {isLoading: isLoadingList}] = useFetchCompaniesMutation();
  const [isValueChanged, setIsValueChanged] = React.useState(false);

  const query = useMemo(() => {
    const queryParams: ICommonArgs = {
      include: 'employees,company_responsibles',
      fields: employeesFields,
      filters: {
        company_type_id: 1,
        is_has_employees: 1,
        active: 1
      },
      page: {
        limit: PAGE_SIZE,
        offset: 0
      }
    };

    if (inputValue && inputValue !== company?.name) {
      queryParams.find = inputValue;
    }

    if (queryParams['filters'] && isIndividualCompany && task?.fromEmployee?.email) {
      queryParams['filters']['has_employee_email'] = task.fromEmployee.email;
    }

    return queryParams;
  }, [inputValue, company?.name, isIndividualCompany, task?.fromEmployee?.email]);

  /**
   * COMPANY LIST
   */

  const loadCompanies = useCallback(
    async (active = true) => {
      try {
        const response = await fetchCompanies(query);
        const companyData = 'data' in response ? response.data?.data : [];

        if (active) {
          setOptions([...companyData]);
        }
      } catch (err) {
        console.error('Ошибка загрузки компаний');
      }
    },
    [fetchCompanies, query]
  );

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    loadCompanies(active);

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  /**
   * Поиск компании по названию
   */

  const handleChangeInput = (_event: React.SyntheticEvent, value: string, reason: string) => {
    if (reason === 'input' || (value && reason === 'reset')) {
      setInputValue(value);
    }
  };

  const debouncedValue = useDebounce(inputValue, 500);

  useEffect(() => {
    if (open) {
      loadCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  /**
   * Обновить компанию => сбросить сотрудника
   * @param value
   */
  const handleChangeCompany = (value?: ICompany) => {
    if (!value) {
      dispatch(updateCompany());
      dispatch(setCompanyError(checkTaskClientMsg(true)));
    }

    dispatch(updateCompany(value));
    dispatch(setCompanyError(null));

    dispatch(updateEmployee());
    dispatch(setEmployeeError(checkTaskEmployeeMsg(true)));

    value?.id !== task?.companyId ? setIsValueChanged(true) : setIsValueChanged(false);
  };

  // init
  // подставляем компанию задачи в выбранное значение
  useEffect(() => {
    if (!task?.company) {
      return;
    }

    dispatch(updateCompany(task.company));
  }, [dispatch, task?.company]);

  useEffect(() => {
    dispatch(setCompanyError(checkTaskClientMsg(isIndividualCompany)));
  }, [dispatch, isIndividualCompany]);

  return (
    <ComboBox<ICompany>
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      variantInput="standard"
      fieldLabel="Клиент"
      isLoading={isLoading || isLoadingList}
      sx={{
        minWidth: '400px'
      }}
      disableClearable
      value={company || null}
      // @ts-ignore
      onChange={(_, newValue?: ICompany) => {
        return handleChangeCompany(newValue);
      }}
      inputValue={inputValue}
      onInputChange={handleChangeInput}
      placeholder="Выберите клиента"
      filterOptions={x => x}
      filterSelectedOptions
      renderOption={({className, ...props}, option: ICompany) => {
        return (
          <li {...props} className={className} key={option.id}>
            {option.name}
          </li>
        );
      }}
      inputClassName={cn({'combo-box-changed': isValueChanged, imp: isIndividualCompany})}
      disabled={isDisabled}
    />
  );
};

export default memo(TaskCompanyBox);
