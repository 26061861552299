import React, {FC, useState} from 'react';
import Button from '@mui/lab/LoadingButton';
import {Backdrop, Modal, Stack, Typography} from '@mui/material';

import {ETaskStatuses} from '@/shared/constants/taskStatuses';
import {
  isIndividualCompanySelector,
  setCompanyError,
  setEmployeeError,
  setTypeError,
  taskEmployeeSelector,
  taskErrorsSelector
} from '@/stores/TaskSettingsStore';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {checkTaskTypeMsg} from '@/shared/utils/taskUtils';
import {EditButton} from '@/scenes/TaskPage/components/EditButton';
import {DelayButton} from '@/scenes/TaskPage/components/DelayButton';
import {TransferTaskForm} from '@/modals/transfer/TransferTaskForm';
import {ITask} from '@/shared/models/tasksDataModel';

interface IProps {
  task?: ITask;
  taskId: string;
  taskType: number;
  status?: ETaskStatuses;
  isErrorTask?: boolean;
  departmentId?: number;
  isDisabled?: boolean;
  isUpdateLoading?: boolean;
  onChangeStatus: (status: number) => void;
  selectedDateDelay?: Date | null;
  setSelectedDateDelay?: React.Dispatch<React.SetStateAction<Date | null>>;
  reasonDelay?: string;
  setReasonDelay?: any;
  isHead?: boolean;
  isHeadUnit?: boolean;
  taskTicket?: number;
}

const TaskHeaderControls: FC<IProps> = ({
  task,
  taskId,
  taskType,
  status,
  isErrorTask,
  departmentId,
  isDisabled,
  onChangeStatus,
  isUpdateLoading,
  selectedDateDelay = null,
  setSelectedDateDelay,
  reasonDelay,
  setReasonDelay,
  isHead,
  isHeadUnit,
  taskTicket
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const editedEmployee = useAppSelector(taskEmployeeSelector);

  const errorList = useAppSelector(taskErrorsSelector);
  const error = errorList?.[0];

  const isIndividualCompany = useAppSelector(isIndividualCompanySelector);
  const errorTypeMsg = checkTaskTypeMsg(taskType, true);

  const handleOpen = () => {
    if (errorTypeMsg) {
      dispatch(setTypeError(errorTypeMsg));
    }
    if (isIndividualCompany) {
      dispatch(setCompanyError('Выберите клиента'));
    }
    if (!editedEmployee) {
      dispatch(setEmployeeError('Выберите сотрудника'));
    }
    setOpen(!isIndividualCompany && editedEmployee && !errorTypeMsg);
  };

  const handleClose = () => setOpen(false);

  return (
    <Stack sx={{position: 'relative'}} alignItems="center">
      <Stack spacing={8} direction="row">
        {(isHead || isHeadUnit) && (
          <EditButton
            task={task}
            taskId={taskId}
            taskTicket={taskTicket}
            isLoading={isUpdateLoading}
          />
        )}
        {(status === ETaskStatuses.New || status === ETaskStatuses.Progress) && (
          <DelayButton
            selectedDateDelay={selectedDateDelay}
            setSelectedDateDelay={setSelectedDateDelay}
            reasonDelay={reasonDelay}
            setReasonDelay={setReasonDelay}
            isDisabled={isDisabled}
            isLoading={isUpdateLoading}
            onChangeStatus={onChangeStatus}
            errorTypeMsg={errorTypeMsg}
          />
        )}
        {(status === ETaskStatuses.New || status === ETaskStatuses.Delay) && (
          <>
            <div className="task-header-button">
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={isDisabled}
                loading={isUpdateLoading}
                loadingIndicator=""
                onClick={() => onChangeStatus(ETaskStatuses.Progress)}
                sx={{padding: 5}}
              >
                <Typography
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Взять в работу
                </Typography>
              </Button>
            </div>
          </>
        )}
        {status &&
          status !== ETaskStatuses.Closed &&
          status !== ETaskStatuses.Done &&
          status !== ETaskStatuses.Error && (
            <>
              <div className="task-header-button">
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  disabled={isDisabled}
                  loading={isUpdateLoading}
                  loadingIndicator=""
                  onClick={handleOpen}
                  sx={{padding: 5}}
                >
                  <Typography
                    sx={{
                      width: '100%',
                      textAlign: 'center',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Передать
                  </Typography>
                </Button>
              </div>
            </>
          )}
        {((isErrorTask && status !== ETaskStatuses.Closed) ||
          status === ETaskStatuses.Progress) && (
          <>
            <div className="task-header-button">
              <Button
                variant="contained"
                color="primary"
                onClick={() => onChangeStatus(ETaskStatuses.Closed)}
                disabled={isDisabled}
                loading={isUpdateLoading}
                loadingIndicator="Завершить..."
                sx={{padding: 5}}
              >
                <Typography
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Завершить
                </Typography>
              </Button>
            </div>
          </>
        )}

        {/* {status === ETaskStatuses.Closed && <Typography component="h2">Завершено</Typography>} */}
      </Stack>

      {error && (
        <Typography variant="body2" color="error" sx={{position: 'absolute', bottom: -20}}>
          {error}
        </Typography>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Перевод в другой отдел"
        closeAfterTransition
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <TransferTaskForm
          taskId={taskId}
          currentDepartmentId={departmentId}
          onClose={handleClose}
        />
      </Modal>
    </Stack>
  );
};

export default TaskHeaderControls;
