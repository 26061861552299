import {useMemo} from 'react';

import {formatUserName} from '@/shared/utils/fieldsUtils';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {ISubtask, ISubtaskTable} from '@/shared/models/subtaskModel';
import {ISupportTask} from '@/shared/models/supportTaskModel.ts';

export const useSupportTasksData = (data?: ISupportTask[]) => {
  const {departmentsIds, typesIds, statusesIds} = usePrepareDict();

  const memoTableData = useMemo(() => {
    const emptyArray = [] as ISubtask[];

    if (!data) {
      return emptyArray;
    }
    return data.map((task: ISubtask, index: number) => {
      return {
        ...task,
        departmentName: departmentsIds[task.departmentId]?.name,
        taskStatusName: statusesIds[task.taskStatusId]?.name,
        taskTypeName: typesIds[task.taskTypeId]?.name,
        companyName: task.company?.name,
        taskName: task.task?.title,
        userName: formatUserName(task.user),
        authorName: formatUserName(task.author),
        index
      };
    }) as ISubtaskTable[];
  }, [data, departmentsIds, statusesIds, typesIds]);

  return {
    data: memoTableData
  };
};
