import {GridSortModel} from '@mui/x-data-grid';

import {pascalToSnake} from '@/shared/utils/responseUtils';

export const prepareSortFunc = (sortArr: GridSortModel) => {
  if (!sortArr?.length) {
    return;
  }

  const {field, sort} = sortArr[0];

  let sortValue = '';

  switch (field) {
    case 'companyName':
      sortValue = 'company_id';
      break;
    case 'taskStatusName':
      sortValue = 'task_status_id';
      break;
    case 'taskTypeName':
      sortValue = 'task_type_id';
      break;
    case 'taskSourceName':
      sortValue = 'task_source_id';
      break;
    case 'userName':
      sortValue = 'user_id'; // TODO: уточнить
      break;
    case 'authorName':
      sortValue = 'author_id';
      break;
    case 'departmentName':
      sortValue = 'department_id'; // TODO: уточнить
      break;
    default:
      sortValue = pascalToSnake(field);
      break;
  }
  const direction = sort === 'desc' ? '-' : '';

  return direction + sortValue;
};
