import {useMemo} from 'react';

import {ITask} from '@/shared/models/tasksDataModel';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';

export const usePrepareTasksData = (data?: ITask[]) => {
  const {departmentsIds, typesIds, statusesIds, sourcesIds} = usePrepareDict();

  const memoTableData = useMemo(() => {
    const emptyArray = [] as ITask[];

    if (!data) {
      return emptyArray;
    }
    return data.map((task: ITask, index: number) => {
      return {
        ...task,
        departmentName: departmentsIds[task.departmentId]?.name,
        taskStatusName: statusesIds[task.taskStatusId]?.name,
        taskTypeName: typesIds[task.taskTypeId]?.name,
        taskSourceName: sourcesIds[task.taskSourceId]?.name,
        companyName: task.company?.name,
        userName: formatUserName(task.user),
        employeeName: formatUserName(task.employee),
        fromEmployeeName: formatUserName(task.fromEmployee),
        index
      };
    }) as ITask[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    data: memoTableData
  };
};
