// copied from TaskHeaderControls
import React, {FC, useEffect, useState} from 'react';
import Button from '@mui/lab/LoadingButton';
import {Backdrop, Modal, Stack, Typography} from '@mui/material';

import {ETaskStatuses} from '@/shared/constants/taskStatuses';
import {TransferSubtaskForm} from '@/modals/transfer/TransferSubtaskForm';
import {setEmployeeError, taskEmployeeSelector} from '@/stores/TaskSettingsStore';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {Correction} from '@/modals/confirmation/Correction';
import {useCreateSubtaskCommentMutation} from '@/stores/api/subtask-page/subtask-comment';

interface IProps {
  taskId: string;
  status: ETaskStatuses;
  isErrorTask?: boolean;
  departmentId?: number;
  isDisabled?: boolean;
  error?: string | null;
  isUpdateLoading?: boolean;
  onChangeStatus: (status: number) => void;
  isAuthor?: boolean;
  isExecutor?: boolean;
}

const SubtaskHeaderControls: FC<IProps> = ({
  taskId,
  status,
  isErrorTask,
  departmentId,
  isDisabled,
  onChangeStatus,
  isUpdateLoading,
  error = null,
  isAuthor,
  isExecutor
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const editedEmployee = useAppSelector(taskEmployeeSelector);
  const [openCorrection, setOpenCorrection] = useState(false);

  useEffect(() => {
    if (editedEmployee) {
      dispatch(setEmployeeError(null));
    }
  }, [dispatch, editedEmployee]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  /**
   * Окно на исправление
   */
  const handleOpenCorrection = () => {
    setOpenCorrection(true);
  };

  const handleCloseCorrection = () => {
    setOpenCorrection(false);
  };

  const [submitComment, {isLoading: isLoadingSubmit}] = useCreateSubtaskCommentMutation();

  const handleForCorrection = async (message: string) => {
    if (message) {
      await submitComment({
        taskId,
        content: message
      });
    }

    onChangeStatus(ETaskStatuses.Progress);
    handleCloseCorrection();
  };

  return (
    <Stack sx={{position: 'relative'}}>
      <Stack spacing={8} direction="row">
        {isExecutor && status === ETaskStatuses.New && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={isDisabled}
            loading={isUpdateLoading}
            loadingIndicator=""
            onClick={() => onChangeStatus(ETaskStatuses.Progress)}
          >
            Взять в работу
          </Button>
        )}

        {isAuthor && status === ETaskStatuses.Done && (
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            disabled={isDisabled}
            loading={isUpdateLoading}
            loadingIndicator=""
            onClick={handleOpenCorrection}
          >
            На исправление
          </Button>
        )}

        {/* В статусе «Принято в работу» у исполнителя нужно добавить кнопку «Передать», так как они могут взять в работу, а потом осознать, что задача не для них.*/}
        {((isAuthor && status === ETaskStatuses.New) ||
          (isExecutor && status === ETaskStatuses.Progress)) && (
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            disabled={isDisabled}
            loading={isUpdateLoading}
            loadingIndicator=""
            onClick={handleOpen}
          >
            Передать
          </Button>
        )}

        {(isErrorTask ||
          (isAuthor &&
            [ETaskStatuses.New, ETaskStatuses.Progress, ETaskStatuses.Done].includes(status))) && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onChangeStatus(ETaskStatuses.Closed)}
            disabled={isDisabled}
            loading={isUpdateLoading}
            loadingIndicator="Завершить..."
          >
            Завершить
          </Button>
        )}
        {isExecutor && status === ETaskStatuses.Progress && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onChangeStatus(ETaskStatuses.Done)}
            disabled={isDisabled}
            loading={isUpdateLoading}
            loadingIndicator="Выполнено..."
          >
            Выполнено
          </Button>
        )}

        {status === ETaskStatuses.Closed && <Typography component="h2">Завершено</Typography>}
      </Stack>

      {error && (
        <Typography variant="body2" color="error" sx={{position: 'absolute', bottom: -20}}>
          {error}
        </Typography>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Перевод в другой отдел"
        closeAfterTransition
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <TransferSubtaskForm
          taskId={taskId}
          currentDepartmentId={departmentId}
          onClose={handleClose}
        />
      </Modal>

      <Modal
        open={openCorrection}
        onClose={handleCloseCorrection}
        aria-labelledby="Перевод в другой отдел"
        closeAfterTransition
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Correction
          open={openCorrection}
          onSubmit={handleForCorrection}
          onClose={handleCloseCorrection}
          isLoading={isLoadingSubmit}
          sendButtonText="На исправление"
          title="На исправление"
        />
      </Modal>
    </Stack>
  );
};

export default SubtaskHeaderControls;
