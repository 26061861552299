import React, {FC} from 'react';
import ReactQuill from 'react-quill';
import cn from 'classnames';

import 'react-quill/dist/quill.snow.css';
import s from './TextEditor.module.css';

interface IProps {
  id?: string;
  value?: string;
  onChange: (val: string) => void;
  error?: boolean;
  helperText?: string;
  className?: string;
}

const TextEditor: FC<IProps> = ({className, error, helperText, id, value, onChange}) => {
  return (
    <div className={cn({[s.error]: error})}>
      <ReactQuill
        id={id}
        theme="snow"
        value={value}
        onChange={onChange}
        className={cn(s.editor, className, {[s.error]: error})}
      />
      {helperText && <span className={s.helperText}>{helperText}</span>}
    </div>
  );
};

export default TextEditor;
