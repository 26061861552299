import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IProps {
  chatErrorMessage: {
    error: string[] | null;
  };
}

const initialState: IProps = {
  chatErrorMessage: {
    error: null
  }
};

export const taskChatStoreSlice = createSlice({
  name: 'taskChatState',
  initialState,
  reducers: {
    setChatErrorMessage: (state, action: PayloadAction<string[] | null>) => {
      state.chatErrorMessage = {
        ...state.chatErrorMessage,
        error: action.payload
      };
    }
  }
});

export const {setChatErrorMessage} = taskChatStoreSlice.actions;

export const taskChatStateReducer = taskChatStoreSlice.reducer;
