import React, {FC, memo, useEffect, useState} from 'react';
import {CircularProgress} from '@mui/material';

import {IRateTaskArg} from '@/stores/api/task-page/task-rate';
import s from '@/scenes/FeedbackPage/FeedbackPage.module.css';

interface IProps {
  taskId: string;
  ticket: number;
  onSubmit: (params: IRateTaskArg) => any;
}

const PositiveFeedback: FC<IProps> = ({taskId, ticket, onSubmit}) => {
  const [isDone, setIsDone] = useState(false);

  const rateTask = async () => {
    const res = await onSubmit({
      taskId,
      rate: 2 // success
    });

    if (!res.error) {
      setIsDone(true);
    }
  };

  useEffect(() => {
    rateTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDone) {
    return (
      <p className={s.text}>
        Задача №{ticket} оценена.
        <br />
        <br />
        Рады, что Вы остались довольны работой нашего специалиста, если у Вас будут ещё вопросы,
        обязательно задавайте — мы на связи!
      </p>
    );
  }

  return <CircularProgress color="inherit" />;
};

export default memo(PositiveFeedback);
