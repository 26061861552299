import React, {FC, memo, useMemo} from 'react';
import cn from 'classnames';
import {CircularProgress, Typography} from '@mui/material';

import {IFile} from '@/shared/models/fileModel';
import {convertToDataStorageUnit} from '@/shared/utils/mathUtils';
import {ReactComponent as FileIcon} from '@/assets/file.svg';
import {ReactComponent as DownloadIcon} from '@/assets/download.svg';

import s from './ChatFile.module.css';

interface IProps {
  isSending?: boolean;
  file: IFile;
  onGetFile: (file: IFile) => void;
}

const ChatFile: FC<IProps> = ({file, isSending, onGetFile}) => {
  const sizeFormatted = useMemo(() => {
    if (!file?.size) {
      return '';
    }
    return convertToDataStorageUnit(file.size, true, 1);
  }, [file?.size]);

  if (!file) {
    return null;
  }

  return (
    <button
      className={cn(s.file, s.fileButton, {[s.disabled]: isSending})}
      onClick={() => onGetFile(file)}
      title={file.name}
    >
      <div className={s.fileIcon}>
        <FileIcon />
      </div>
      <div className={s.fileInfo}>
        <Typography variant="bold" color="" title={file.name} className={s.fileInfoText}>
          {file.name}
        </Typography>
        <span className={s.fileSize}>{sizeFormatted}</span>
      </div>
      <div className={s.fileDownload}>
        {isSending && <CircularProgress color="inherit" size={24} />}
        {!isSending && <DownloadIcon />}
      </div>
    </button>
  );
};

export default memo(ChatFile);
