import {api} from '@/stores/api';
import {ITaskResponse} from '@/shared/models/tasksDataModel';

interface IFileArg {
  name: string;
  file: string;
}

interface ICreateTaskArg {
  title?: string;
  company_id?: number | string;
  employee_id?: number | string;
  task_type_id?: number | string;
  task_source_id?: number | string;
  task_comment?: {
    content: string;
    attachments?: IFileArg[];
  };
  delay_task_comment?: {
    content: string;
  };
}

export const apiWithCreateTask = api.injectEndpoints({
  endpoints: builder => ({
    createTask: builder.mutation<ITaskResponse, ICreateTaskArg>({
      query: (data: ICreateTaskArg) => ({
        url: '/v1/tasks/',
        method: 'post',
        data
      }),
      invalidatesTags: [{type: 'TASKS', id: 'LIST'}]
    })
  }),
  overrideExisting: true
});

export const {useCreateTaskMutation} = apiWithCreateTask;
