import React, {FC, memo} from 'react';
import {Typography} from '@mui/material';

import {IUser} from '@/shared/models';
import {useAppSelector} from '@/stores/hooks';
import {toCopyEmployeesSelector} from '@/stores/TaskSettingsStore';

interface IProps {
  recipient?: IUser;
}

const SendAllConfirmContent: FC<IProps> = ({recipient}) => {
  const toCopyEmployees = useAppSelector(toCopyEmployeesSelector);
  const email = recipient?.email ? `(${recipient?.email})` : '';

  if (!recipient && !toCopyEmployees.length) {
    return <p>Письмо получат все сотрудники из раздела Кому и Копия</p>;
  }
  return (
    <>
      <Typography variant="bold">Письмо получат:</Typography>

      {recipient && <Typography variant="body1">{`${recipient?.name || ''} ${email}`}</Typography>}
      <br />
      {toCopyEmployees.length > 0 && (
        <ul>
          {toCopyEmployees.map((val: IUser) => {
            return (
              <li key={val.id}>
                {`${val.name} (${val.email})`} <br />
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default memo(SendAllConfirmContent);
