import React, {FC} from 'react';
import {generatePath} from 'react-router';
import {Collapse} from '@mui/material';
import get from 'lodash/get';
import {Link} from 'react-router-dom';
import cn from 'classnames';

import {RoutePaths} from '@/shared/constants/route';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {formatDate} from '@/shared/utils/dateUtils';
import {ISubtask} from '@/shared/models/subtaskModel';
import {IDepartment, IStatus, IType} from '@/shared/models/dictionaryModel';
import {DeadlineChip} from '@/components/DeadlineChip';
import {ReactComponent as FilesIcon} from '@/assets/files.svg';

import s from './SubCell.module.css';

interface IDictTable {
  departmentsIds?: Record<string, IDepartment>;
  typesIds?: Record<string, IType>;
  statusesIds?: Record<string, IStatus>;
}

interface ISubCellProps extends IDictTable {
  id: number;
  subtasks: Record<string, ISubtask[]>;
  field?: string;
  isSelected: boolean;
}

const SubCell: FC<ISubCellProps> = ({
  id,
  subtasks = {},
  departmentsIds = {},
  typesIds = {},
  statusesIds = {},
  field,
  isSelected
}) => {
  const loadedSubtasks = subtasks[id];

  const hasSubtasks = loadedSubtasks && loadedSubtasks.length > 0;

  if (!hasSubtasks) {
    return null;
  }

  return (
    <Collapse in={isSelected} className={s.collapse}>
      {loadedSubtasks.map((val: ISubtask) => {
        const subtask = {
          ...val,
          department: departmentsIds ? departmentsIds[val.departmentId] : '-',
          taskType: typesIds ? typesIds[val.taskTypeId] : '-',
          taskStatusName: statusesIds ? statusesIds[val.taskStatusId]?.name : '',
          taskSource: '-',
          userName: formatUserName(val.user),
          authorName: formatUserName(val.author)
        };

        const value = field ? get(subtask, field, '-') : '-';

        const renderValue = () => {
          switch (field) {
            case 'title':
              return (
                <Link
                  className={cn('lint-text', s.tableRow)}
                  to={generatePath(RoutePaths.SubtaskPage, {taskId: `${val.id}`})}
                >
                  {value}
                </Link>
              );
            case 'createdAt':
            case 'updatedAt':
            case 'doneAt':
              return value ? `${formatDate(value, 'dd.MM.yyyy HH:mm')}` : '-';
            case 'deadlineAt':
              return (
                <div className={s.deadlineCell}>
                  <DeadlineChip
                    deadlineAt={subtask.deadlineAt}
                    createdAt={subtask.createdAt}
                    doneAt={subtask.doneAt}
                    componentType="chip"
                  />
                </div>
              );
            case 'isHasFiles': {
              return (
                <div className={s.tableRow}>
                  {value}
                  {value ? <FilesIcon /> : <div>&nbsp;</div>}
                </div>
              );
            }
            default:
              return <span>{value}</span>;
          }
        };

        return (
          <div key={val.id} className={s.tableRow}>
            {renderValue()}
          </div>
        );
      })}
    </Collapse>
  );
};

export default SubCell;
