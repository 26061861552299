import {individualCompanies, innerSupportCompanies} from '@/shared/constants/companyConstants';

const arrayToString = (param: any[]) => {
  const arr = param.filter(Boolean);

  if (!arr.length) {
    return undefined;
  }

  // массив с одним элементом
  if (arr.length === 1) {
    return arr[0] ?? undefined;
  }

  // массив
  return 'in|' + arr.join(',');
};

export const prepareUrlParams = (param: string | null | (string | null)[]) => {
  if (!param) {
    return undefined;
  }
  if (typeof param === 'string') {
    // Для задач и подзадач при выбранном фильтре в запросе использовать filters[physic_companies]=1
    // Для техподдержки, если выбрали внутренние компании, то отдельный фильтр filters[without_inner_support_companies]
    if (param === individualCompanies || param === innerSupportCompanies) {
      return undefined;
    }
    try {
      const arr = param.split(',');
      return arrayToString(arr);
    } catch (_err) {
      /* empty */
    }
    return param;
  }

  if (typeof param === 'number') {
    return param;
  }

  // Если массив
  return arrayToString(param);
};
