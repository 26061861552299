import {companyNotDefined} from '@/shared/constants/companyConstants';

export const checkCompanyIndividual = (
  companyIndividualType?: number,
  typeId?: any,
  name?: string
) => {
  if (!companyIndividualType || !typeId || !name) {
    return false;
  }
  if (name !== companyNotDefined) {
    return typeId === companyIndividualType;
  }
  return false;
};
