import React, {FC} from 'react';

import SupportTasksFilters from '@/scenes/AllTasksPage/components/TasksFilters/SupportTasksFilters.tsx';
import {TFilterValues} from '@/shared/models/tasksFilterModel.ts';
import {useAppDispatch, useAppSelector} from '@/stores/hooks.ts';
import {useUpdateUrlParams} from '@/scenes/AllTasksPage/hooks/useUpdateUrlParams.ts';
import {supportTasksFilterValuesSelector} from '@/stores/SupportTasksFilterStore/SupportTasksFilterSelector.ts';
import {resetFilterValues, updateValueByKey} from '@/stores/SupportTasksFilterStore';

import {SupportTasksTable} from './components/SupportTasksTable/index';

interface IProps {}

const SupportTasks: FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const filterValues: TFilterValues = useAppSelector(supportTasksFilterValuesSelector);

  const handleUpdateFilters = ({key, value}: any) => {
    dispatch(updateValueByKey({key, value}));
  };

  const handleResetFilters = () => {
    dispatch(resetFilterValues());
  };

  useUpdateUrlParams(filterValues, handleUpdateFilters, handleResetFilters);

  return (
    <>
      <SupportTasksFilters />
      <SupportTasksTable />
    </>
  );
};

export default SupportTasks;
