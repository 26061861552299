import {useEffect} from 'react';

import {EFilterName, IOption} from '@/shared/models/tasksFilterModel';
import {allSourcesQueryState} from '@/stores/api/dictionaries';

type TUpdate = (key: EFilterName, options: IOption[]) => void;

export const useSourceFilters = (onUpdate: TUpdate) => {
  const {data: sources} = allSourcesQueryState({});

  useEffect(() => {
    if (sources) {
      onUpdate(EFilterName.Sources, sources);
    }
  }, [onUpdate, sources]);
};
