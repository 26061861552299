import React, {FC, memo, useState} from 'react';
import {Box, Grid, TextField, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';

import {IRateTaskArg, useRateTaskMutation} from '@/stores/api/task-page/task-rate';
import s from '@/scenes/FeedbackPage/FeedbackPage.module.css';

interface IProps {
  taskId: string;
  ticket: number;
}

type FormValues = {
  content: string;
};

const NegativeFeedback: FC<IProps> = ({taskId, ticket}) => {
  const {control, handleSubmit} = useForm<FormValues>();
  const [submitRate, {isLoading: isLoadingRate}] = useRateTaskMutation();
  const [isDone, setIsDone] = useState(false);

  // @ts-ignore
  const onSubmit: SubmitHandler<FormValues | undefined> = async data => {
    if (data) {
      const requestData = {
        taskId,
        rate: 1
      } as IRateTaskArg;

      if (data.content) {
        requestData.content = data.content;
      }

      const res = await submitRate(requestData);

      // @ts-ignore
      if (!res?.error) {
        setIsDone(true);
      }
    }
  };

  if (isDone) {
    return (
      <p className={s.text}>
        Задача №{ticket} оценена отрицательно.
        <br />
        <br />
        Ваш комментарий успешно отправлен. Благодарим за обратную связь! Мы свяжемся с Вами, чтобы
        обсудить, как сделать работу бухгалтера более эффективной.
      </p>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h2" sx={{mb: 20}}>
        Задача №{ticket} оценена Вами отрицательно.
        <br />
        <br /> Расскажите нам пожалуйста, что прошло не так?
      </Typography>

      <Box sx={{mb: 20, textAlign: 'left'}}>
        <label htmlFor="content" className={s.label}>
          Оставьте комментарий
        </label>
        <Controller
          name="content"
          control={control}
          defaultValue=""
          render={({field: {onChange, value}}) => (
            <TextField
              id="content"
              placeholder="Введите сообщение"
              value={value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(event.target.value);
              }}
              fullWidth
              multiline
              autoComplete="off"
              rows={6}
            />
          )}
        />
      </Box>

      <Grid container justifyContent="space-between">
        <div></div>
        {/*<Button*/}
        {/*  type="button"*/}
        {/*  variant="outlined"*/}
        {/*  color="secondary"*/}
        {/*  onClick={handleClose}*/}
        {/*  sx={{minWidth: 154, borderWidth: 2}}*/}
        {/*>*/}
        {/*  Отмена*/}
        {/*</Button>*/}
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          loading={isLoadingRate}
          loadingIndicator="Отправляется…"
          sx={{minWidth: 154}}
        >
          Отправить
        </LoadingButton>
      </Grid>
    </form>
  );
};

export default memo(NegativeFeedback);
