import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {logout} from '@/stores/AuthStore';

interface IProps {
  token: string | null;
  exists: boolean;
  expiredAt: string | null;
}

const initialState = {
  token: null,
  exists: false,
  expiredAt: null
} as IProps;

interface IAuth {
  expires_at: string;
  user: {
    id: string;
  };
}

export const fileStorageStateSlice = createSlice({
  name: 'fileStorageState',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<IAuth>) => {
      state.expiredAt = action.payload.expires_at;
      state.token = action.payload.user?.id;
    },
    resetState: () => {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(logout, () => {
      return initialState;
    });
  }
});

export const {setToken, resetState} = fileStorageStateSlice.actions;

export const fileStorageReducer = fileStorageStateSlice.reducer;
