import React, {FC, useMemo} from 'react';
import cn from 'classnames';
import {CircularProgress, Divider, Stack, Typography} from '@mui/material';

import {IMessage} from '@/shared/models/messageModel';
import {formatDate} from '@/shared/utils/dateUtils';
import {IFile} from '@/shared/models/fileModel';
import {ChatFilesDownloadButton} from '@/components/Chat/components/ChatFilesDownloadButton';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {HtmlContent} from '@/components/HtmlContent';
import {IChatMessage} from '@/shared/models/chatModel.ts';

import {ChatFile} from '../ChatFile';

import {ReactComponent as DoneIcon} from './assets/check.svg';
import {ReactComponent as DoneAllIcon} from './assets/double-check.svg';
import s from './ChatMessage.module.css';

interface IProps {
  message: IMessage | IChatMessage | any;
  onGetFile: (file: IFile) => void;
  isDisplaySystemComments: boolean;
}

const ChatMessage: FC<IProps> = ({message, onGetFile, isDisplaySystemComments}) => {
  const {
    id,
    taskId,
    createdAt,
    isRead,
    isSending,
    employee,
    customer,
    content,
    user,
    direction,
    isChangeDepartment,
    isDelay,
    isSpectator,
    isInner,
    taskIds
  } = message;

  const userName = useMemo(() => {
    if (!user) {
      if (employee) {
        return `${formatUserName(employee)} <${employee.email}>`;
      }
      if (customer) {
        return `${formatUserName(customer)} <${customer.email}>`;
      }
      return;
    }
    return `${formatUserName(user)} <${user.email}>`;
  }, [employee, user, customer]);

  if (message.isSystem) {
    if (!isDisplaySystemComments) {
      return null;
    }

    return (
      <div className={s.messagesSystem}>
        <Typography variant="body2" className={s.messagesSystemText}>
          <span className={s.messagesItemTime}>
            {formatDate(createdAt, 'dd MMM yyyy в HH:mm')}&nbsp;
          </span>
          {userName && <span>{userName}:&nbsp;</span>}
          {content}
        </Typography>
      </div>
    );
  }

  return (
    <div className={cn(s.messagesItem, !direction && s.messagesItemSent)}>
      <div className={s.messagesItemInfo}>
        {isChangeDepartment && 'Комментарий при передаче '}
        {isDelay && 'Комментарий при переносе'}
        {isSpectator && 'Комментарий для наблюдателя'}
        {isInner && 'Внутренний комментарий'}
        <br />
        {taskIds && (
          <div key={taskIds.join(',')} className={s.taskLink}>
            <span className={s.taskLabel}>Задача №</span>
            {taskIds.map((taskId: string, index: number) => (
              <React.Fragment key={taskId}>
                <a
                  href={`/task/${taskId}`}
                  target="_blank"
                  rel="noreferrer"
                  className={s.taskLinkUrl}
                >
                  {taskId}
                </a>
                {index < taskIds.length - 1 && ', '}
              </React.Fragment>
            ))}
          </div>
        )}
        {userName && <span>{userName}&nbsp;</span>}
        <span className={s.messagesItemTime}>{formatDate(createdAt, 'dd MMM yyyy в HH:mm')}</span>
      </div>
      <div
        className={cn(s.messagesItemContent, {
          [s.messagesItemContentGuest]: direction,
          [s.messagesItemContentMe]: !direction,
          [s.messagesItemContentChangeDepartment]: isChangeDepartment && !direction,
          [s.messagesItemContentDelay]: isDelay && !direction,
          [s.messagesItemContentForSpectator]: isSpectator,
          [s.messagesItemContentInner]: isInner
        })}
      >
        <HtmlContent html={content} />

        {/* ATTACHMENT */}

        {((message.files && message.files.length > 0) ||
          (isSending && message.attachments && message.attachments.length > 0)) && (
          <>
            <Divider sx={{borderColor: '#CCD6DF', mb: 3}} />
            <Stack
              spacing={8}
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              {message.files && message.files.length > 0 && (
                <Stack direction="row" spacing={6} useFlexGap flexWrap="wrap">
                  {message.files.map((file: IFile) => (
                    <ChatFile key={file.id} file={file} onGetFile={onGetFile} />
                  ))}
                </Stack>
              )}
              {isSending && message.attachments && message.attachments.length > 0 && (
                <Stack direction="row" spacing={6} useFlexGap flexWrap="wrap">
                  {message.attachments.map((file: any, index: number) => (
                    <ChatFile isSending={isSending} key={index} file={file} onGetFile={onGetFile} />
                  ))}
                </Stack>
              )}
            </Stack>
            <Stack direction="column">
              {!isSending && message.files && message.files.length > 0 && (
                <ChatFilesDownloadButton
                  className={!direction ? s.downloadItemSent : undefined}
                  fileName={`${taskId}-${id}`}
                  file={message.files[0]}
                />
              )}
            </Stack>
          </>
        )}
      </div>
      <div
        className={cn(s.messagesItemRead, {
          [s.messagesItemReadSent]: direction
        })}
      >
        {isSending && <CircularProgress color="inherit" size={16} />}
        {!isSending && (isRead ? <DoneAllIcon /> : <DoneIcon />)}
      </div>
    </div>
  );
};

export default ChatMessage;
