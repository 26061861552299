import React, {FC, useState} from 'react';
import {Backdrop, Box, Grid, Modal, Typography} from '@mui/material';

import {ReactComponent as LikeIcon} from '@/assets/like.svg';
import {ReactComponent as DislikeIcon} from '@/assets/dislike.svg';
import {ReactComponent as LogoWithText} from '@/assets/logo-text.svg';
import {useRateSupportTaskMutation} from '@/stores/api/support-task-page/support-task-rate';

import s from './CloseTaskGrade.module.css';

interface IProps {
  open: boolean;
  onClose: () => void;
  taskId: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 600,
  height: 503,
  bgcolor: '#EEF0F3',
  p: 18,
  overflowY: 'auto'
};

const popupContent = {
  width: '100%',
  maxWidth: 528,
  height: 234,
  bgcolor: 'background.paper',
  p: 18,
  overflowY: 'auto',
  borderRadius: '8px'
};

const CloseTaskGrade: FC<IProps> = ({open, onClose, taskId}) => {
  const [rate, setRate] = useState<number | null>(null);
  const [submitRate] = useRateSupportTaskMutation();

  const handleIconClick = async (rating: number) => {
    setRate(rating);
    try {
      await submitRate({supportTaskId: taskId, rate: rating});
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Перевод в другой отдел"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <Box pb={15}>
          <LogoWithText />
        </Box>
        <Grid
          sx={popupContent}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <form action="">
              <Typography variant="h1" align="center" style={{marginBottom: '20px'}}>
                Оцените выполнение задачи
              </Typography>
              <Grid container pt={10} justifyContent="space-around">
                <Grid item style={{cursor: 'pointer'}}>
                  <LikeIcon
                    className={rate === 2 ? s.active : s.disabled}
                    onClick={() => handleIconClick(2)}
                  />
                </Grid>
                <Grid item style={{cursor: 'pointer'}}>
                  <DislikeIcon
                    className={rate === 1 ? s.active : s.disabled}
                    onClick={() => handleIconClick(1)}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Typography style={{fontWeight: 500}} className={s.respectText}>
          С уважением, <br /> команда Первой Экспертной Бухгалтерии
        </Typography>
      </Box>
    </Modal>
  );
};

export default CloseTaskGrade;
