import React from 'react';
import {DataGrid, GridColDef, GridColumnVisibilityModel, ruRU} from '@mui/x-data-grid';
import {DataGridProps} from '@mui/x-data-grid/models/props/DataGridProps';

import TableToolbar from './components/TableToolbar/TableToolbar';

interface IProps extends DataGridProps {
  // tableData: T[];
  columns: GridColDef[];
  // rowCount: number;
  // autoHeight?: boolean;
}

const getTogglableColumns = (columns: GridColDef[]) => {
  // hide the column with field `id` from list of togglable columns
  const hiddenColumns = ['unreadCommentsCount', 'isHasFiles', 'userUnreadCommentsCount'];
  return columns
    .filter(column => !hiddenColumns.includes(column.field))
    .map(column => column.field);
};

const Table = ({
  // tableData,
  columns,
  // autoHeight,
  ...props
}: IProps) => {
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      id: false,
      status: false
    });

  return (
    <DataGrid
      // rows={tableData}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {page: 0, pageSize: 5}
        },
        columns: {
          columnVisibilityModel: {
            // Hide columns status and traderName, the other columns will remain visible
            // status: false,
            // traderName: false,
          }
        }
      }}
      autoHeight
      getRowHeight={() => 'auto'}
      getEstimatedRowHeight={() => 28}
      columnHeaderHeight={44}
      filterMode="client"
      disableColumnMenu
      disableColumnFilter
      // disableColumnSelector
      disableDensitySelector
      slots={{
        toolbar: TableToolbar
      }}
      slotProps={{
        columnsPanel: {
          getTogglableColumns
        }
      }}
      // controlled visibility columns
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
      localeText={{
        ...ruRU.components.MuiDataGrid.defaultProps.localeText,
        toolbarColumns: 'Колонки'
      }}
      getRowClassName={params => {
        if (params.row.index % 2 === 0) {
          return 'peb-row_selected';
        }
        return 'peb-row';
      }}
      {...props}
    />
  );
};

export default Table;
