import React, {FC, SyntheticEvent} from 'react';
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Typography
} from '@mui/material';

interface IProps {
  open: boolean;
  onSubmit: ({isRate}: {isRate: boolean}) => void;
  onClose: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 434,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const CloseTaskConfirmation: FC<IProps> = ({open, onSubmit, onClose}) => {
  const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    if (data.get('notrate') === 'on') {
      return onSubmit({isRate: false});
    }
    onSubmit({isRate: true});
  };

  return (
    <Modal
      open={open}
      aria-labelledby="Перевод в другой отдел"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <form action="" onSubmit={handleSubmit}>
          <Typography variant="h2">Закрытие задачи</Typography>
          <FormControlLabel
            name="notrate"
            control={<Checkbox />}
            label="Закрыть без отправки уведомления клиенту"
            sx={{mt: 10, mb: 15}}
          />

          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={onClose}
                sx={{minWidth: 140}}
                disabled={false}
              >
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={false}
                sx={{minWidth: 140}}
              >
                Закрыть задачу
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default CloseTaskConfirmation;
