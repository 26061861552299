import {api} from '@/stores/api';
import {ITaskResponse} from '@/shared/models/tasksDataModel';

export interface IRateTaskArg {
  supportTaskId?: string;
  rate?: number;
}

export const apiWithSupportTaskRate = api.injectEndpoints({
  endpoints: builder => ({
    rateSupportTask: builder.mutation<ITaskResponse, IRateTaskArg>({
      query: ({supportTaskId, rate}) => ({
        url: `/v1/support_tasks/${supportTaskId}/rate`,
        method: 'put',
        data: {
          rate
        }
      }),
      // @ts-ignore
      transformResponse(returnValue?: {data: ITaskResponse}): ITaskResponse | undefined {
        return returnValue?.data;
      }
    })
  }),
  overrideExisting: true
});

export const {useRateSupportTaskMutation} = apiWithSupportTaskRate;
