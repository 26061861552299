import {Draft} from '@reduxjs/toolkit';

import {api} from '@/stores/api';
import {IFile} from '@/shared/models/fileModel';
import {ITask, ITaskModel} from '@/shared/models/tasksDataModel';
import {IMessage} from '@/shared/models/messageModel';
import {IPagination} from '@/shared/models/commonModel.ts';

interface ICreateTaskCommentArg {
  taskId: string;
  content: string;
  attachments?: IFile[];
  isAll?: boolean;
}

export const apiWithTaskComment = api.injectEndpoints({
  endpoints: builder => ({
    createTaskComment: builder.mutation<ITaskModel, ICreateTaskCommentArg>({
      query: ({taskId, content, attachments, isAll}) => ({
        url: `/v1/tasks/${taskId}/task_comments`,
        method: 'post',
        data: {
          content,
          attachments,
          reply_all: isAll
        }
      }),
      // @ts-ignore
      invalidatesTags: (_result, _error, id) => {
        return [{type: 'TASK', id}];
      },
      async onQueryStarted({taskId, ...patch}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          // @ts-ignore
          api.util.updateQueryData('getTaskById', {taskId}, (draft: Draft<IPagination<ITask>>) => {
            const comment: IMessage = {
              id: -1,
              taskId: Number(taskId),
              createdAt: new Date().toUTCString(),
              isSending: true,
              ...patch
            };

            if (draft.data?.taskComments?.length) {
              draft.data.taskComments.push(comment);
            } else {
              draft.data.taskComments = [comment];
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      }
    })
  }),
  overrideExisting: true
});

export const {useCreateTaskCommentMutation} = apiWithTaskComment;
