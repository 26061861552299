import React, {FC, memo, useEffect, useState} from 'react';
import {InputAdornment, SxProps, Theme} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import {Input} from '@/components/UIKit/Input';
import {useAppDispatch} from '@/stores/hooks';
import {useDebounce} from '@/shared/hooks/useDebounce';
import {setSearchState} from '@/stores/SearchStateStore';

interface IProps {
  placeholder?: string;
  isStartAdornment: boolean;
  customRootStyles?: SxProps<Theme>;
}

const SearchField: FC<IProps> = ({placeholder = 'Поиск', isStartAdornment, customRootStyles}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    if (e.target.value === '') {
      dispatch(setSearchState(''));
    }
  };

  const debouncedValue = useDebounce(search, 400);

  const startAdornment = {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    )
  };

  const endAdornment = {
    endAdornment: (
      <InputAdornment position="end">
        <SearchIcon />
      </InputAdornment>
    )
  };

  useEffect(() => {
    if (search === '') {
      dispatch(setSearchState(''));
    } else {
      dispatch(setSearchState(debouncedValue));
    }
  }, [dispatch, debouncedValue, search]);

  return (
    <>
      <Input
        placeholder={placeholder}
        fullWidth
        autoComplete="off"
        value={search}
        onChange={handleChange}
        InputProps={isStartAdornment ? startAdornment : endAdornment}
        customRootStyles={customRootStyles}
        isMultiline={false}
      />
    </>
  );
};

export default memo(SearchField);
