import React, {FC, useState, memo} from 'react';
import {Backdrop, Button, Modal} from '@mui/material';

import {CreateSubtask} from '@/modals/create/CreateSubtask/index';

import s from './CreateSubtaskButton.module.css';

interface IProps {
  parentTaskId?: number;
  companyId?: number;
  isDisabled?: boolean;
}

const CreateSubtaskButton: FC<IProps> = ({parentTaskId, companyId, isDisabled}) => {
  const [openSubtask, setOpenSubtask] = useState(false);
  const handleOpenSubtask = () => setOpenSubtask(true);
  const handleCloseSubtask = () => setOpenSubtask(false);

  return (
    <>
      <Button
        className={s.button}
        type="button"
        variant="contained"
        color="secondary"
        onClick={handleOpenSubtask}
        sx={{
          marginBottom: 10
        }}
        disabled={isDisabled}
      >
        Создать подзадачу
      </Button>
      <Modal
        open={openSubtask}
        onClose={handleCloseSubtask}
        aria-labelledby="Окно создания подзадачи"
        closeAfterTransition
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
        disableEnforceFocus
      >
        <CreateSubtask
          parentTaskId={parentTaskId}
          defaultCompanyId={companyId}
          onClose={handleCloseSubtask}
        />
      </Modal>
    </>
  );
};

export default memo(CreateSubtaskButton);
