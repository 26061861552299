import {ThemeOptions} from '@mui/material/styles';

import {ExtendedTypographyOptions} from '@/shared/types/mui';

export const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    mode: 'light',
    muted: {
      main: '#EEF0F3'
    },
    primary: {
      main: '#007AFF'
    },
    secondary: {
      main: '#556675'
    },
    background: {
      default: '#EEF0F3'
    },
    text: {
      primary: '#132436',
      secondary: '#000000'
    },
    success: {
      main: '#1C9A65'
    },
    error: {
      main: '#FF4040'
    },
    search: {
      main: 'transparent',
      contrastText: '#000'
    },
    divider: '#EEF0F3'
  },
  typography: {
    htmlFontSize: 10,
    fontSize: 14,
    fontFamily: ['Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: 24,
      fontWeight: '400'
    },
    h2: {
      fontSize: 20
    },
    h3: {
      fontSize: 16,
      fontWeight: '600'
    },
    h5: {
      fontSize: 14
    },
    body1: {
      fontSize: 14
    },
    body2: {
      fontSize: 12
    },
    bold: {
      fontSize: 14,
      fontWeight: '600'
    },
    button: {
      textTransform: 'none'
    }
  } as ExtendedTypographyOptions,
  spacing: 2,
  shape: {
    borderRadius: 6
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        margin: 'none',
        size: 'small'
      },
      styleOverrides: {
        root: {
          fontSize: '1.4rem'
        },
        input: {
          borderRadius: 6,
          padding: 4
        }
      }
    },
    MuiButton: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          boxShadow: 'none'
        },
        sizeSmall: {
          fontSize: '1.4rem',
          lineHeight: '2rem',
          paddingTop: 9,
          paddingBottom: 9
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          paddingTop: '0.6rem',
          paddingBottom: '0.6rem'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none'
        },
        main: {
          backgroundColor: '#fff'
        },
        cell: {
          paddingLeft: 16
        },
        cellContent: {
          whiteSpace: 'nowrap'
        },
        columnHeaders: {
          backgroundColor: '#132436',
          color: '#fff'
        },
        menuIconButton: {
          color: '#fff'
        },
        sortIcon: {
          color: '#fff'
        },
        row: {}
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '4px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.variant === 'filled' &&
            ownerState.color === 'warning' && {
              backgroundColor: '#F7A800',
              color: '#fff'
            })
        })
      }
    }
  }
};
