import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/stores';
import {IUser} from '@/shared/models';
import {IDepartment, ITaskStatus, IType, IUnit} from '@/shared/models/dictionaryModel';
import {ICompany} from '@/shared/models/companyModel';
import {companyIndividualTypeIdSelector} from '@/stores/TaskConditionsStore/TaskConditionsSelector.ts';
import {checkCompanyIndividual} from '@/shared/utils/companyUtils.ts';

export const taskCompanySelector = (state: RootState) => {
  return state.taskEditState.company.data as ICompany;
};

export const taskEmployeeSelector = (state: RootState) => {
  return state.taskEditState.employee.data as IUser;
};

export const taskDepartmentSelector = (state: RootState) => {
  return state.taskEditState.department.data as IDepartment;
};

export const taskUserSelector = (state: RootState) => {
  return state.taskEditState.user.data as IUser;
};

export const taskUnitSelector = (state: RootState) => {
  return state.taskEditState.unit.data as IUnit;
};

export const taskTypeSelector = (state: RootState) => {
  return state.taskEditState.taskType.data as IType;
};

export const taskStatusSelector = (state: RootState) => {
  return state.taskEditState.taskStatus.data as ITaskStatus;
};

/**
 * Errors
 * @param state
 */
export const taskCompanyErrorSelector = (state: RootState) => {
  return state.taskEditState.company.error;
};

export const taskEmployeeErrorSelector = (state: RootState) => {
  return state.taskEditState.employee.error;
};

export const taskTypeErrorSelector = (state: RootState) => {
  return state.taskEditState.taskType.error;
};

export const taskDepartmentErrorSelector = (state: RootState) => {
  return state.taskEditState.department.error;
};

export const taskDelayErrorSelector = (state: RootState) => {
  return state.taskEditState.delayAt.error;
};

export const taskUserErrorSelector = (state: RootState) => {
  return state.taskEditState.user.error;
};

export const taskEditErrorsSelector = createSelector(
  taskCompanyErrorSelector,
  taskEmployeeErrorSelector,
  taskTypeErrorSelector,
  taskDepartmentErrorSelector,
  taskDelayErrorSelector,
  taskUserErrorSelector,
  (company, employee, type, department, user, delay) => {
    return [company, employee, type, department, user, delay].filter(Boolean);
  }
);

export const isIndividualCompanySelector = createSelector(
  companyIndividualTypeIdSelector,
  taskCompanySelector,
  (companyIndividualType, company) => {
    if (!company && !companyIndividualType) {
      return false;
    }
    return checkCompanyIndividual(companyIndividualType, company?.companyTypeId, company?.name);
  }
);
