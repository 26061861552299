import React, {FC, memo, useMemo} from 'react';
import cn from 'classnames';
import {Typography} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import {convertToDataStorageUnit} from '@/shared/utils/mathUtils';
import {ReactComponent as FileIcon} from '@/assets/file.svg';
import {IFile} from '@/shared/models/fileModel';

import s from './ChatFile.module.css';

interface IProps {
  file: IFile;
  onDeleteFile: (file: IFile) => void;
  className: string;
}

const ChatFileDelete: FC<IProps> = ({file, onDeleteFile, className = ''}) => {
  const sizeFormatted = useMemo(() => {
    if (!file?.size) {
      return '';
    }
    return convertToDataStorageUnit(file.size, true, 1);
  }, [file?.size]);

  if (!file) {
    return null;
  }

  return (
    <div className={cn(s.file, className)}>
      <div className={s.fileIcon}>
        <FileIcon />
      </div>
      <div className={s.fileInfo}>
        <Typography variant="bold" color="" title={file.name} className={s.fileInfoText}>
          {file.name}
        </Typography>
        <span className={s.fileSize}>{sizeFormatted}</span>
      </div>
      <button onClick={() => onDeleteFile(file)} className={s.fileDelete}>
        <DeleteIcon style={{color: '#647484'}} />
      </button>
    </div>
  );
};

export default memo(ChatFileDelete);
