import {api} from '@/stores/api';
import {ITaskResponse} from '@/shared/models/tasksDataModel';

interface ITaskWatchersArg {
  supportTaskId: number;
  userIds: number[];
  spectatorSupportTaskComment?: {
    content: string;
  };
}

interface IDeleteSpectatorArg {
  supportTaskId: number;
  supportTaskSpectatorId: number;
}

export const apiWithSupportTaskWatchers = api.injectEndpoints({
  endpoints: builder => ({
    supportTaskWatchers: builder.mutation<ITaskResponse, ITaskWatchersArg>({
      query: ({supportTaskId, userIds, spectatorSupportTaskComment}) => ({
        url: `/v1/support_tasks/${supportTaskId}/spectators`,
        method: 'post',
        data: {
          user_ids: userIds,
          spectator_support_task_comment: spectatorSupportTaskComment
        }
      }),
      // @ts-ignore
      transformResponse(returnValue?: {data: ITaskResponse}): ITaskResponse | undefined {
        return returnValue?.data;
      },
      invalidatesTags: ['SUPPORT_TASK']
    }),
    deleteSpectator: builder.mutation<ITaskResponse, IDeleteSpectatorArg>({
      query: ({supportTaskId, supportTaskSpectatorId}) => ({
        url: `/v1/support_tasks/${supportTaskId}/spectators/${supportTaskSpectatorId}`,
        method: 'delete'
      }),
      // @ts-ignore
      transformResponse(returnValue?: {data: ITaskResponse}): ITaskResponse | undefined {
        return returnValue?.data;
      },
      invalidatesTags: ['SUPPORT_TASK']
    })
  }),
  overrideExisting: true
});

export const {useSupportTaskWatchersMutation, useDeleteSpectatorMutation} =
  apiWithSupportTaskWatchers;
