import React, {FC, memo} from 'react';

import {useAppSelector} from '@/stores/hooks';
import {taskEmployeeSelector} from '@/stores/TaskSettingsStore';

interface IProps {}

const RecipientInput: FC<IProps> = () => {
  const editedEmployee = useAppSelector(taskEmployeeSelector);
  const email = editedEmployee?.email ? `(${editedEmployee?.email})` : '';

  return (
    <p>
      <span className="muted">Кому: </span>
      {`${editedEmployee?.name || ''} ${email}`}
    </p>
  );
};

export default memo(RecipientInput) as typeof RecipientInput;
