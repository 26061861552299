import React, {useEffect} from 'react';
import {RouterProvider} from 'react-router-dom';

import {appRouter} from '@/router';
import {store} from '@/stores';
import {setAuthTokens} from '@/stores/AuthStore';

function App() {
  useEffect(() => {
    const syncAuthState = () => {
      const persistedState = JSON.parse(localStorage.getItem('persist:root') || '{}');
      const authState = JSON.parse(persistedState.authState || '{}');
      if (authState && authState.accessToken) {
        store.dispatch(setAuthTokens(authState));
      }
    };

    window.addEventListener('storage', syncAuthState);

    return () => {
      window.removeEventListener('storage', syncAuthState);
    };
  }, []);

  return <RouterProvider router={appRouter} fallbackElement={<>Загрузка...</>} />;
}

export default App;
