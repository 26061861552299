import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';
import {generatePath} from 'react-router';
import {Box, Chip, Tooltip} from '@mui/material';
import {GridColDef, GridRenderCellParams, GridValueGetterParams} from '@mui/x-data-grid';

import {formatDate} from '@/shared/utils/dateUtils';
import {RoutePaths} from '@/shared/constants/route';
import {DeadlineChip} from '@/components/DeadlineChip';
import {ReactComponent as FilesIcon} from '@/assets/files.svg';
import {ReactComponent as CrownIcon} from '@/assets/crown.svg';
import {checkCompanyIndividual} from '@/shared/utils/companyUtils';
import {useAppSelector} from '@/stores/hooks';
import {companyIndividualTypeIdSelector} from '@/stores/TaskConditionsStore/TaskConditionsSelector';

import s from '../SubtasksTable.module.css';

export const useColumns = () => {
  const companyIndividualType = useAppSelector(companyIndividualTypeIdSelector);

  const renderCell = (task: GridRenderCellParams, field?: string) => {
    return (
      <Box>
        <div className={s.tableRow}>
          {field === 'deadlineAt' && (
            <div className={s.deadlineCell}>
              <DeadlineChip
                deadlineAt={task.row.deadlineAt}
                createdAt={task.row.createdAt}
                doneAt={task.row.doneAt}
                componentType="chip"
              />
            </div>
          )}
          {field !== 'deadlineAt' && (
            <Link
              className={cn('lint-text', s.tableRow)}
              to={generatePath(RoutePaths.SubtaskPage, {taskId: `${task.id}`})}
            >
              {task.value}
            </Link>
          )}
        </div>
      </Box>
    );
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        headerName: 'Наименование',
        field: 'companyName',
        width: 220,
        renderCell: ({value, row}) => {
          return (
            <span
              className={
                checkCompanyIndividual(companyIndividualType, row?.company?.companyTypeId, value)
                  ? 'imp'
                  : ''
              }
            >
              {row?.company?.isImportant && (
                <Tooltip title="Важный клиент">
                  <span>
                    <CrownIcon />{' '}
                  </span>
                </Tooltip>
              )}
              {value}
            </span>
          );
        }
      },
      {
        headerName: 'Номер',
        field: 'ticket',
        sortable: false
      },
      {
        headerName: '   ',
        field: 'unreadCommentsCount',
        width: 80,
        align: 'center',
        renderCell: ({value}) => {
          if (value) {
            return (
              <Tooltip title="Непрочитанные комментарии">
                <Chip color="warning" size="small" label={value} />
              </Tooltip>
            );
          }
          return <div>&nbsp;</div>;
        }
      },
      {
        headerName: 'Название',
        field: 'title',
        width: 262
      },
      {
        headerName: '   ',
        field: 'isHasFiles',
        width: '20',
        align: 'center',
        renderCell: ({value}) => {
          if (value) {
            return <FilesIcon />;
          }
          return <div>&nbsp;</div>;
        }
      },
      {
        headerName: 'Статус',
        field: 'taskStatusName',
        width: 120
      },
      {
        headerName: 'Дата и время создания',
        field: 'createdAt',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => {
          return `${formatDate(params.value, 'dd.MM.yyyy HH:mm')}`;
        }
      },
      {
        headerName: 'До выполнения осталось',
        field: 'deadlineAt',
        width: 150,
        renderCell: params => {
          return renderCell(params, 'deadlineAt');
        }
      },
      {
        headerName: 'Дата и время выполнения',
        field: 'doneAt',
        width: 130,
        valueGetter: (params: GridValueGetterParams) => {
          if (!params.value) {
            return '-';
          }
          return `${formatDate(params.value, 'dd.MM.yyyy HH:mm')}`;
        }
      },
      {
        headerName: 'Тип',
        field: 'taskTypeName',
        width: 220
      },
      {
        headerName: 'Исполнитель',
        field: 'userName',
        width: 130
      },
      {
        headerName: 'Отдел',
        field: 'departmentName',
        width: 180
      },
      {
        headerName: 'Автор',
        field: 'authorName',
        width: 180
      }
    ] as GridColDef[];

    return columnList.map(item => ({
      ...item,
      renderCell: item.renderCell || renderCell
    }));
  }, [companyIndividualType]);

  return columns;
};
