import {useMemo} from 'react';

import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {prepareDepartment} from '@/shared/utils/departmentUtils';
import {allDepartmentsQueryState} from '@/stores/api/dictionaries';
import {useGetDepartmentsQuery} from '@/stores/api/filtered-dictionaries';
import {ICompany} from '@/shared/models/companyModel';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {useAppSelector} from '@/stores/hooks';

interface IProps {
  company?: ICompany;
  isTask: boolean;
}

export const useDepartmentsLogic = ({company, isTask = false}: IProps) => {
  const currentUser = useAppSelector(userInfoSelector);
  const {data: departmentsDict = []} = allDepartmentsQueryState({});
  const {departmentsIds, unitsIds} = usePrepareDict();

  const isBuh = useMemo(() => {
    return !currentUser?.isHead && !currentUser?.isHeadUnit;
  }, [currentUser]);

  const companyResponsiblesFilter = useMemo(() => {
    if (!company?.companyResponsibles?.length) {
      return;
    }
    return company.companyResponsibles.map(({departmentId}) => departmentId).join(',');
  }, [company]);

  const userDepartmentsFilter = useMemo(() => {
    if (!currentUser?.userDepartments) {
      return;
    }
    return currentUser.userDepartments.map(({departmentId}) => departmentId).join(',');
  }, [currentUser]);

  const crossUnitsFilters = useMemo(() => {
    if (!departmentsDict) {
      return [];
    }
    return departmentsDict
      .filter(({isCrossUnit}) => isCrossUnit)
      .map(({id}) => id)
      .join(',');
  }, [departmentsDict]);

  /**
   * 4) селекторе “Отдел” бухгалтер видит только те отделы куда привязан и бухгалтер и клиент из юнита бухгалтера, плюс кроссюнитовые отделы.
   * Если is_head=False и is_head_unit=False в список выводим отделы
   * которые есть и в массиве токена user_departments и в массиве выбранной
   * компании company_responsibles, + отделы из справочника
   * у которых is_cross_unit=True
   */

  // Если is_head=False и is_head_unit=False берем отделы из массива выбранной компании company_responsibles + отделы из справочника где is_cross_unit=True
  const filters = useMemo(() => {
    if (!currentUser) {
      return {};
    }

    const ids = [isTask && userDepartmentsFilter, crossUnitsFilters, companyResponsiblesFilter]
      .filter(Boolean)
      .join(',');

    if (!ids) {
      return {};
    }

    return {
      'filters[id]': 'in|' + ids,
      'filters[is_disabled]': 0
    };
  }, [companyResponsiblesFilter, crossUnitsFilters, currentUser, isTask, userDepartmentsFilter]);

  const {departments, isDepartmentsLoading} = useGetDepartmentsQuery(filters, {
    skip: !company,
    selectFromResult: ({data, isFetching}) => ({
      departments: data ? data.map(val => prepareDepartment(val, unitsIds)) : [],
      isDepartmentsLoading: isFetching
    })
  });

  /**
   * 1. если авторизованный бухгалтер привязан к одному отделу то селектор "Отдел" задизейблен и по умолчанию в селекторе выбран единственны отдел бухгалтера
   */
  if (
    isTask &&
    isBuh &&
    departmentsIds &&
    currentUser?.userDepartments &&
    currentUser.userDepartments.length === 1
  ) {
    const userDepartmentId = currentUser.userDepartments[0].departmentId;
    return {
      departments:
        departmentsIds && userDepartmentId && departmentsIds[userDepartmentId]
          ? [prepareDepartment(departmentsIds[userDepartmentId], unitsIds)]
          : [],
      isDepartmentsLoading: false,
      isDepartmentsDisabled: true
    };
  }

  return {
    departments,
    isDepartmentsLoading
  };
};
