import React, {FC, memo} from 'react';
import {IconButton, Tooltip} from '@mui/material';

import {ReactComponent as DisableInfoIcon} from '@/assets/info-circle-disable.svg';
import {ReactComponent as InfoIcon} from '@/assets/info-circle.svg';

interface IProps {
  onClick: () => void;
  isDisplaySystemComments: boolean;
}

const DisplaySystemCommentsButton: FC<IProps> = ({onClick, isDisplaySystemComments}) => {
  return (
    <Tooltip
      title={
        isDisplaySystemComments ? 'Скрыть системные сообщения' : 'Показать системные сообщения'
      }
    >
      <IconButton type="button" onClick={onClick}>
        {isDisplaySystemComments ? <InfoIcon /> : <DisableInfoIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default memo(DisplaySystemCommentsButton);
