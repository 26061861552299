import {useEffect} from 'react';

import {useGetUsersQuery} from '@/stores/api/user';
import {EFilterName, IOption} from '@/shared/models/tasksFilterModel';

type TUpdate = (key: EFilterName, options: IOption[]) => void;

export const useHeadFilters = (onUpdate: TUpdate) => {
  const {data: users} = useGetUsersQuery({
    'filters[heads]': true
  });

  useEffect(() => {
    if (users) {
      onUpdate(EFilterName.Head, users);
    }
  }, [onUpdate, users]);
};
