import {useCallback, useEffect, useRef} from 'react';
import useWebSocket from 'react-use-websocket';
import {Draft} from '@reduxjs/toolkit';

import {useAppDispatch, useAppSelector} from '@/stores/hooks.ts';
import {authTokenSelector} from '@/stores/AuthStore/AuthStateSelector.ts';
import {ESocketEvent} from '@/shared/models/socketTypes.ts';
import {api} from '@/stores/api';
import {IPagination} from '@/shared/models/commonModel.ts';
import {ITask, ITaskResponse} from '@/shared/models/tasksDataModel.ts';
import {IMessage} from '@/shared/models/messageModel.ts';
import {formatKeys} from '@/shared/utils/responseUtils.ts';
import {updateEmployee, updateToCopyEmployees} from '@/stores/TaskSettingsStore';
import {formatUserName} from '@/shared/utils/fieldsUtils.ts';
import usePrevious from '@/shared/hooks/usePrevious.ts';
import {withSocket} from '@/shared/constants/appConstants.ts';

const SOCKET_URL = import.meta.env.APP_SOCKET_URL || '';

export const useTaskStreaming = ({taskId}: {taskId?: string}) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(authTokenSelector);
  const WS_URL = SOCKET_URL + `/tasks/${taskId}`;
  const didUnmount = useRef(false);
  const prevToken = usePrevious(accessToken);

  const {lastMessage} = useWebSocket(
    WS_URL,
    {
      onOpen: () => console.debug('opened'),
      onClose: () => console.debug('closed'),
      share: false,
      shouldReconnect: () => {
        /*
      useWebSocket will handle unmounting for you, but this is an example of a
      case in which you would not want it to automatically reconnect
    */
        if (prevToken && prevToken !== accessToken) {
          console.log('reconnect', {prevToken, accessToken});
          return true;
        }
        return didUnmount.current === false;
      },
      queryParams: {
        token: accessToken
      }
    },
    withSocket
  );

  const handleTaskComment = useCallback(
    (message: IMessage) => {
      dispatch(
        api.util.updateQueryData(
          // @ts-ignore
          'getTaskById',
          {taskId},
          (draft: Draft<IPagination<ITask>>) => {
            const comment: IMessage = formatKeys(message);
            console.log('new comment', comment);
            if (draft.data?.taskComments?.length) {
              if (draft.data?.taskComments.findIndex(o => o.id === comment.id) === -1) {
                draft.data.taskComments.push(comment);
              }
            } else {
              draft.data.taskComments = [comment];
            }
          }
        )
      );
    },
    [dispatch, taskId]
  );

  const handleUpdateTask = useCallback(
    (message: IMessage) => {
      const patch = formatKeys<Partial<ITaskResponse>, Partial<ITask>>(message);

      dispatch(
        // @ts-ignore
        api.util.updateQueryData('getTaskById', {taskId}, (draft: any = {}) => {
          const prevData = draft.data as Partial<ITask>;

          if (patch.toCopyEmployees && patch.toCopyEmployees !== prevData.toCopyEmployees) {
            const toCopyEmployees = patch.toCopyEmployees.map(item => {
              return {
                ...item,
                name: formatUserName(item)
              };
            });

            dispatch(updateToCopyEmployees(toCopyEmployees));
          }

          // Если нет сотрудников в копии
          if (
            patch.toCopyEmployeesIds &&
            patch.toCopyEmployeesIds !== prevData.toCopyEmployeesIds &&
            !patch.toCopyEmployeesIds.length
          ) {
            dispatch(updateToCopyEmployees([]));
          }

          if (patch.employee && patch.employeeId !== prevData.employeeId) {
            const employee = {
              ...patch.employee,
              name: formatUserName(patch.employee)
            };

            dispatch(updateEmployee(employee));
          }

          return {
            ...draft,
            data: {
              ...draft.data,
              ...patch,
              taskComments: [...(prevData.taskComments || []), ...(patch.taskComments || [])]
            }
          };
        })
      );
    },
    [dispatch, taskId]
  );

  // Run when a new WebSocket message is received (lastMessage)
  useEffect(() => {
    if (lastMessage !== null) {
      const commentData = JSON.parse(lastMessage.data);
      console.debug('Got a new message: ', commentData);

      switch (commentData.type) {
        case ESocketEvent.CreateTaskComment:
          handleTaskComment(commentData.data);
          break;

        case ESocketEvent.UpdateTask:
          handleUpdateTask(commentData.data);
          break;
      }
    }
  }, [handleTaskComment, handleUpdateTask, lastMessage]);

  return null;
};
